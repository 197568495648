import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function GoogleAdsIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", pb: "12px"}}>
                <a href="https://ads.google.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/google_ads.svg" alt="Google Ads Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Google Ads with Skro?"/>
            <Box>
                <p>
                    <a href="https://ads.google.com" target="_blank" rel="noreferrer">
                        Google Ads
                    </a> integration gives you the ability to manage and optimize all your Google ads through the
                    Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, ad groups and ads every 30 min.</li>
                    <li>Check the status of your campaigns, ad groups and ads.</li>
                    <li>Pause or resume campaigns, ad groups and ads.</li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in Google Ads and Skro so Google can
                    pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in Google and Skro is required.
                </p>
                <DocImage src="/images/docs/google-manage-campaigns.png" alt="Manager Google Ads campaigns"
                     />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Create a Conversion Action</h2>
                <p>
                    A conversion action is needed for Skro to send conversions to Google Ads. We should get a
                    conversion action name in this section.
                </p>
                <ul>
                    <li>
                        Sign in to your Google Ads account.
                    </li>
                    <li>
                        Navigate to the <strong>Tools and
                        settings</strong> -> <strong>Measurement</strong> -> <strong>Conversions</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-1.png" alt="Manager Google Ads campaigns"
                     />
                <ul>
                    <li>Click <strong>New conversion action</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-2.png" alt="Manager Google Ads campaigns"
                     />
                <ul>
                    <li>
                        Choose <strong>Import</strong> type.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-3.png" alt="Manager Google Ads campaigns"
                     />
                <ul>
                    <li>
                        Choose <strong>Other data sources or CRMs</strong> and <strong>Track conversions from
                        clicks</strong>.
                    </li>
                    <li>Click <strong>Continue</strong>.</li>
                </ul>
                <DocImage src="/images/docs/google-4.png" alt="Manager Google Ads campaigns"
                     />
                <ul>
                    <li>
                        Fill all required fields.
                    </li>
                    <li>
                        Remember the <strong>Conversion name</strong> you will need it when creating integration on
                        Skro.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-5.png" alt="Manager Google Ads campaigns"
                     />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>II. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-integration-1.png" alt="Google Ads Integration step 1"
                     />
                <ul>
                    <li>Click on <strong>Google Ads</strong> logo and you will be redirected to Google Ads.</li>
                </ul>
                <DocImage src="/images/docs/google-integration-2.png" alt="Google Ads Integration step 2"
                     />
                <ul>
                    <li>
                        Choose or sign in the account you want to integrate, click Allow or Confirm button when Google
                        ask.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-integration-3.png" alt="Google Ads Integration step 3"
                     />
                <ul>
                    <li>
                        Choose the Google Ads account you want to integrate. If you have multiple ads account, you need
                        to integrate them separately.
                    </li>
                    <li>
                        Click on the <strong>Next</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-integration-4.png" alt="Google Ads Integration step 4"
                     />
                <ul>
                    <li>
                        If you want to send conversions info back to Google Ads, then you will need to
                        enable <strong>Conversion API.</strong>
                    </li>
                    <li>
                        <strong>Conversion Action Name</strong> - must be the same as you typed in Google Ads. When
                        creating
                        Skro campaign you will need to specify which conversion action to send to.
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to Google Ads.
                    </li>
                    <li>
                        Click on <strong>Click & Save</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-integration-5.png" alt="Google Ads Integration step 5"
                     />
                <ul>
                    <li>You Google Ads account is successfully integrated.</li>
                </ul>
                <DocImage src="/images/docs/google-integration-6.png" alt="Google Ads Integration step 5"
                     />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on
                        the <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-1.jpg" alt="Setup Google Ads traffic source Step 1"
                     />
                <ul>
                    <li>Click on <strong>Google Ads</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/google-integration-7.png" alt="Setup Google Ads traffic source step 2"
                     />
                <ul>
                    <li>Choose and select your Google Ads integration from dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/google-integration-8.png" alt="Setup Google Ads traffic source step 4"
                     />
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for Google Ads.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your offer.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-offer-1.png" alt="Setup Skro Offer"
                     />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as Google Ads.</li>
                    <li>
                        Select your <strong>Conversion Action Name</strong>. That is needed to send conversion info back
                        to Google Ads.
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/google-campaign-1.png" alt="Setup Skro Campaign"
                     />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/google-campaign-2.png" alt="Setup Skro Campaign"
                     />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>
                        In given example we use only offers, Switch on <strong>Use only offers</strong> and select your
                        offer.
                    </li>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/google-campaign-3.png" alt="Setup Skro Campaign"
                     />
                <ul>
                    <li>Choose <b>Direct</b> method and select your offer.</li>
                    <li>
                        Copy and paste the Offer URL to Google Ads <strong>Your business's website</strong> field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://skro.eu"}
                </Alert>
                <ul>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>Final URL suffix</strong> text field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiMzBhNWFhMGQtNjU4YS00ZWY2LWFhOTEtMDQ5YzUxNzQ5MDlhIiwiciI6MCwicCI6MCwibyI6MH0=&gclid={gclid}&wbraid={wbraid}&gbraid={gbraid}&adgroupid={adgroupid}&creative={creative}&campaignid={campaignid}&device={device}&adposition={adposition}&network={network}&placement={placement}&matchtype={matchtype}&keyword={keyword}"}
                </Alert>
                <ul>
                    <li>Copy and paste Skro <strong>Tracking Pixel</strong> to your website between HEAD tags.</li>
                    <li>
                        Copy and paste Skro <strong>Thank you page JS pixel</strong> to your website between HEAD or
                        BODY tags.
                    </li>
                    <li>If you want to send conversion info back to Skro from your server, then you will need to
                        use <strong>Postback URL</strong> not <strong>Thank you page JS pixel</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-campaign-4.png" alt="Setup Skro Campaign"
                     />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in Google</h2>
                <p>
                    There are multiple campaign types that you can create with Google Ads. Campaign types determine
                    where and how your ad will be promoted.
                </p>
                <ul>
                    <li><strong>Search:</strong> Your ad will be displayed among Google's search results.</li>
                    <li>
                        <strong>Display:</strong> Your ad will be displayed on websites partnered with Google in the
                        Display network.
                    </li>
                    <li>
                        <strong>Shopping:</strong> Your ad will be displayed to people already searching for a product.
                    </li>
                    <li>
                        <strong>Video:</strong> Your video ad will be displayed when searching or watching a YouTube
                        video. Note that you need to have your video ad uploaded to YouTube to use it in your ad
                        campaign
                    </li>
                    <li>
                        <strong>App:</strong> Your ad will be displayed in all Google's app-related properties: search,
                        Play store, Display Network and other.
                    </li>
                </ul>

                <p>In order to create a Google ad campaign, please follow the steps below:</p>
                <ul>
                    <li>Sign in to your Google Ads account.</li>
                    <li>In your Google Ads dashboard, click the <strong>New Campaign</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-ads-1.png" alt="Setup Google Campaign"
                     />
                <ul>
                    <li>Select the campaign goal.</li>
                </ul>
                <DocImage src="/images/docs/google-ads-2.png" alt="Setup Google Campaign"
                     />
                <ul>
                    <li>Select a campaign type.</li>
                </ul>
                <DocImage src="/images/docs/google-ads-3.png" alt="Setup Google Campaign"
                     />
                <ul>
                    <li>Provide your landing page's URL as your business's website. Do not include any tracking
                        parameters at this point, provide the plain URL.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-ads-4.png" alt="Setup Google Campaign"
                     />

                <ul>
                    <li>In our example we selected the following things:</li>
                </ul>
                <DocImage src="/images/docs/google-ads-5.png" alt="Setup Google Campaign"
                     />
                <ul>
                    <li>Click the <strong>Continue</strong> button.</li>
                </ul>
                <p>
                    Provide all required information for the selected campaign goal and type. This information include:
                </p>
                <ul>
                    <li>Campaign budget.</li>
                    <li>Bid.</li>
                    <li>Targeting.</li>
                    <li>Keywords.</li>
                    <li>Ad title, images and description.</li>
                    <li>Save your campaign by clicking the <strong>Save</strong> button.</li>
                </ul>
                <p>
                    Once you have saved your campaign, go to this campaign's details.
                </p>
                <ul>
                    <li>Go to <strong>Settings</strong> -> <strong>Additional settings</strong> -> <strong>Campaign
                        URL Options</strong>.
                    </li>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>Final URL suffix</strong> text field.
                    </li>
                </ul>
                <p><strong>Example</strong>:</p>
                <p>The complete direct tracking URL that you have copied from Skro should look like this:</p>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://skro.eu?trck=eyJjcGlkIjoiMzBhNWFhMGQtNjU4YS00ZWY2LWFhOTEtMDQ5YzUxNzQ5MDlhIiwiciI6MCwicCI6MCwibyI6MH0=&gclid={gclid}&wbraid={wbraid}&gbraid={gbraid}&adgroupid={adgroupid}&creative={creative}&campaignid={campaignid}&device={device}&adposition={adposition}&network={network}&placement={placement}&matchtype={matchtype}&keyword={keyword}"}
                </Alert>
                <p>
                    The following part of the above URL should be pasted in the <strong>Final URL suffix</strong> text
                    field
                </p>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiMzBhNWFhMGQtNjU4YS00ZWY2LWFhOTEtMDQ5YzUxNzQ5MDlhIiwiciI6MCwicCI6MCwibyI6MH0=&gclid={gclid}&wbraid={wbraid}&gbraid={gbraid}&adgroupid={adgroupid}&creative={creative}&campaignid={campaignid}&device={device}&adposition={adposition}&network={network}&placement={placement}&matchtype={matchtype}&keyword={keyword}"}
                </Alert>
                <DocImage src="/images/docs/google-ads-6.png" alt="Setup Google Campaign"
                     />
                <ul>
                    <li>Click the <strong>Save</strong> button.</li>
                </ul>
                <p>
                    Congratulations, you have successfully set up tracking of Google traffic with Skro. Each time a
                    visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed. On a landing page, the direct tracking script will launch and send an HTTPS
                    request to Skro with information about a visit and other information passed from Google.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default GoogleAdsIntegration;
