import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function MicrosoftAdsIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://ads.microsoft.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/microsoftads.svg" alt="Microsoft Ads Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Microsoft Ads with Skro?"/>
            <Box>
                <p>
                    <a href="https://ads.microsoft.com" target="_blank" rel="noreferrer">
                        Microsoft Ads
                    </a> integration gives you the ability to manage and optimize all your Microsoft ads through the
                    Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, ad groups and ads every 30 min.</li>
                    <li>Check the status of your campaigns, ad groups and ads.</li>
                    <li>Pause or resume campaigns, ad groups and ads.</li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in Microsoft Ads and Skro so Microsoft
                    can
                    pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in Microsoft and Skro is required.
                </p>
                <DocImage src="/images/docs/google-manage-campaigns.png" alt="Manager Microsoft Ads campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Create a Conversion Goal</h2>
                <p>
                    A conversion goal is needed for Skro to send conversions to Microsoft Ads. We should get a
                    conversion goal name in this section.
                </p>
                <ul>
                    <li>
                        Sign in to your Microsoft Ads account.
                    </li>
                    <li>
                        Navigate to the <strong>Tools</strong> -> <strong>Conversion Tracking</strong> -> <strong>Conversion
                        goals</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/microsoft-1.png" alt="Manager Microsoft Ads campaigns"
                />
                <ul>
                    <li>Click <strong>+ Create</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-2.png" alt="Manager Microsoft Ads campaigns"
                />
                <ul>
                    <li>
                        Choose <strong>Offline</strong> type.
                    </li>
                    <li>Click <strong>Next</strong>.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-3.png" alt="Manager Microsoft Ads campaigns"
                />
                <ul>
                    <li>
                        Select your <strong>Goal category</strong> and click on <strong>Goal Type</strong>.
                    </li>
                    <li>Click <strong>Next</strong>.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-4.png" alt="Manager Microsoft Ads campaigns"
                />
                <ul>
                    <li>
                        Fill all required fields.
                    </li>
                    <li>
                        Remember the <strong>Conversion name</strong> you will need it when creating integration on
                        Skro.
                    </li>
                    <li>Click <strong>Save and next</strong>.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-5.png" alt="Manager Microsoft Ads campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>II. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-integration-1.png" alt="Microsoft Ads Integration step 1"
                />
                <ul>
                    <li>Click on <strong>Microsoft</strong> logo and you will be redirected to Microsoft Ads.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-integration-1.png" alt="Microsoft Ads Integration step 2"
                />
                <ul>
                    <li>
                        Choose or sign in the account you want to integrate, click Allow or Confirm button when
                        Microsoft ask.
                    </li>
                </ul>
                <DocImage src="/images/docs/microsoft-integration-2.png" alt="Microsoft Ads Integration step 3"
                />
                <ul>
                    <li>
                        Choose the Microsoft Ads account you want to integrate. If you have multiple ads account, you
                        need to integrate them separately.
                    </li>
                    <li>
                        Click on the <strong>Next</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/microsoft-integration-3.png" alt="Microsoft Ads Integration step 4"
                />
                <ul>
                    <li>
                        If you want to send conversions info back to Microsoft Ads, then you will need to
                        enable <strong>Conversion API.</strong>
                    </li>
                    <li>
                        <strong>Conversion Action Name</strong> - must be the same as you typed in Microsoft Ads. When
                        creating Skro campaign you will need to specify which conversion goal to send to.
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to Microsoft Ads.
                    </li>
                    <li>
                        Click on <strong>Click & Save</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/microsoft-integration-4.png" alt="Microsoft Ads Integration step 5"
                />
                <ul>
                    <li>You Microsoft Ads account is successfully integrated.</li>
                </ul>
                <DocImage src="/images/docs/google-integration-6.png" alt="Microsoft Ads Integration step 5"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on
                        the <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-1.jpg"
                          alt="Setup Microsoft Ads traffic source Step 1"
                />
                <ul>
                    <li>Click on <strong>Microsoft Ads</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-traffic-1.png" alt="Setup Microsoft Ads traffic source step 2"
                />
                <ul>
                    <li>Choose and select your Microsoft Ads integration from dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-traffic-2.png" alt="Setup Microsoft Ads traffic source step 4"
                />
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for Microsoft Ads.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your offer.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-offer-1.png" alt="Setup Skro Offer"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as Microsoft Ads.</li>
                    <li>
                        Select your <strong>Conversion Goal Name</strong>. That is needed to send conversion info back
                        to Microsoft Ads.
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/microsoft-skro-campaign-1.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/microsoft-skro-campaign-2.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>
                        In given example we use only offers, Switch on <strong>Use only offers</strong> and select your
                        offer.
                    </li>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/google-campaign-3.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose <b>Direct</b> method and select your offer.</li>
                    <li>
                        Copy and paste the Offer URL to Microsoft Ads <strong>Final URL</strong> field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://skro.eu"}
                </Alert>
                <ul>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>Final URL suffix</strong> text field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiMjQzYjgzMjQtYTYyNS00MWUxLWJiNWUtYzU1NmE1MDA5M2Q3IiwiciI6MCwicCI6MCwibyI6MH0=&msclkid={msclkid}&AdId={AdId}&AdGroupId={AdGroupId}&CampaignId={CampaignId}&Device={Device}&MatchType={MatchType}&KeyWord={KeyWord}&TargetId={TargetId}&QueryString={QueryString}&Network={Network}"}
                </Alert>
                <ul>
                    <li>Copy and paste Skro <strong>Tracking Pixel</strong> to your website between HEAD tags.</li>
                    <li>
                        Copy and paste Skro <strong>Thank you page JS pixel</strong> to your website between HEAD or
                        BODY tags.
                    </li>
                    <li>If you want to send conversion info back to Skro from your server, then you will need to
                        use <strong>Postback URL</strong> not <strong>Thank you page JS pixel</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/google-campaign-4.png" alt="Setup Skro Campaign"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in Microsoft Ads</h2>
                <p>
                    There are multiple campaign types that you can create with Microsoft Ads. Campaign types determine
                    where and how your ad will be promoted.
                </p>
                <ul>
                    <li><strong>Search:</strong> Your ad will be displayed among Microsoft's search results.</li>
                    <li>
                        <strong>Display:</strong> Your ad will be displayed on websites partnered with Microsoft in the
                        Display network.
                    </li>
                </ul>

                <p>In order to create a Microsoft ad campaign, please follow the steps below:</p>
                <ul>
                    <li>Sign in to your Microsoft Ads account.</li>
                    <li>In your Microsoft Ads campaigns view, click the <strong>Create</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-ad-1.png" alt="Setup Microsoft Campaign"
                />
                <ul>
                    <li>Select the <strong>Campaign Goal</strong>.</li>
                    <li>Select the <strong>Ad Type</strong>.</li>
                    <li>Click <strong>Next</strong>.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-ad-2.png" alt="Setup Microsoft Campaign"
                />
                <ul>
                    <li>Provide a name for your campaign.</li>
                    <li>Set the campaign budget and targeting options.</li>
                    <li>Click the <strong>Save & go to the next step</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-ad-3.png" alt="Setup Microsoft Campaign"
                />
                <ul>
                    <li>Provide your landing page URL.</li>
                    <li>Provide an ad group name and some keywords.</li>
                    <li>Click the <strong>Save & go to the next step</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-ad-4.png" alt="Setup Microsoft Campaign"
                />
                <ul>
                    <li>Select your <strong>Ad Type</strong>.</li>
                    <li>Provide your Final URL.</li>
                    <li>Add your ad headlines, images and descriptions.</li>
                </ul>
                <DocImage src="/images/docs/microsoft-ad-5.png" alt="Setup Microsoft Campaign"
                />
                <ul>
                    <li>Scroll down to <strong>Ad URL options</strong></li>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>Final URL suffix</strong> text field.
                    </li>
                </ul>
                <DocImage src="/images/docs/microsoft-ad-6.png" alt="Setup Microsoft Campaign"
                />
                <p><strong>Example</strong>:</p>
                <p>The complete direct tracking URL that you have copied from Skro should look like this:</p>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://skro.eu?trck=eyJjcGlkIjoiMjQzYjgzMjQtYTYyNS00MWUxLWJiNWUtYzU1NmE1MDA5M2Q3IiwiciI6MCwicCI6MCwibyI6MH0=&msclkid={msclkid}&AdId={AdId}&AdGroupId={AdGroupId}&CampaignId={CampaignId}&Device={Device}&MatchType={MatchType}&KeyWord={KeyWord}&TargetId={TargetId}&QueryString={QueryString}&Network={Network}"}
                </Alert>
                <p>
                    The following part of the above URL should be pasted in the <strong>Final URL suffix</strong> text
                    field
                </p>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiMjQzYjgzMjQtYTYyNS00MWUxLWJiNWUtYzU1NmE1MDA5M2Q3IiwiciI6MCwicCI6MCwibyI6MH0=&msclkid={msclkid}&AdId={AdId}&AdGroupId={AdGroupId}&CampaignId={CampaignId}&Device={Device}&MatchType={MatchType}&KeyWord={KeyWord}&TargetId={TargetId}&QueryString={QueryString}&Network={Network}"}
                </Alert>
                <ul>
                    <li>Click on <strong>Save</strong> button.</li>
                </ul>
                <p>
                    Congratulations, you have successfully set up tracking of Microsoft traffic with Skro. Each time a
                    visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed. On a landing page, the direct tracking script will launch and send an HTTPS
                    request to Skro with information about a visit and other information passed from Microsoft.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default MicrosoftAdsIntegration;
