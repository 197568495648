import React from "react";
import {Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function MondiadIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://mondiad.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/partners/mondiad.svg" alt="Mondiad logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Mondiad with Skro?"/>
            <Box>
                <p>
                    <a href="https://mondiad.com?refid=6783" target="_blank" rel="noreferrer">
                        Mondiad
                    </a> integration gives you the ability to manage and optimize all your Mondiad ads through the
                    Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, creatives, zones and sites every 30 min.</li>
                    <li>Check the status of your campaigns, creatives, zones and sites.</li>
                    <li>Pause or resume campaigns, creatives, zones and sites.</li>
                    <li>Change the bid value for campaigns, zones and sites.</li>
                </ul>
                <DocImage src="/images/docs/manage-campaigns.jpg" alt="Manager Mondiad campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>How to generate Mondiad API token?</h2>
                <ul>
                    <li>Log in to your <strong>Mondiad</strong> account.</li>
                    <li>Navigate to <strong>Account Settings</strong> -> <strong>API Integration</strong>.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-api-token-1.jpg" alt="Mondiad API Token 1"
                />
                <ul>
                    <li>Click on <strong>Add API Key</strong> button.</li>
                    <li>Enter your API key name and click on <strong>Submit</strong> button.</li>
                    <li>Copy the <strong>Client ID</strong> and <strong>Client Secret</strong> on the clipboard.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-api-token-2.jpg" alt="Mondiad API Token 1"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add integration to Skro</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-integration-1.jpg" alt="Mondiad Integration step 1"
                />
                <ul>
                    <li>Click on <strong>Mondiad</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-integration-2.jpg" alt="Mondiad Integration step 2"
                />
                <ul>
                    <li>
                        Paste the Mondiad <strong>Client ID</strong> and <strong>Client Secret</strong> and
                        click on <strong>Create Integration</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/mondiad-integration-3.jpg" alt="Mondiad Integration step 3"
                />
                <ul>
                    <li>You can turn on/off auto-cost update through <strong>Data transfer</strong> switch.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-integration-4.jpg" alt="Mondiad Integration step 4"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Setup <strong>Postback URL</strong> and associate the integration with Mondiad traffic source.</h2>
                <ul>
                    <li>Log in to your Mondiad account.</li>
                    <li>
                        Navigate to the <strong>Conversion Tracking</strong>.
                    </li>
                    <li>Click on <strong>Skro</strong> logo to get pre-filled Postback URL.</li>
                    <li>Copy the <strong>Postback URL</strong> to the clipboard.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-postback-1.jpg" alt="Setup Mondiad conversion tracking step 1"
                />

                <ul>
                    <li>Go back to Skro and create a new traffic source.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-1.jpg" alt="Setup Mondiad traffic source step 1"
                />
                <ul>
                    <li>Click on <strong>Mondiad</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-traffic-source-1.jpg" alt="Setup Mondiad traffic source step 2"
                />
                <ul>
                    <li>
                        Replace the pre-filled Postback URL with the new <strong>Postback URL</strong> copied from
                        Mondiad into the <strong>Traffic Source Postback URL</strong> field.
                    </li>
                </ul>
                <DocImage src="/images/docs/mondiad-traffic-source-2.jpg" alt="Setup Mondiad traffic source step 3"
                />
                <ul>
                    <li>Add integration association.</li>
                </ul>
                <DocImage src="/images/docs/mondiad-traffic-source-3.jpg" alt="Setup Mondiad traffic source step 4"
                />
                <ul>
                    <li>All parameters are pre-filled.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default MondiadIntegration;
