import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function HowToCreateACampaignToTrackFacebookTraffic(props) {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", pb: "12px"}}>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/meta.svg" alt="Facebook (Meta) Ads Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to create a campaign to track Facebook (Meta) traffic?"/>
            <Box>
                <h2>I. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your landing page (pre-lander).
                </p>
                <ul>
                    <li>Navigate to the <strong>Landings</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your landing URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-1.png" alt="Setup Skro Landing"
                />
                <p>
                    Add your offers.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-2.png" alt="Setup Skro Offer"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as Facebook (Meta) Ads.</li>
                    <li>
                        Select your <strong>Conversion Event Name</strong>. That is needed to send conversion info back
                        to Facebook (Meta).
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-3.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-4.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>Select your landing.</li>
                    <li>Select your offer(s).</li>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-6.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose <b>Direct</b> method and select your landing page.</li>
                    <li>Copy and paste the Landing URL to your Facebook (Meta) Ads <strong>* Website URL</strong> field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://prelander1.com"}
                </Alert>
                <ul>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>URL parameters</strong> text field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiZDdjOTllNDAtNWVjOS00NjZjLWI1MmMtZTk1YjM3NTViNsY3IiwiciI6MCwicCI6MCwibCI6MH0=&ad_id={{ad.id}}&ad_name={{ad.name}}&adset_id={{adset.id}}&adset_name={{adset.name}}&campaign_id={{campaign.id}}&campaign_name={{campaign.name}}&placement={{placement}}&site_source_name={{site_source_name}}&gender={gender}"}
                </Alert>

                <p>
                    If you are doing ads by gender, then you can define your gender value manually to feed Facebook
                    Ads AI. If do not want to use this parameter then just remove it.
                </p>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 16}}>
                    Male traffic only: {"&gender=m"}
                </Alert>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 16}}>
                    Female traffic only: {"&gender=f"}
                </Alert>
                <ul>
                    <li>Copy and paste Skro <strong>Tracking Pixel</strong> to your website between HEAD tags.</li>
                    <li>Copy and paste Skro <strong>Click to offer URL</strong> to your landing page as CTA button.</li>
                    <li>Copy and paste Skro <strong>Postback URL</strong> to your affiliate network or use JS or IMG
                        pixel, but you will need to map Skro click ID and payout macros by yourself.
                    </li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-7.png" alt="Setup Skro Campaign"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>II. Create a Campaign in Facebook (Meta) Ads</h2>
                <p>
                    There are multiple campaign types that you can create with Facebook (Meta) Ads. Campaign types
                    determine how your ad will be promoted.
                </p>

                <p>In order to create a Facebook (Meta) ad campaign, please follow the steps below:</p>
                <ol>
                    <li>Sign in to your Facebook (Meta) ads manager account.</li>
                    <li>Create a new campaign.</li>
                    <li>Select your campaign type, tracking event, pixel, audience, placements, budget etc.</li>
                </ol>

                <p><strong>Example</strong>:</p>
                <p>The complete direct tracking URL that you have copied from Skro should look like this:</p>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://prelander1.com/?trck=eyJjcGlkIjoiZDdjOTllNDAtNWVjOS00NjZjLWI1MmMtZTk1YjM3NTViNDY3IiwiciI6MCwicCI6MCwibCI6MH0=&ad_id={{ad.id}}&ad_name={{ad.name}}&adset_id={{adset.id}}&adset_name={{adset.name}}&campaign_id={{campaign.id}}&campaign_name={{campaign.name}}&placement={{placement}}&site_source_name={{site_source_name}}&gender={gender}"}
                </Alert>

                <p>
                    On the ad level you have to provide your landing page URL as your business website. Do not
                    include any tracking parameters at this point, provide the plain URL.
                </p>

                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 24}}>
                    {"https://prelander1.com"}
                </Alert>

                <DocImage src="/images/docs/meta-camp-1.png" alt="Setup Facebook (Meta) Campaign"
                />

                <ul>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>URL Parameters</strong> text field.
                    </li>
                </ul>

                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiZDdjOTllNDAtNWVjOS00NjZjLWI1MmMtZTk1YjM3NTViNsY3IiwiciI6MCwicCI6MCwibCI6MH0=&ad_id={{ad.id}}&ad_name={{ad.name}}&adset_id={{adset.id}}&adset_name={{adset.name}}&campaign_id={{campaign.id}}&campaign_name={{campaign.name}}&placement={{placement}}&site_source_name={{site_source_name}}&gender=f"}
                </Alert>
                <DocImage src="/images/docs/meta-camp-2.png" alt="Setup Facebook (Meta) Campaign"
                />
                <ul>
                    <li>Click the <strong>Publish</strong> button.</li>
                </ul>
                <p>
                    Congratulations, you have successfully set up tracking of Facebook (Meta) traffic with Skro. Each
                    time a visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed. On a landing page, the direct tracking script will launch and send an HTTPS
                    request to Skro with information about a visit and other information passed from Facebook (Meta).
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default HowToCreateACampaignToTrackFacebookTraffic;

