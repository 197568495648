import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function ClickBankIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", pb: 2}}>
                <a href="https://clickbank.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 250}} src="/images/integration/clickbank.svg" alt="ClickBank logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate ClickBank with Skro?"/>
            <div>
                <p>
                    ClickBank is one of the affiliate networks that has stood at the beginning of affiliate industry
                    development in the internet. If you run the ad campaigns using a tracking solution such as Skro, you
                    should be aware that regular postback tracking method is not applicable with this network. Due to
                    the <strong>Instant Notification System</strong> you can connect your ClickBank Affiliate Account
                    with Skro just with one link.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add ClickBank integration</h2>
                <ul>
                    <li>Log in to your ClickBank account.</li>
                    <li>Navigate to <strong>Accounts</strong> and click on your account.</li>
                    <li>Press <strong>Vendor Settings</strong> and select <strong>My site</strong> on ClickBank panel.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-1.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Scroll down to <strong>Advanced Tools</strong> section.</li>
                    <li>Press <strong>Edit</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-2.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Click on <strong>Request access</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-3.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Mark all values <strong>Yes</strong>.</li>
                    <li>Read and confirm the "Terms & Conditions" and press <strong>Save changes</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-4.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>
                        If you don't have a <strong>Secret Key</strong> then then enter your secret key in the
                        Secret Key field and press <strong>Save Changes</strong>. Your secret key is a string of up
                        to 16 characters, including numbers and capital letters. Your instant notifications will be
                        encrypted using that secret key.
                    </li>
                    <li>
                        If you already have a <strong>Secret Key</strong> then copy to the clipboard.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-5.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Log in to your Skro account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>Press <strong>Add</strong> button on the Affiliate Networks section.</li>
                </ul>
                <DocImage src="/images/docs/affiliate-network-integration.jpg" alt="Integrations view"
                />
                <ul>
                    <li>Choose <strong>ClickBank</strong>.</li>
                </ul>
                <DocImage src="/images/docs/choose-clickbank-integration.jpg" alt="Choose ClickBank integration"
                />
                <ul>
                    <li>Enter your integration name to the <strong>Integration Name</strong> field.</li>
                    <li>Paste your secret key to the <strong>ClickBank Secret Key</strong> field.</li>
                    <li>Press <strong>Generate Instant Notification URL</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-add-integration-1.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>
                        Copy the <strong>Instant Notification URL</strong> to the clipboard
                        and press <strong>Save & Close</strong>.
                    </li>
                    <li>Go back to ClickBank.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-add-integration-2.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>
                        Paste your <strong>Instant Notification URL</strong> copied from Skro.
                    </li>
                    <li>Choose version <strong>7.0</strong> and press <strong>TEST IPN</strong> button.</li>
                    <li>
                        If the <strong>INS URL</strong> is introduced correctly, the <strong>Verified</strong> sign
                        will appear.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-5.jpg" alt="Add ClickBank integration"
                />
                <p>
                    Press <strong>Save Changes</strong> to finish the setup process. Instant Notification URL with
                    verified sign will be available on the Advanced Tools section.
                </p>
                <DocImage src="/images/docs/clickbank-7.jpg" alt="Add ClickBank integration"
                />
                <p>
                    Instant Notification Access settings are finished and you are now successfully integrated.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>How to set up tracking for Initiate Checkout?</h2>
                <ul>
                    <li>
                        Go back to Skro and navigate
                        to <strong>Settings</strong> -> <strong>Custom Conversions</strong>.
                    </li>
                    <li>Enter name <strong>Initiate Checkout</strong>.</li>
                    <li>Enter <strong>InitiateCheckout</strong> to <strong>Values Of The ce Parameter</strong> and press
                        enter.
                    </li>
                    <li>
                        Mark the <strong>Send postback to Traffic Source</strong>, if you want to track given
                        event in your traffic source. Actual event value what is placed in "Values Of The ce Parameter"
                        field, will be postback to your traffic source as an event name. Given example is suitable
                        for Facebook InitiateCheckout event tracking.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-custom-conversion.jpg" alt="Add custom conversion"/>
                <ul>
                    <li>
                        Go back to ClickBank and navigate to <strong>Clickbank account</strong> -> <strong>Vendor
                        settings</strong> -> <strong>My site</strong> -> scroll down to the
                        Integrated sales reporting section and press <strong>Add tracking code</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-7.jpg" alt="Add ClickBank integration"/>
                <ul>
                    <li>
                        Choose the type <strong>Tracking pixel</strong> and press <strong>Add</strong> button.
                    </li>
                    <li>
                        Enter your <strong>Tracking pixel URL</strong> from Skro <strong>Custom
                        Conversions</strong> page.
                        In our example the value is: <strong>https://skrotrack.com/postback?ce=InitiateCheckout</strong>
                    </li>

                </ul>

                <Alert severity="warning" variant="filled" sx={{mb: 3}}>
                    The pasted URL SHOULD NOT BE precisely copied from the affiliate network Postback URL, it SHOULD BE
                    of the following format: https://skrotrack.com/postback?ce=InitiateCheckout, where you
                    should replace the “skrotrack.com” part with the tracking domain you use for this particular
                    campaign.
                </Alert>

                <ul>
                    <li>
                        Select <strong>Affiliate</strong> if you are promoting ClickBank products from the specific
                        sales account you are working in. If you select Affiliate, you will receive information relevant
                        to the affiliate process.
                    </li>
                    <li>
                        Select <strong>Seller</strong> if you are creating products for promotion in the account. If you
                        select Seller, you will receive information relevant to the vendor process.
                    </li>
                    <li>
                        Check the <strong>Order Form</strong> box to indicate that the pixel will fire on the order
                        form.
                    </li>
                    <li>
                        Leave all fields under the <strong>Order Form Event</strong> header the same except
                        the <strong>AFFILIATE_TRACKING_CODE</strong> field. Change that
                        from <strong>trackingCodes</strong> to <strong>clickId</strong> and
                        press <strong>Save</strong> to apply the changes.
                    </li>
                </ul>

                <DocImage src="/images/docs/clickbank-8.jpg" alt="Add ClickBank integration"/>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add ClickBank as an Affiliate Network to Skro</h2>
                <ul>
                    <li>Navigate to <strong>Affiliate Networks</strong> and press <strong>Create</strong> button.</li>
                    <li>Choose <strong>ClickBank</strong> integration.</li>
                </ul>
                <DocImage src="/images/docs/affiliate-network-choose.jpg" alt="Choose ClickBank"/>

                <ul>
                    <li>Press <strong>Save & Close</strong></li>
                </ul>
                <DocImage src="/images/docs/clickbank-affiliate-network.jpg" alt="ClickBank as affiliate network"/>

                <Divider sx={{mt: 3, mb: 2}}/>

                <h2>Add ClickBank offer to Skro</h2>
                <ul>
                    <li>Navigate to <strong>Offers</strong> and press <strong>Create</strong>.</li>
                    <li>Enter your offer name.</li>
                    <li>
                        Copy the offer link from ClickBank to the <strong>Offer URL</strong> field and
                        append <strong>tid={`clickId`}</strong> parameter to the end of the offer URL.
                    </li>
                    <li>
                        Choose <strong>ClickBank</strong> from the Affiliate Network dropdown menu.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-offer-form.jpg" alt="ClickBank as offer form"/>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </div>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default ClickBankIntegration;
