import {createTheme, responsiveFontSizes} from "@mui/material/styles";

// colors
const primary = "rgba(68, 199, 103, 1)";
const primaryLight = "rgba(106, 206, 133, 1)";
const primaryDark = "rgba(59, 172, 89, 1)";
const secondary = "rgba(50, 112, 220, 1)";
const black = "rgb(18, 29, 39)";
const darkBlack = "rgb(6, 13, 19)";
const background = "rgb(18, 29, 39)";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";
const white = "rgba(255, 255, 255, 1)";

const defaultBlue = "rgb(18, 29, 39)";
const darkBlue = "rgb(6, 13, 19)";

// border
const borderWidth = 1;
const borderHeight = 1;
const borderColor = "rgb(38, 45, 52)";
const borderColorLight = "rgba(38, 45, 52, 0.1)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme({
    palette: {
        primary: {
            main: primary,
            light: primaryLight,
            dark: primaryDark,
            rowHover: "#eee",
            borderLight: borderColorLight,
        },
        secondary: {main: secondary},
        common: {
            black,
            darkBlack,
            darkBlue,
            defaultBlue,
            white
        },
        warning: {
            light: warningLight,
            main: warningMain,
            dark: warningDark
        },
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        background: {
            default: background
        },
        spacing
    },
    shape: {
        borderRadius: 3
    },
    breakpoints: {
        // Define custom breakpoint values.
        // These will apply to Material-UI components that use responsive
        // breakpoints, such as `Grid` and `Hidden`. You can also use the
        // theme breakpoint functions `up`, `down`, and `between` to create
        // media queries for these breakpoints
        values: {
            xl,
            lg,
            md,
            sm,
            xs
        }
    },
    border: {
        borderColor: borderColor,
        borderLight: borderColorLight,
        borderWidth: borderWidth
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: "10px 16px",
                    color: white
                },
                label: {
                    lineHeight: "normal"
                },
                containedPrimary: {
                    "&:hover": {
                        color: white
                    }
                },
                outlinedInherit: {
                    color: darkBlue
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    "&.Mui-expanded": {
                        margin: 0
                    },
                    "&:before": {
                        backgroundColor: "none"
                    }
                },
                rounded: {
                    "&:last-child": {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                    }
                }
            }
        },
        MuiExpansionPanel: {
            styleOverrides: {
                root: {
                    position: "static"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingLeft: spacing * 2,
                    paddingRight: spacing * 2,
                    borderBottom: `${borderWidth}px solid ${borderColor}`,
                    [`@media (max-width:  ${sm}px)`]: {
                        paddingLeft: spacing,
                        paddingRight: spacing
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: borderColor,
                    height: borderHeight
                }
            }
        },
        MuiPrivateNotchedOutline: {
            styleOverrides: {
                root: {
                    borderWidth: borderWidth
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                divider: {
                    borderBottom: `${borderWidth}px solid ${borderColor}`
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    width: "100%",
                    maxWidth: 430,
                    marginLeft: spacing,
                    marginRight: spacing
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: darkBlack
                }
            }
        },
        MuiExpansionPanelDetails: {
            styleOverrides: {
                root: {
                    [`@media (max-width:  ${sm}px)`]: {
                        paddingLeft: spacing,
                        paddingRight: spacing
                    }
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: "white"
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    wordBreak: "break-word"
                },
                filledInfo: {
                    backgroundColor: "rgba(50, 112, 220, 1)"
                },
                filledWarning: {
                    color: darkBlue,
                    backgroundColor: "#ff9800",
                },
                filledError: {
                    color: darkBlue
                },
                filledSuccess: {
                    color: darkBlue,
                    backgroundColor: primaryLight
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: `'Rubik', sans-serif`,
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: darkBlue,
                    borderColor: darkBlue,
                    "&.Mui-selected": {
                        backgroundColor: darkBlue,
                        "&:hover": {
                            backgroundColor: darkBlue
                        }
                    }
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    paddingLeft: "0px !important",
                    paddingRight: "16px !important",
                    minHeight: "56px !important"
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: `'Rubik', sans-serif !important`,
    }
});

export default responsiveFontSizes(theme);
