import React from "react";
import {Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function PropellerAdsIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://propellerads.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/propellerads.svg" alt="PropellerAds logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate PropellerAds with Skro?"/>
            <Box>
                <p>
                    PropellerAds integration gives you the ability to manage and optimize all your PropellerAds
                    ads through the Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, creatives and zones every 30 min.</li>
                    <li>Check the status of your campaigns, creatives and zones.</li>
                    <li>Pause or resume campaigns, creatives and zones.</li>
                    <li>Change the bid value for campaigns and zones.</li>
                </ul>
                <DocImage src="/images/docs/manage-campaigns.jpg" alt="Manager PropellerAds campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>How to generate PropellerAds API token?</h2>
                <ul>
                    <li>Log in to your <strong>PropellerAds</strong> account.</li>
                    <li>Navigate to <strong>Account settings</strong> -> <strong>API Access</strong>.</li>
                </ul>
                <DocImage src="/images/docs/propellerads-api-token-1.jpg" alt="PropellerAds API Token 1"
                />
                <ul>
                    <li>Click on <strong>Create token</strong> button.</li>
                    <li>Copy the token to clipboard.</li>
                </ul>
                <DocImage src="/images/docs/propellerads-api-token-2.jpg" alt="PropellerAds API Token 1"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add integration to Skro</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-integration-1.jpg" alt="PropellerAds Integration step 1"
                />
                <ul>
                    <li>Click on <strong>PropellerAds</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/propellerads-integration-1.jpg" alt="PropellerAds Integration step 2"
                />
                <ul>
                    <li>
                        Paste the <strong>PropellerAds API Token</strong> to <strong>API Token</strong> field and
                        click <strong>Create Integration</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/propellerads-integration-2.jpg" alt="PropellerAds Integration step 3"
                />
                <ul>
                    <li>You can turn on/off auto-cost update through <strong>Data transfer</strong> switch.</li>
                </ul>
                <DocImage src="/images/docs/propellerads-integration-3.jpg" alt="PropellerAds Integration step 4"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>
                    Setup <strong>Postback URL</strong> and associate the integration with PropellerAds traffic source.
                </h2>
                <ul>
                    <li>Log in to your PropellerAds account.</li>
                    <li>Navigate to the <strong>Tracking</strong>.</li>
                    <li>Select a <strong>other tracker or CPA network</strong>, because Skro isn't listed.</li>
                    <li>Click on the link <strong>Copy this S2S Postback URL</strong></li>
                </ul>
                <DocImage src="/images/docs/propellerads-tracking-1.jpg"
                          alt="Setup PropellerAds conversion tracking step 1"
                />
                <ul>
                    <li>Go back to Skro and create a new traffic source.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-1.jpg"
                          alt="Setup PropellerAds traffic source step 1"
                />
                <ul>
                    <li>Click on <strong>PropellerAds</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/propellerads-traffic-source-3.jpg"
                          alt="Setup PropellerAds traffic source step 2"
                />
                <ul>
                    <li>Paste that S2S Postback URL to <strong>Traffic Source Postback URL</strong> field.</li>
                    <li>
                        In this example our S2S postback URL
                        is <strong>{`"http://ad.propellerads.com/conversion.php?aid=3490101&pid=&tid=96357&visitor_id=\${SUBID}&payout=\${PAYOUT}"`}</strong>
                    </li>
                    <li><strong>${`{SUBID}`}</strong> - Change this macro to <strong>{`{externalId}`}</strong></li>
                    <li><strong>${`{PAYOUT}`}</strong> - Change this macro to <strong>{`{payout}`}</strong></li>
                </ul>
                <DocImage src="/images/docs/propellerads-traffic-source-1.jpg"
                          alt="Setup PropellerAds traffic source step 2"
                />
                <ul>
                    <li>Add integration association.</li>
                </ul>
                <DocImage src="/images/docs/propellerads-traffic-source-2.jpg"
                          alt="Setup PropellerAds traffic source step 3"
                />
                <ul>
                    <li>All parameters are pre-filled.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default PropellerAdsIntegration;
