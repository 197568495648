import React from "react";
import {Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocLinkBox, DocRoot} from "../Docs";

function AboutDeploySitesOnAws() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://aws.amazon.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/aws.svg" alt="AWS logo"/>
                </a>
            </Box>
            <DocHeaderTitle
                title="Why AWS S3 + CloudFront + Certificate Manager is Ideal for Hosting Static Websites?"/>
            <Box>

                <p>
                    As a marketer, you need a hosting solution for your static websites that’s not only cost-effective
                    but also scalable, secure, and easy to manage. That’s where Skro comes in. We've seamlessly
                    integrated with AWS to provide you with a powerful, serverless solution that leverages Amazon S3,
                    CloudFront, and AWS Certificate Manager.
                </p>
                <h2 style={{paddingTop: "24px"}}>1. Amazon S3: Reliable and Scalable Storage</h2>
                <DocDivider/>
                <p>
                    Amazon S3 is a highly durable and scalable object storage service that is perfect for hosting static
                    websites. It offers several benefits:
                </p>
                <ul>
                    <li>
                        <strong>Simplicity:</strong> S3 allows you to host static files (HTML, CSS, JavaScript, images,
                        etc.) directly from the storage bucket. It’s straightforward to set up and manage.
                    </li>
                    <li>
                        <strong>Scalability:</strong> S3 automatically scales to handle any amount of traffic, ensuring
                        your website is available even during sudden traffic spikes.
                    </li>
                    <li>
                        <strong>Durability and Reliability:</strong> S3 provides 99.999999999% durability, meaning your
                        website's files are stored redundantly across multiple locations to prevent data loss.
                    </li>
                    <li>
                        <strong>Cost-Effective:</strong> You only pay for the storage and the amount of data transferred
                        out of S3, making it a budget-friendly option.
                    </li>
                </ul>

                <h2 style={{paddingTop: "24px"}}>2. Amazon CloudFront: Global Content Delivery with Low Latency</h2>
                <DocDivider/>
                <p>
                    While S3 is excellent for storage, Amazon CloudFront, a content delivery network (CDN), complements
                    it by accelerating the delivery of your website content globally:
                </p>
                <ul>
                    <li>
                        <strong>Low Latency:</strong> CloudFront caches your content at edge locations around the world,
                        ensuring fast delivery to users, regardless of their location.
                    </li>
                    <li>
                        <strong>Scalability:</strong> Like S3, CloudFront scales automatically with your traffic. It
                        handles
                        large spikes in demand seamlessly.
                    </li>
                    <li>
                        <strong>DDoS Protection:</strong> CloudFront provides built-in protection against DDoS attacks,
                        adding an extra layer of security to your website.
                    </li>
                    <li>
                        <strong>Custom SSL/TLS Support:</strong> With CloudFront, you can easily configure SSL/TLS
                        certificates, ensuring secure data transmission between your users and your website.
                    </li>
                </ul>

                <h2 style={{paddingTop: "24px"}}>3. AWS Certificate Manager: Easy and Free SSL/TLS Certificates</h2>
                <DocDivider/>
                <p>
                    Security is crucial for any website, and using HTTPS is a standard practice. AWS Certificate Manager
                    (ACM) simplifies the process of obtaining and managing SSL/TLS certificates:
                </p>
                <ul>
                    <li>
                        <strong>Automated Certificate Management:</strong> ACM handles the provisioning, renewal, and
                        management of certificates, so you don’t have to worry about manual updates.
                    </li>
                    <li>
                        <strong>No Cost for Public Certificates:</strong> ACM provides SSL/TLS certificates for free,
                        reducing your costs while ensuring secure data transmission.
                    </li>
                    <li>
                        <strong>Seamless Integration with CloudFront:</strong> ACM integrates seamlessly with
                        CloudFront, allowing you to deploy secure certificates with just a few clicks.
                    </li>
                </ul>

                <h2 style={{paddingTop: "24px"}}>Pricing Examples</h2>
                <DocDivider/>
                <p>
                    To illustrate the cost-effectiveness of using AWS S3, CloudFront, and Certificate Manager, let’s
                    look at two pricing examples. These estimates are based on AWS pricing as of 2024. Note that actual
                    costs may vary depending on your specific usage patterns and AWS region.
                </p>
                <p><strong>
                    Example 1: Hosting a Single Website with 1 Million Requests per Month
                </strong></p>

                <ul>
                    <li>Amazon S3:</li>
                    <ul>
                        <li><strong>Storage:</strong> 50 MB (0.05 GB) of website content stored in S3.</li>
                        <li><strong>Data Transfer Out:</strong> Let’s assume 10 GB of data is transferred out of S3 to
                            CloudFront.
                        </li>
                        <li>
                            <strong>Estimated Cost:</strong>
                        </li>
                        <ul>
                            <li><strong>Storage:</strong> 0.05 GB * $0.023/GB = $0.00115 per month.</li>
                            <li><strong>Data Transfer:</strong> 10 GB * $0.90/GB = $0.90 per month.</li>
                        </ul>
                    </ul>
                    <li>Amazon CloudFront:</li>
                    <ul>
                        <li><strong>Data Transfer:</strong> Assuming all 10 GB of data is delivered through CloudFront
                            to users.
                        </li>
                        <li><strong>Requests:</strong> 1 million GET requests.</li>
                        <li><strong>Estimated Cost:</strong></li>
                        <ul>
                            <li><strong>Data Transfer:</strong> 10 GB * $0.085/GB = $0.85 per month.</li>
                            <li><strong>Requests:</strong> 1,000,000 / 10,000 * $0.0075 = $0.75 per month.</li>
                        </ul>
                    </ul>
                    <li>AWS Certificate Manager:</li>
                    <ul>
                        <li><strong>SSL/TLS Certificate:</strong> Free.</li>
                    </ul>
                    <li>Total Estimated Cost:</li>
                    <ul>
                        <li><strong>S3:</strong> $0.00115 + $0.90 = $0.90115</li>
                        <li><strong>CloudFront:</strong> $0.85 + $0.75 = $1.60</li>
                        <li><strong>Grand Total:</strong> $0.90115 + $1.60 = $2.50115 per month.</li>
                    </ul>
                </ul>


                <br/>
                <br/>
                <p><strong>Example 2: Hosting 5 Landing Pages, Each with 100,000 Requests per Month</strong></p>
                <ul>
                    <li>Amazon S3:</li>
                    <ul>
                        <li><strong>Storage:</strong> 50 MB per landing page, totaling 250 MB (0.25 GB) for all 5
                            landing pages.
                        </li>
                        <li><strong>Data Transfer Out:</strong> 10 GB of data transferred out (2 GB per landing page).
                        </li>
                        <li>
                            <strong>Estimated Cost:</strong>
                        </li>
                        <ul>
                            <li><strong>Storage:</strong> 0.25 GB * $0.023/GB = $0.00575 per month.</li>
                            <li><strong>Data Transfer:</strong> 10 GB * $0.09/GB = $0.90 per month.</li>
                        </ul>
                    </ul>
                    <li>Amazon CloudFront:</li>
                    <ul>
                        <li><strong>Data Transfer:</strong> 10 GB of data delivered through CloudFront.
                        </li>
                        <li><strong>Requests:</strong> 5 * 100,000 = 500,000 requests.</li>
                        <li><strong>Estimated Cost:</strong></li>
                        <ul>
                            <li><strong>Data Transfer:</strong> 10 GB * $0.085/GB = $0.85 per month.</li>
                            <li><strong>Requests:</strong> 500,000 / 10,000 * $0.0075 = $0.375 per month.</li>
                        </ul>
                    </ul>
                    <li>AWS Certificate Manager:</li>
                    <ul>
                        <li><strong>SSL/TLS Certificate:</strong> Free.</li>
                    </ul>
                    <li>Total Estimated Cost:</li>
                    <ul>
                        <li><strong>S3:</strong> $0.00575 + $0.90 = $0.90575</li>
                        <li><strong>CloudFront:</strong> $0.85 + $0.375 = $1.225</li>
                        <li><strong>Grand Total:</strong> $0.90575 + $1.225 = $2.13075 per month.</li>
                    </ul>
                </ul>

                <h2 style={{paddingTop: "24px"}}>Summary</h2>
                <DocDivider/>
                <p>
                    With smaller storage requirements (50 MB per site), the costs associated with S3 storage become
                    almost negligible. In both scenarios, the major costs are driven by data transfer and request
                    handling through CloudFront, making this combination of services not only efficient but also very
                    affordable for small to moderately trafficked static websites.
                </p>

            </Box>

            <DocLinkBox to="/docs/deploy-website/aws-api-integration" title="Get started with AWS integration ->"/>

            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default AboutDeploySitesOnAws;
