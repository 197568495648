import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function System1Integration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://system1.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/system1.svg" alt="System1 logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate System1 with Skro?"/>
            <Box>
                <h2>Who we are</h2>
                <p>
                    <a href="https://system1.com" target="_blank" rel="noreferrer">
                        System1
                    </a> is led by passionate entrepreneurs & industry veterans who have built and managed
                    multiple billion dollar companies. Our team is 500+ and growing, and is an incredible group of
                    engineers, product managers, data scientists, & buy and sell-side experts. We have completed a
                    series of strategic acquisitions, expanded around the world, and we are just getting started.
                </p>
                <h2>What we do</h2>
                <p>
                    We combine our best-in-class technology & data science to operate one of the world’s most advanced
                    Responsive Acquisition Marketing Platforms (RAMP). RAMP enables us to build powerful brands across
                    multiple consumer verticals, develop & grow our suite of privacy-focused products, and deliver
                    high-intent customers to our advertising partners.
                </p>
                <h2>Integration</h2>
                <p>
                    Skro integration with System1 gives you the ability to sync earnings data that has been adjusted
                    after different traffic sources.
                </p>
                <ul>
                    <li>Update revenue of the previous 2 days once a day.</li>
                </ul>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>1. Add integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>
                        Click on the <strong>Add</strong> button in Affiliate Networks & Search Feed Arbitrage section.
                    </li>
                </ul>
                <DocImage src="/images/docs/aff-network-integration-1.jpg" alt="System1 integration step 1"
                />
                <ul>
                    <li>Click on the <strong>System1</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/system1-integration.jpg" alt="System1 integration step 2"
                />
                <ul>
                    <li>
                        <strong>API Key</strong> info you must ask from your System1
                        account manager or write to System1 support.
                    </li>
                    <li><strong>System1 Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a different columns in your Skro reports.
                    </li>
                    <li>
                        <strong>Click ID Param</strong> is the parameter what you use in your offer URL to send Skro
                        Click ID.
                    </li>
                </ul>
                <DocImage src="/images/docs/system1-integration-from.jpg" alt="System1 integration step 3"
                />
                <ul>
                    <li>Fill the form and click <strong>Save & Close</strong></li>
                </ul>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>

                <Divider/>
                <h2>2. Create a Custom Conversions for System1 events.</h2>

                <p>
                    With custom conversion events feature it is possible to track different types of conversions. For
                    System1 we are going to use 2 different types of
                    conversions: <strong>Search</strong> and <strong>Purchase</strong>.
                </p>

                <ul>
                    <li>
                        Go to <strong>Custom Conversions</strong> page <a
                        href="https://app.skro.eu/settings/custom-events" target="_blank" rel="noreferrer">here</a>
                    </li>
                    <li>
                        Click on <strong>Create</strong> button and add <strong>Search</strong> event.
                    </li>
                </ul>
                <p></p>
                <DocImage src="/images/docs/system1-search.png" alt="System1 event search"
                />

                <Alert color="info" variant="filled">
                    That postback goes to your offer URL:<br/>
                    {`&search_track_url = https://your_tracking_domain/postback?clickId={clickId}&ce=Search`}
                </Alert>

                <ul>
                    <li>
                        Create an another event to track revenue (clicks) on your System1 offer page. Create an event
                        name <strong>Purchase</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/system1-purchase.png" alt="System1 event purchase"
                />
                <Alert color="info" variant="filled">
                    That postback goes to your offer URL:<br/>
                    {`&click_track_url = https://your_tracking_domain/postback?clickId={clickId}&ce=Purchase`}
                </Alert>

                <Alert color="warning" variant="filled" sx={{mt: 2}}>
                    {`Note: Always make sure to encode the postback URLs, before appending them to your offer URL. Do not encode Skro click id macro {clickId}`}
                </Alert>

                <Divider sx={{mt: 2}}/>

                <h2>3. How to add System1 offer to Skro?</h2>

                <ul>
                    <li>1. Go to <strong>Offers</strong> page.</li>
                    <li>2. Click on <strong>Create</strong> button and add your offer URL and fill other fields.</li>
                </ul>

                <p>This is an example offer URL which goes to Skro:</p>
                <Alert color="info" variant="filled">
                    {`http://free-online-courses-us-1.com/?forcekeyA=digital+marketing+online+course&forcekeyB=digital+marketing+degree+online&forcekeyC=how+to+be+successful+in+digital+marketing&forcekeyD=free+online+courses&forcekeyE=digital+marketing+course&sub_id={clickId}&ref=tiktok-{custom7}&rskey=digital+marketing+course&headline=digital+marketing+courses&search_track_url=https%3A%2F%2Fyour_tracking_domain%2Fpostback%3FclickId%3D{clickId}%26ce%Search%26protectionToken%3DxyUHBuwKZT&click_track_url=https%3A%2F%2Fyour_tracking_domain%2Fpostback%3FclickId%3D{clickId}%26ce%3DPurchase`}
                </Alert>
                <p>Encoded System1 offer URL which goes to System1:</p>
                <Alert color="info" variant="filled">
                    {`http%3A%2F%2Ffree-online-courses-us-1.com%2F%3FforcekeyA%3Ddigital%2Bmarketing%2Bonline%2Bcourse%26forcekeyB%3Ddigital%2Bmarketing%2Bdegree%2Bonline%26forcekeyC%3Dhow%2Bto%2Bbe%2Bsuccessful%2Bin%2Bdigital%2Bmarketing%26forcekeyD%3Dfree%2Bonline%2Bcourses%26forcekeyE%3Ddigital%2Bmarketing%2Bcourse%26sub_id%3D%7BclickId%7D%26ref%3Dtiktok-%7Bcustom7%7D%26rskey%3Ddigital%2Bmarketing%2Bcourse%26headline%3Ddigital%2Bmarketing%2Bcourses%26search_track_url%3Dhttps%253A%252F%252Fyour_tracking_domain%252Fpostback%253FclickId%253D%7BclickId%7D%2526ce%25Search%2526protectionToken%253DxyUHBuwKZT%26click_track_url%3Dhttps%253A%252F%252Fyour_tracking_domain%252Fpostback%253FclickId%253D%7BclickId%7D%2526ce%253DPurchase`}
                </Alert>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default System1Integration;
