import React, {useEffect} from "react";
import PropTypes from "prop-types";
import PricingSection from "../home/PricingSection";
import {Box} from "@mui/material";

function Pricing(props) {
    const {selectPricing} = props;
    useEffect(() => {
        selectPricing();
    }, [selectPricing]);
    return (
        <Box>
            <PricingSection/>
        </Box>
    );
}

Pricing.propTypes = {
    selectPricing: PropTypes.func.isRequired
};

export default Pricing;
