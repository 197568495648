import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Typography, Box} from "@mui/material";
import {DocRoot} from "../docs/Docs";

function PrivacyPolicy(props) {
    const {selectPrivacyPolicy} = props;

    useEffect(() => {
        selectPrivacyPolicy();
    }, [selectPrivacyPolicy]);

    return (
        <DocRoot>
            <Box pt={10}>
                <Typography variant="h3" align="center">
                    Privacy Policy
                </Typography>
                <Typography variant="h6" align="center">
                    Last updated: June 3, 2023
                </Typography>
                <Box p={2}>
                    <Typography variant={"h4"} sx={{paddingTop: 3, fontSize: 24}}>
                        OVERVIEW
                    </Typography>
                    <p>
                        The English language version of this Privacy Policy shall be controlling in all respects and
                        shall prevail in case of any inconsistencies with translated versions, if any.
                    </p>
                    <p>
                        This notice is made by Skro OÜ, trading as Skro ("Skro", "we", "us" or "our") is devoted to keep
                        the personal information privacy of the visitors of the www.skro.eu website (the "Site") and
                        users of the services available thereon (the "Services").
                    </p>
                    <p>
                        Any adjustment introduced to this Policy will be available on the Site and/or Services and
                        enters into its effect when posted. If you continue to use the Site or Services after the
                        corresponding adjustments have been introduced to the Privacy Policy it will be considered as
                        your conscience acceptance with the adjustments introduced.
                    </p>

                    <Typography variant={"h4"} sx={{paddingTop: 3, fontSize: 24}}>
                        1 SECTION - WHAT INFORMATION WE COLLECT
                    </Typography>
                    <p>
                        1.1 If you join us as a user of our Site or Services, we require the personal information such
                        as personal name, company name, address, email address, credit card information and other
                        information to provide the Services to you: account activation, communication on the account
                        status and other purposes described and regulated by this Policy. By providing us with the
                        personal information in the process of using our Site or Services you permit the collection, use
                        and disclosure of your personal information to us as specified in this Privacy Policy.
                    </p>
                    <p>
                        1.2 When you register or log on through Google sign-in functionality and grant the “basic
                        profile
                        info”, Google provides us with its Google ID Token that includes your name and surname, email
                        address, language settings and profile picture but we only retrieve your name, surname and email
                        address to verify your identity for our needs while registering or logging you in.
                    </p>
                    <p>
                        1.3 We might in some cases ask you to additionally agree to the collection, use, or disclosure
                        of your personal information in circumstances that might require these actions. In some
                        occasions your consent will be implied through your behavior or actions if the collecting,
                        using, or disclosing information process is apparent in certain circumstances or in case when
                        you personally provide us with the information on a voluntary basis.
                    </p>
                    <p>
                        1.4 The purposes of the personal or account-based information using include the following cases:
                    </p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>provision of the Service, quality control and improvements of the Site or/and Services;</li>
                        <li>
                            provision of the information for more effective using of the Site or/and Services;
                        </li>
                        <li>
                            creation, management and control of the account information;
                        </li>
                        <li>
                            access verification to the Site or/and Services;
                        </li>
                        <li>
                            account billing according to the pricing plan selected;
                        </li>
                        <li>
                            communication with the users of the Site or/and Services in order to inform about the
                            changes, updates, adjustments to the Site or/and Services or of the availability of the Site
                            or/and Services.
                        </li>
                        <li>
                            evaluation of the quality level of the Site or/and Services, traffic patterns monitoring,
                            measuring the demand and frequency of usage of the Site or/and Services;
                        </li>
                        <li>
                            conducting marketing activities;
                        </li>
                        <li>
                            ensuring the compliance with this privacy Policy;
                        </li>
                        <li>
                            formulation of responses to claims about any violation of our rights or the rights of third
                            parties;
                        </li>
                        <li>
                            processing the customer services requests;
                        </li>
                        <li>
                            protection of the rights, property, or personal safety of us and our users and the public,
                            and as required or permitted by law.
                        </li>
                    </ol>
                    <p>
                        1.5 We might contact you regarding our products, services, news and events while you have the
                        option to opt out of receiving any information from us. We provide the opt-out option in all the
                        email messages of this kind. You can also contact us directly in any convenient way to opt out
                        of receiving any information from us.
                    </p>
                    <p>
                        1.6 You may not opt out of receiving messages related to the provision of services, including
                        information related to your account, planned suspensions or disconnections of the Services, when
                        necessary.
                    </p>
                    <p>
                        1.7 We may collect data necessary to process your payment if you make purchases, such as your
                        payment instrument number (such as a credit card number), and the security code associated with
                        your payment instrument. All payment data is stored by Stripe. You may find their
                        privacy notice link
                        here: <a target="_blank" rel="noreferrer noopener"
                                 href="https://stripe.com/privacy">Stripe Privacy Notice</a>
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>2 SECTION - USER DATA FROM
                        THIRD-PARTY</Typography>
                    <p>
                        2.1 We offer tools to help our users to manage, optimize and track third-party campaigns,
                        including: <strong>Google Ads</strong>, <strong>Meta Ads</strong>, <strong>Microsoft
                        Ads</strong>, <strong>TikTok
                        Ads</strong>, <strong>ExoClick</strong>, <strong>Mondiad</strong>, <strong>PropellerAds</strong>.
                        To take use of this feature, our users will need
                        to connect their accounts with Skro.
                    </p>
                    <ul>
                        <li>
                            Skro's use and transfer of data to any other app of information received from Google's
                            Accounts will adhere to
                            the <a target="_blank" rel="noreferrer noopener"
                                   href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                            Google API Services User Data Policy</a>, including Limited Use requirements.
                        </li>
                    </ul>
                    <p>
                        2.2 <strong>Accessing Data</strong>: We access your third-party user data by communicating with
                        API in the manner of server-to-server. We will never access your data if you doesn't grant us to
                        do so.
                    </p>
                    <p>
                        2.3 <strong>Storing Data</strong>: We only store the Authorization Token, Refresh Token, Ad
                        Account ID, Ad Account Timezone and Ad Account Currency in our cloud-based database, which is
                        only accessible by application, not humans.
                    </p>
                    <p>
                        2.4 <strong>Using Data</strong>: We use the data to post conversions back to third-parties, to
                        sync your ads spend to our platform, and to enable/disable your campaigns, zones, sites, ad
                        sets, ad groups and ads from our platform by automation/your action.
                    </p>
                    <p>
                        2.5 <strong>Sharing Data</strong>: We will never share your data with others, your data is
                        safely stored in our cloud-based database, no humans can access it.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>3 SECTION - AGE OF CONSENT</Typography>
                    <p>
                        3.1 We do not knowingly provide the services to individuals who are under the age of consent.
                    </p>
                    <p>
                        3.2 We do not knowingly collect personal data from persons under the age of consent.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>4 SECTION - ACCESS TO THE
                        INFORMATION</Typography>
                    <p>
                        4.1 Personal information can be accessed and edited by you anytime through the web panel.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>5 SECTION - DISCLOSURE</Typography>
                    <p>
                        5.1 Your information may only be shared with the third parties in order to provide you with the
                        specific services in accordance with the applicable privacy laws. According to the general rule
                        we do not and will not sell, rent, share or disclose your personal information without first
                        obtaining your permission or in accordance with this Privacy Policy.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>6 SECTION - AGGREGATED DATA</Typography>
                    <p>
                        6.1 Your personal information may be used to create aggregated data that is necessary for
                        internal use and for sharing with the third parties as necessary. "Aggregated data" refers to
                        the records without information that potentially identifies customers, landing pages, or
                        end-users. This data is processed or combined to provide aggregated and anonymous information.
                        Your identity and personal information will be stored anonymously in Aggregated Data.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>7 SECTION - LINKS</Typography>
                    <p>
                        7.1 The Site might contain links to other websites. We try to cooperate with only trusted
                        partners however we do not take the responsibility for their privacy policies or content of
                        their sites. We always recommend that you make yourself familiar with the privacy policies of
                        these websites as their policies and practices might or totally differ from our policies and
                        practices.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>8 SECTION - COOKIES DATA</Typography>
                    <p>
                        8.1 Like any other site, our web site uses cookies and log files in the process of its
                        operation. Cookies are a small amount of the data that is passed to your web browser via a web
                        server. In terms of our Services this data is stored on your device to ensure the proper
                        operation of our website and allows us to continually improve the speed and security of our
                        website, enhance our website, and our marketing goals to provide you with the best experience
                        when using our Site or/and Services.
                    </p>
                    <p>
                        8.2 Types of cookies that we use on the Site and Services: Our own cookies are used to provide
                        you with the comment function on our Site or/and Services and determine if you are logged in or
                        not. These cookies are necessary to support the functionality of the Site or/and Services. The
                        only way to prevent the using of these cookies is to opt out of using our Site and Services.
                    </p>
                    <p>
                        8.3 Visitor cookies is an anonymous form of cookie that is necessary to collect the data about
                        the amount of visitors, the type of technology (browser type, device type, language, operational
                        system), and the part of the Site that the user is interested in. This data is necessary to
                        provide our Services and optimise and enhance the performance of our Site and Services.
                    </p>
                    <p>
                        8.4 Advertising cookies allow us to perform our marketing goals and to provide our Services.
                        Advertising cookies do not provide any details that can be determined as the personal
                        information for us or our ad partners. To opt-out of almost all advertising cookies, follow the
                        link <a target="_blank" rel="noreferrer noopener"
                           href="https://www.youronlinechoices.com/uk/your-ad-choices">
                            https://www.youronlinechoices.com/uk/your-ad-choices</a>
                    </p>
                    <p>
                        8.5 Referral Program Cookies help us promote our services with your help. We pay a referral
                        commission to our referral program participants according to its terms and conditions. Cookies
                        are necessary in this case to identify referrals and provide rewards to members of the referral
                        program. Referral program cookies do not contain any personal information for us or our referral
                        program partners.
                    </p>
                    <p>
                        8.6 Although cookies do not contain any personal information, you can disable them by changing
                        your browser settings to prevent it from accepting cookies. However, this might cause some
                        features of the Site or/and Services to stop working.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        9 SECTION - WHAT ARE YOUR PRIVACY RIGHTS?
                    </Typography>

                    <p>
                        9.1 In some regions (like the EEA, UK, and Canada), you have certain rights under applicable
                        data protection laws. These may include the right (I) to request access and obtain a copy of
                        your personal information, (II) to request rectification or erasure; (III) to restrict the
                        processing of your personal information; and (IV) if applicable, to data portability. In certain
                        circumstances, you may also have the right to object to the processing of your personal
                        information. You can make such a request by contacting us by using the contact details provided
                        below. We will consider and act upon any request in accordance with applicable data protection
                        laws.
                    </p>
                    <p>
                        9.2 If you are located in the EEA or UK and you believe we are unlawfully processing your
                        personal
                        information, you also have the right to complain to your local data protection supervisory
                        authority. You can find their contact details
                        here: <a target="_blank" rel="noreferrer noopener"
                           href="https://ec.europa.eu/newsroom/article29/items/612080">
                            https://ec.europa.eu/newsroom/article29/items/612080</a>.
                    </p>
                    <p>
                        9.3 If you are located in Switzerland, the contact details for the data protection authorities
                        are
                        available here: <a target="_blank" rel="noreferrer noopener"
                                           href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
                    </p>
                    <p>
                        9.4 Withdrawing your consent: If we are relying on your consent to process your personal
                        information, which may be express and/or implied consent depending on the applicable law, you
                        have the right to withdraw your consent at any time. You can withdraw your consent at any time
                        by contacting us by using the contact details provided below. However, please note that this
                        will not affect the lawfulness of the processing before its withdrawal, nor when applicable law
                        allows, will it affect the processing of your personal information conducted in reliance on
                        lawful processing grounds other than consent.
                    </p>
                    <p>
                        9.5 Opting out of marketing and promotional communications: You can unsubscribe from our
                        marketing
                        and promotional communications at any time by clicking on the unsubscribe link in the emails
                        that we send, or by contacting us using the details provided below. You will then be removed
                        from the marketing lists. However, we may still communicate with you — for example, to send you
                        service-related messages that are necessary for the administration and use of your account, to
                        respond to service requests, or for other non-marketing purposes.
                    </p>
                    <p>
                        9.6 <strong>Account Information</strong>: If you would at any time like to review or change the
                        information in your account or terminate your account, you can log in to your account settings
                        and update your user account.
                    </p>
                    <p>
                        9.7 Upon your request to terminate your account, we will deactivate or delete your account and
                        information from our active databases. However, we may retain some information in our files to
                        prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms
                        and/or comply with applicable legal requirements.
                    </p>
                    <p>
                        9.8 <strong>Cookies and similar technologies</strong>: Most Web browsers are set to accept
                        cookies by default. If you prefer, you can usually choose to set your browser to remove
                        cookies and to reject cookies. If you choose to remove cookies or reject cookies, this
                        could affect certain features or services of our Services. To opt out of interest-based
                        advertising by advertisers on our Services visit <a target="_blank" rel="noreferrer noopener"
                                                                            href="http://www.aboutads.info/choices/">
                        http://www.aboutads.info/choices/</a>. For further information, please see our Cookie
                        Notice: <a target="_blank" rel="noreferrer noopener"
                                   href="https://skro.eu/cookie-policy">
                        https://skro.eu/cookie-policy</a>.
                    </p>
                    <p>
                        If you have questions or comments about your privacy rights, you may email us
                        at <strong>support@skro.eu</strong>.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        10 SECTION - DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </Typography>

                    <p>
                        10.1 California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
                        users who are California residents to request and obtain from us, once a year and free of
                        charge, information about categories of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and addresses of all third parties with
                        which we shared personal information in the immediately preceding calendar year. If you are a
                        California resident and would like to make such a request, please submit your request in writing
                        to us using the contact information provided below.
                    </p>
                    <p>
                        10.2 If you are under 18 years of age, reside in California, and have a registered account with
                        Services, you have the right to request removal of unwanted data that you publicly post on the
                        Services. To request removal of such data, please contact us using the contact information
                        provided below and include the email address associated with your account and a statement that
                        you reside in California. We will make sure the data is not publicly displayed on the Services,
                        but please be aware that the data may not be completely or comprehensively removed from all our
                        systems (e.g., backups, etc.).
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        11 SECTION - CHANGE OF OWNERSHIP OR BUSINESS TRANSITION
                    </Typography>
                    <p>
                        11.1 In case of ownership change or other business transition, such as a merger, acquisition, or
                        sale of our assets, the information is transferred in accordance with applicable privacy laws.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>12 SECTION - SECURITY</Typography>
                    <p>
                        12.1 We are committed to preventing unauthorized access to your personal information. However,
                        no
                        method of data transmission can be considered completely secure. We are constantly improving our
                        security procedures.
                    </p>
                    <p>
                        12.2 Please remember that you ultimately control the access to your personal information. You
                        are
                        solely responsible for storing your identity and/or any personal information that is under your
                        control when using the Services. Be careful and responsible about managing your personal
                        information. We cannot control the access and use of any information by others with your
                        permission. You must be careful when choosing the personal information that you provide to
                        others through the services.
                    </p>
                    <p>
                        12.3 Similarly, we do not share any responsibility for the content of any personal information
                        or
                        other information that you get from others through the services, and you release us from any
                        liability concerning the content of any personal information or other information that you might
                        be receiving through the Services. We cannot guarantee or assume any responsibility for the
                        accuracy of personal information or other information provided by any third party. You release
                        us from any liability related to the use of such personal information or other information of
                        others.
                    </p>


                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>13 SECTION - CONTACT
                        INFORMATION</Typography>
                    <p>Skro OÜ</p>
                    <p>Registry code: 16173240</p>
                    <p>Address: Muuga põik 6, Laiaküla, Viimsi, Harju maakond 74008 Estonia</p>
                    <p>Customer support: support@skro.eu</p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>14 SECTION - TIME THE TERMS ENTER INTO
                        EFFECT</Typography>
                    <p>
                        These Terms enter into effect on June 3th, 2023, at 00.00 Eastern European Time (EET) and
                        apply to all actions performed on our Site, Service and within the Application since then.
                    </p>
                </Box>
            </Box>
        </DocRoot>
    );
}

PrivacyPolicy.propTypes = {
    selectPrivacyPolicy: PropTypes.func.isRequired
};

export default PrivacyPolicy;
