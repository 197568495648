import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function OutbrainIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://outbrain.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/outbrain.svg" alt="Outbrain Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Outbrain with Skro?"/>
            <Box>
                <p>
                    <a href="https://outbrain.com" target="_blank" rel="noreferrer">
                        Outbrain
                    </a> is the world’s leading discovery and native advertising feed for the open web. A third of
                    the world’s Internet-connected population explores and discovers information through our feed
                    technology, which is trusted by emerging to established brands and integrated into thousands of
                    media companies’ tech stacks to manage and monetize their publishing operations. Outbrain operates
                    in 55 countries and is headquartered in New York City, with offices in 18 cities worldwide.
                </p>
                <p>
                    Outbrain integration gives you the ability to manage and optimize all your Outbrain ad campaigns
                    through the Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, publishers and sections every 30 min.</li>
                    <li>Check the status of your campaigns, ads, publishers and sections.</li>
                    <li>Pause or resume campaigns, ads, publishers and sections.</li>
                    <li>Change bidding for campaigns and sections.</li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in Outbrain and Skro so Outbrain
                    can pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in Outbrain and Skro is required.
                </p>
                <p><strong>Manage Outbrain campaigns through the Skro UI</strong></p>
                <DocImage src="/images/docs/ob-reporting-1.png" alt="Manager Outbrain campaigns"
                />

                <p><strong>Manage Outbrain ads through the Skro UI</strong></p>
                <DocImage src="/images/docs/ob-reporting-2.png" alt="Manager Outbrain campaigns"
                />

                <p><strong>Manage Outbrain publishers and sections through the Skro UI</strong></p>
                <DocImage src="/images/docs/ob-reporting-3.png" alt="Manager Outbrain campaigns"
                />
                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Get Outbrain API Credentials</h2>
                <p>
                    Outbrain <strong>API Token</strong>. You can get one by following the steps below:
                </p>
                <ul>
                    <li>
                        In Outbrain, click on the email on the top right corner and navigate to
                        the <strong>Amplify API Token</strong>.
                    </li>
                    <li>Enter your password and generate API token.</li>
                </ul>
                <DocImage src="/images/docs/ob-cred-1.png" alt="Get Outbrain API token"
                />
                <ul>
                    <li>Enter your password and generate API token.</li>
                    <li>Copy the <strong>API Token</strong> to any text editing tool.</li>
                </ul>
                <DocImage src="/images/docs/ob-cred-2.png" alt="Get Outbrain API token"
                />
                <p>
                    Once you have everything ready, read the further part of this guide.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>II. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-1.png" alt="Outbrain Integration step 1"
                />
                <ul>
                    <li>Click on <strong>Outbrain</strong> logo and integration form will pop up.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-2.png" alt="Outbrain Integration step 2"
                />
                <ul>
                    <li>
                        Enter your <strong>Integration Name</strong>.
                    </li>
                    <li>
                        Enter your Outbrain <strong>API Token</strong>.
                    </li>
                    <li>Click <strong>Next</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-3.png" alt="Outbrain Integration step 3"
                />
                <ul>
                    <li>
                        Integrate your Outbrain Ad Account.
                    </li>
                    <li>Click <strong>Next</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-4.png" alt="Outbrain Integration step 4"
                />
                <ul>
                    <li>
                        If you want to send conversions info back to Outbrain, then you will need to
                        enable <strong>Conversion API.</strong>
                    </li>
                    <li>
                        <strong>Conversion Event Name</strong> - must be the same as you typed in Outbrain. When
                        creating Skro campaign you will need to specify which conversion goal to send to.
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to Outbrain.
                    </li>
                    <li>
                        Click on <strong>Click & Save</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/ob-integration-5.png" alt="Outbrain Integration step 5"
                />
                <ul>
                    <li>Your Outbrain account is successfully integrated.</li>
                    <li>You can switch on/off cost sync with given switch button.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-6.png" alt="Outbrain Integration step 6"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on
                        the <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/ob-integration-7.png" alt="Setup Outbrain traffic source Step 1"
                />
                <ul>
                    <li>Click on <strong>Outbrain</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-8.png" alt="Setup Outbrain traffic source step 2"
                />
                <ul>
                    <li>Postback URL is already set with correct macros.</li>
                    <li>Select your Outbrain integration from the dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-9.png" alt="Setup Outbrain traffic source step 3"
                />
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for Outbrain.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your pre-lander.
                </p>

                <ul>
                    <li>Navigate to the <strong>Landings</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your landing URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/ob-skro-campaign-0.png" alt="Setup Skro Landing"
                />

                <p>
                    The second thing you need to do is to create an offer.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-offer-1.png" alt="Setup Skro Offer"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as Outbrain.</li>
                    <li>
                        Select your <strong>Conversion Event Name</strong>. That is needed to send conversion info back
                        to Outbrain.
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/ob-skro-campaign-1.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/ob-skro-campaign-2.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>Choose your landing page</li>
                </ul>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 8}}>
                    In given example we use 1 landing and 1 offer. Outbrain scans links that you submit, you cannot use
                    a
                    redirect method of tracking, as that redirect URL would not be correctly scanned. With the direct
                    method of tracking, which is turned on by default for Outbrain, you will submit a direct tracking
                    URL to Outbrain. This will allow Outbrain to scan this link without a problem.
                </Alert>
                <ul>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/ob-skro-campaign-3.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose the <b>Direct</b> method.</li>
                    <li>Install <strong>Tracking Pixel</strong> to your landing page.</li>
                    <li>Install <strong>CPA button</strong> with correct <strong>Click To Offer Link</strong>.
                        In given example the link is <strong>https://skrotrack.com/click</strong>
                    </li>
                    <li>Select your landing page and copy the Landing URL with correct params and macros.</li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    In given example we have that kind of URL:<br/><br/>
                    {"https://prelander1.com/?trck=eyJjcGlkIjoiZmMxZmE5NGItMmRiOS00YzcyLWJhZGYtZmIxODI1MDJjODAxIiwiciI6MCwicCI6MCwibCI6MH0=&cpc={{cpc}}&ob_click_id={{ob_click_id}}&ad_title={{ad_title}}&ad_id={{ad_id}}&campaign_id={{campaign_id}}&publisher_id={{publisher_id}}&publisher_name={{publisher_name}}&section_id={{section_id}}&section_name={{section_name}}&promoted_link_id={{promoted_link_id}}"}
                </Alert>
                <DocImage src="/images/docs/ob-skro-campaign-4.png" alt="Setup Skro Campaign"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in Outbrain</h2>
                <p>
                    Now you have your campaign URL with tracking parameters added. In the next step you will submit it
                    as your item's URL in Outbrain campaign setup. In order to do that, perform the following
                    steps:
                </p>
                <ul>
                    <li>Sign in to your Outbrain account.</li>
                    <li>Go to the <strong>Campaigns</strong> view.</li>
                    <li>
                        Click the <strong>Create Campaign</strong> button and setup your campaign.
                    </li>
                </ul>
                <DocImage src="/images/docs/ob-campaign-1.png" alt="Setup Outbrain Campaign"
                />
                <ul>
                    <li>
                        Add your landing page without parameters. In given example we used
                        landing <strong>https://prelander1.com</strong>
                    </li>
                    <li>Select your conversion event name same as you added into Skro.</li>
                    <li>Click <strong>Next</strong>.</li>
                </ul>
                <DocImage src="/images/docs/ob-campaign-2.png" alt="Setup Outbrain Campaign"
                />
                <ul>
                    <li>Set up your creatives.</li>
                    <li>Click <strong>Next</strong>.</li>
                </ul>
                <DocImage src="/images/docs/ob-campaign-3.png" alt="Setup Outbrain Campaign"
                />
                <ul>
                    <li>Set up tracking suffix field and budget.</li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    In given example we have that kind of tracking parameters:<br/><br/>
                    {"trck=eyJjcGlkIjoiZmMxZmE5NGItMmRiOS00YzcyLWJhZGYtZmIxODI1MDJjODAxIiwiciI6MCwicCI6MCwibCI6MH0=&cpc={{cpc}}&ob_click_id={{ob_click_id}}&ad_title={{ad_title}}&ad_id={{ad_id}}&campaign_id={{campaign_id}}&publisher_id={{publisher_id}}&publisher_name={{publisher_name}}&section_id={{section_id}}&section_name={{section_name}}&promoted_link_id={{promoted_link_id}}"}
                </Alert>
                <DocImage src="/images/docs/ob-campaign-4.png" alt="Setup Outbrain Campaign"
                />
                <ul>
                    <li>
                        Paste the copied campaign tracking URL from Skro into the <strong>URL</strong> field.
                    </li>
                    <li>
                        Once your set up is done, click the <strong>Launch Campaign</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/ob-campaign-5.png" alt="Setup Outbrain Campaign"
                />

                <p>
                    Congratulations, you have successfully set up tracking of Outbrain traffic with Skro. Each time a
                    visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default OutbrainIntegration;
