import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function MgidIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", pb: "12px"}}>
                <a href="https://mgid.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/mgid.svg" alt="MGID Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate MGID with Skro?"/>
            <Box>
                <p>
                    <a href="https://mgid.com" target="_blank" rel="noreferrer">
                        MGID
                    </a> integration gives you the ability to manage and optimize all your MGID ad campaigns through
                    the Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, teasers and widgets every 30 min.</li>
                    <li>Check the status of your campaigns, teasers and widgets.</li>
                    <li>Pause or resume campaigns, teasers and widgets.</li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in MGID and Skro so MGID
                    can pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in MGID and Skro is required.
                </p>
                <p><strong>Manage MGID campaigns through the Skro</strong></p>
                <DocImage src="/images/docs/mgid-reporting-1.png" alt="Manager MGID campaigns"
                />

                <p><strong>Manage MGID teasers through the Skro</strong></p>
                <DocImage src="/images/docs/mgid-reporting-2.png" alt="Manager MGID campaigns"
                />

                <p><strong>Manage MGID widgets and sub ids through the Skro</strong></p>
                <DocImage src="/images/docs/mgid-reporting-3.png" alt="Manager MGID campaigns"
                />
                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Get MGID API Credentials</h2>
                <p>
                    MGID's API <strong>Client ID</strong> and <strong>Access Token</strong>. You can get one by
                    following the steps below:
                </p>
                <ol>
                    <li>In MGID, go to the <strong>MY PROFILE</strong> view.</li>
                    <li>Go to the <strong>USERS</strong> tab.</li>
                    <li>
                        Locate a user you want to grant API access to and click the <strong>Get a token</strong> button.
                    </li>
                </ol>
                <DocImage src="/images/docs/mgid-cred-1.png" alt="Get MGID API token"
                />
                <p>
                    Copy both the <strong>API Client ID</strong> and <strong>Access Token</strong> to any text editing
                    tool.
                </p>
                <DocImage src="/images/docs/mgid-cred-2.png" alt="Get MGID API token"
                />
                <p>
                    Once you have everything ready, read the further part of this guide.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>II. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-1.png" alt="MGID Integration step 1"
                />
                <ul>
                    <li>Click on <strong>MGID</strong> logo and integration form will pop up.</li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-2.png" alt="MGID Integration step 2"
                />
                <ul>
                    <li>
                        Enter your <strong>Integration Name</strong>.
                    </li>
                    <li>
                        Enter your MGID <strong>Client ID</strong>.
                    </li>
                    <li>
                        Enter your MGID <strong>Access Tokent</strong>.
                    </li>
                    <li>Click <strong>Next</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-3.png" alt="MGID Integration step 4"
                />
                <ul>
                    <li>
                        If you want to send conversions info back to MGID, then you will need to
                        enable <strong>Conversion API.</strong>
                    </li>
                    <li>
                        <strong>Conversion Event Name</strong> - must be the same as you typed in MGID. When
                        creating Skro campaign you will need to specify which conversion goal to send to.
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to MGID.
                    </li>
                    <li>
                        Click on <strong>Click & Save</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-4.png" alt="MGID Integration step 5"
                />
                <ul>
                    <li>You MGID account is successfully integrated.</li>
                    <li>You can switch on/off cost sync with given switch button.</li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-5.png" alt="MGID Integration step 5"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on
                        the <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-6.png" alt="Setup MGID traffic source Step 1"
                />
                <ul>
                    <li>Click on <strong>MGID</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-7.png" alt="Setup MGID traffic source step 2"
                />
                <ul>
                    <li>Postback URL is already set with correct macros.</li>
                    <li>Select your MGID integration from the dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/mgid-integration-8.png" alt="Setup MGID traffic source step 4"
                />
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for MGID.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your offer.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-offer-1.png" alt="Setup Skro Offer"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as MGID.</li>
                    <li>
                        Select your <strong>Conversion Event Name</strong>. That is needed to send conversion info back
                        to MGID.
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/mgid-skro-camp-1.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/mgid-skro-camp-2.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>
                        In given example we use only offers, Switch on <strong>Use only offers</strong> and select your
                        offer. With MGID you are allowed to use <strong>Redirect</strong> tracking method, so you can
                        add multiple landing pages and offers to your campaign.
                    </li>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/mgid-skro-camp-3.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose the <b>Redirect</b> method.</li>
                    <li>
                        Copy and paste the Campaign Tracking URL to MGID.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    In given example we have that kind of URL:<br/><br/>
                    {"https://skrotrack.com/campaign/e5e23337-50c4-4862-2272-d58886287abc?cost={click_price}&cid={click_id}&teaser_id={teaser_id}&campaign_id={campaign_id}&widget_id={widget_id}&category_id={category_id}"}
                </Alert>
                <DocImage src="/images/docs/mgid-skro-camp-4.png" alt="Setup Skro Campaign"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in MGID</h2>
                <p>
                    Now you have your campaign URL with tracking parameters added. In the next step you will submit it
                    as your item's URL in MGID campaign setup. In order to do that, perform the following
                    steps:
                </p>
                <ul>
                    <li>Sign in to your MGID account.</li>
                    <li>Go to the <strong>Advertisers</strong> tab.</li>
                    <li>
                        Click the <strong>Add Campaign</strong> button and setup your campaign.
                    </li>
                </ul>
                <DocImage src="/images/docs/mgid-campaign-1.png" alt="Setup MGID Campaign"
                />
                <p><strong>Campaign Settings</strong> - Provide all required information. This includes:</p>
                <DocImage src="/images/docs/mgid-campaign-3.png" alt="Setup MGID Campaign"
                />
                <ul>
                    <li>General.</li>
                    <li>Targeting.</li>
                    <li>Dynamic Retargeting .</li>
                    <li>Schedule</li>
                    <li>Tracking Tags</li>
                    <li>Conversion sensors</li>
                </ul>
                <DocImage src="/images/docs/mgid-campaign-2.png" alt="Setup MGID Campaign"
                />
                <p>Add your conversion event name same as you added into Skro.</p>
                <p>
                    Once you have provided the required information, click the <strong>Confirm</strong> button.
                </p>
                <p>
                    <strong>Create Teasers</strong> - Provide a target URL and a creative. In order to do so, perform
                    the steps below:
                </p>
                <ul>
                    <li>
                        Paste the copied campaign tracking URL from Skro into the <strong>URL</strong> field.
                    </li>
                    <li>
                        Upload a creative or choose one from the stock photo library, provide headlines. Once you set up
                        your ad, click the <strong>Save and Go</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/mgid-campaign-4.png" alt="Setup MGID Campaign"
                />

                <p>
                    Congratulations, you have successfully set up tracking of MGID traffic with Skro. Each time a
                    visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default MgidIntegration;
