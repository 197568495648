import React from "react";
import {Box, Link} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocLinkBox, DocRoot} from "../Docs";
import {Link as RouterLink} from "react-router-dom";

function WebsitesDomainSetup() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://aws.amazon.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/aws.svg" alt="AWS logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="Website Domain Setup"/>
            <Box>
                <h2 style={{paddingTop: "24px"}}>Step 1: Add your domain</h2>
                <DocDivider/>
                <p>
                    1. Go to the <strong>Websites</strong> and click on <strong>Add</strong> button.
                </p>
                <DocImage src="/images/docs/web-domain-1.png" alt="website domain 1"/>
                <p>
                    2. Select your workspace and AWS integration, and type your domain name what you want to use your
                    website deployment. Click on <strong>Next</strong> button. (Loading might take up to 10 sec).
                    In my example I'm using subdomain.
                </p>
                <DocImage src="/images/docs/web-domain-2.png" alt="website domain 2"/>
                <p>
                    2.1.To automatically verify your domain ownership, add your domain provider integration. This will
                    allow Skro to generate a CNAME record for seamless domain verification.
                </p>
                <p>
                    Automated verification are supported
                    with <strong>AWS (Route53)</strong>, <strong>Cloudflare</strong> and <strong>Namesilo</strong>.
                </p>
                <p>
                    - <Link
                    component={RouterLink}
                    to={"/docs/deploy-website/cloudflare-integration"}
                >
                    Cloudflare integration
                </Link>
                </p>
                <p>
                    - <Link
                    component={RouterLink}
                    to={"/docs/deploy-website/namesilo-integration"}
                >
                    Namesilo integration
                </Link>
                </p>
                <DocImage src="/images/docs/web-domain-2.1.png" alt="website domain 2"/>
                <p>
                    3. On the next step you will get CNAME record <strong>Name</strong> and <strong>Value</strong> to
                    verify your domain ownership.
                </p>
                <DocImage src="/images/docs/web-domain-3.png" alt="website domain 3"/>

                <h2 style={{paddingTop: "24px"}}>Step 2: Add CNAME record.</h2>
                <DocDivider/>
                <p>
                    1. In our example we use <strong>Namecheap</strong>. Log in to <strong>Namecheap</strong> account.
                </p>
                <p>
                    2. Select your domain and click on <strong>Manage DNS</strong> and click
                    on <strong>Add new record</strong> button.
                </p>
                <DocImage src="/images/docs/web-domain-3-1.png" alt="website domain 3-1"/>

                <p>
                    <strong>Host:</strong> is <strong>_f233c835f3ade019ae37fs7b8be22f91.cloud</strong>. As you see I
                    do not add <strong>.rtx-tires.com</strong> at the end because Namecheap will add that automatically.
                </p>
                <p>
                    <strong>Value:</strong> is <strong>_0a2c5474061306a110049876b4afa4f7.sdzjtdhdhz.acm-validations.aws.</strong>
                </p>
                <DocImage src="/images/docs/web-domain-3-2.png" alt="website domain 3-2"/>

                <h2 style={{paddingTop: "24px"}}>Step 3: Verify domain ownership and generate free SSL cert.</h2>
                <DocDivider/>
                <p>
                    1. Go back to Skro and click on <strong>Close</strong> button.
                </p>
                <p>
                    2. As you see your domain is in <strong>PENDING_VALIDATION</strong> status. DNS changes will take
                    some time to update and generate SSL for your domain. Usually it will take up to 5-15 minutes, but
                    it can take up to 48 hours.
                </p>
                <DocImage src="/images/docs/web-domain-4.png" alt="website domain 4"/>
                <p>
                    3. After 5 minutes, you can select your domain and click on <strong>Check Status</strong> button
                    to see if your domain is verified and SSL cert issued.
                </p>
                <DocImage src="/images/docs/web-domain-5.png" alt="website domain 5"/>
                <p>
                    Domain is ready for deployment.
                </p>
            </Box>

            <DocLinkBox to="/docs/deploy-website/how-to-deploy-website"
                        title="Get started with website deployment setup ->"/>

            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default WebsitesDomainSetup;
