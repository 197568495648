import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function FacebookAdditionalConversionAPIParameters() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/meta.svg" alt="Facebook (Meta) Ads Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to send additional parameters via Facebook Conversion API?"/>
            <Box>
                <p>
                    <strong>Requirements:</strong> Facebook Ad Account and Conversion API Integration.
                </p>
                <p>
                    If you need to send additional information via Facebook Conversion API, then you can use the
                    following parameters in your postback URL:
                </p>
                <ul>
                    <li><strong>firstName</strong> - Using Roman alphabet a-z characters is recommended.
                    </li>
                    <li><strong>lastName</strong> - Using Roman alphabet a-z characters is recommended.
                    </li>
                    <li><strong>email</strong> - User email address.</li>
                    <li><strong>phone</strong> - User phone number.</li>
                    <li><strong>zip</strong> - Use lowercase with no spaces and no dash. Use only the first 5 digits for
                        U.S. zip codes. Use the area, district, and sector format for the UK.
                    </li>
                    <li><strong>fbLoginId</strong> - The ID issued by Meta when a person first logs into an instance of
                        an app. This is also known as App-Scoped ID.
                    </li>
                    <li><strong>fbp</strong> - The Facebook browser ID value is stored in the _fbp browser cookie under
                        your domain. Example value: fb.1.1596403881668.1116446470
                    </li>
                </ul>

                <Alert severity="info" variant="filled" color="info" sx={{mb: 1}}>
                    <p>Example postback URL with additional parameters:</p>
                    {`https://skrotrack.com/postback?clickId=ancdh2udspoc73cjodf0&payout=10&firstName=John&lastName=Toe&email=johntoe@example.com&zip=12345&fbLoginId=1.1.mylogin&fbp=fb.1.1596403881668.1116446470`}
                </Alert>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default FacebookAdditionalConversionAPIParameters;

