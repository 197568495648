import React from "react";
import {Alert, Box, Link} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocRoot} from "../Docs";
import {Link as RouterLink} from "react-router-dom";

function WebsitesDeploymentSetup() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://aws.amazon.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/aws.svg" alt="AWS logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="Website Deployment Setup"/>
            <Box>
                <h2 style={{paddingTop: "24px"}}>Step 1: Create bucket and upload website</h2>
                <DocDivider/>
                <p>
                    1. Go to the <strong>Websites</strong> -> <strong>S3 Buckets</strong> and click
                    on <strong>Create</strong> button.
                </p>
                <DocImage src="/images/docs/web-bucket-1.png" alt="website bucket 1"/>
                <p>
                    2. Fill the form.
                </p>
                <DocImage src="/images/docs/web-bucket-2.png" alt="website bucket 2"/>
                <ul>
                    <li><strong>Workspace</strong> - Select your workspace.</li>
                    <li><strong>Integration</strong> - Select your AWS integration.</li>
                    <li>
                        <strong>S3 Bucket Region</strong> - Select the location where you want to upload your
                        website. It does not affect your website distribution.
                    </li>
                    <li>
                        <strong>Website Name</strong> - Type your website name.
                    </li>
                    <li>
                        <strong>Select ZIP File</strong> - Your website must be compressed
                        into <strong>ZIP</strong> file to upload it to Skro. ZIP file can contain the following files.
                        <ul>
                            <li>index.html - <strong>Mandatory file</strong></li>
                            <li>error.html - <strong>(Optional)</strong></li>
                            <li>
                                Image files can be <strong>.jpg</strong> <strong>.jpeg</strong> <strong>.png</strong>
                                <strong>.ico</strong>
                            </li>
                            <li>Javascript files <strong>.js</strong></li>
                        </ul>
                    </li>
                </ul>

                <p>This is an example of website file structure:</p>
                <DocImage sx={{maxWidth: 300}} src="/images/docs/web-bucket-3.png" alt="website bucket 3"/>

                <p>
                    3. Click on <strong>Create & Upload</strong> button.
                </p>
                <p>
                    4. Copy the <strong>S3 Origin URL</strong> to test, if you can see your website.
                </p>
                <DocImage src="/images/docs/web-bucket-4.png" alt="website bucket 4"/>

                <h2 style={{paddingTop: "24px"}}>Step 2: Deploy website</h2>
                <DocDivider/>
                <p>
                    1. Select your website and click on <strong>Deploy</strong> button.
                </p>
                <DocImage src="/images/docs/web-deploy-1.png" alt="website deploy 1"/>
                <p>
                    2. Select your domain and click on <strong>Deploy</strong> button. Then we will deploy your website
                    to all available regions through AWS Cloudfront.
                </p>
                <DocImage src="/images/docs/web-deploy-2.png" alt="website deploy 2"/>
                <p>
                    2.1. To automatically distribute your website on your chosen domain, you'll need to select your
                    domain provider integration. This will enable Skro to create a CNAME record for you, simplifying the
                    process.
                </p>

                <p>
                    Automated verification are supported
                    with <strong>AWS (Route53)</strong>, <strong>Cloudflare</strong> and <strong>Namesilo</strong>.
                </p>
                <p>
                    - <Link
                    component={RouterLink}
                    to={"/docs/deploy-website/cloudflare-integration"}
                >
                    Cloudflare integration
                </Link>
                </p>
                <p>
                    - <Link
                    component={RouterLink}
                    to={"/docs/deploy-website/namesilo-integration"}
                >
                    Namesilo integration
                </Link>
                </p>

                <DocImage src="/images/docs/web-deploy-2-1.png" alt="website deploy 2"/>
                <p>
                    3. Now, we redirect your to <strong>Deployments</strong> view and you will see the deployment info.
                </p>
                <DocImage src="/images/docs/web-deploy-4.png" alt="website deploy 4"/>
                <p>
                    4. Select your <strong>Deployment</strong> and click on <strong>Check Status</strong> button to get
                    your distributed website domain.
                </p>
                <DocImage src="/images/docs/web-deploy-3.png" alt="website deploy 3"/>

                <h2 style={{paddingTop: "24px"}}>Step 3: Set CNAME record to your domain</h2>
                <DocDivider/>

                <p>
                    1. Go back to <strong>Namecheap</strong>
                </p>
                <p>
                    2. Create a new <strong>CNAME record</strong> to your domain which points to
                    the <strong>Deployments Domain</strong>, which in
                    our example is <strong>d1qbtel1ndj434.cloudfront.net</strong>
                </p>
                <ul>
                    <li>Host: <strong>cloud</strong></li>
                    <li>Value: <strong>d1qbtel1ndj434.cloudfront.net</strong></li>
                </ul>
                <DocImage src="/images/docs/web-deploy-5.png" alt="website deploy 5"/>

                <Alert variant="filled" color="warning" severity="warning">
                    Typically, DNS changes take 5-15 minutes to propagate, but in some cases, they may take up to 48
                    hours.
                </Alert>
                <p>
                    3. Your site is now accessible via HTTPS using your custom domain.
                </p>

                <h2 style={{paddingTop: "24px"}}>How to update my website?</h2>
                <DocDivider/>
                <p>
                    1. If you want to upload a new version of your website, then simply go
                    to <strong>Websites</strong> -> <strong>S3 Buckets</strong> and select your website and click
                    on <strong>Upload</strong> icon.
                </p>
                <p>
                    2. Select a new version of your website (ZIP file) and click
                    on <strong>Upload</strong> button.
                </p>
                <DocImage src="/images/docs/web-bucket-5.png" alt="website bucket 5"/>


                <h2 style={{paddingTop: "24px"}}>How to delete my website?</h2>
                <DocDivider/>
                <p>
                    If you want to delete your website, then first you will need to
                    delete <strong>Deployments</strong> for your website and after that you will be able to delete
                    a website.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default WebsitesDeploymentSetup;
