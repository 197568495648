import React from "react";
import {Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function MaxWebIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://maxweb.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/maxweb.png" alt="MaxWeb Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate MaxWeb with Skro?"/>
            <Box>
                <p>
                    <a href="https://maxweb.com" target="_blank" rel="noreferrer">
                        MaxWeb
                    </a> is an affiliate network that operates in several verticals, such as nutra, ecommerce,
                    and survival. The following article describes how to set up tracking of their offers with Skro.
                </p>
                <p>
                    Integration gives you the ability to track sales and send postback to your traffic source.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>1. Add affiliate network to Skro</h2>
                <ul>
                    <li>Go to Affiliate Networks and click on <strong></strong>Create button.</li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-1.jpg" alt="Add affiliate network"/>

                <ul>
                    <li>Search MaxWeb and click on MaxWeb logo.</li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-2.jpg" alt="Choose MaxWeb prefilled template"/>

                <ul>
                    <li>Copy postback pixel.</li>
                    <li>Click <strong>Save & Close</strong>.</li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-3.jpg" alt="Save MaxWeb as affiliate network"/>

                <p>
                    You have now the MaxWeb affiliate network added to Skro and a configured postback URL copied in the
                    clipboard. Go to MaxWeb platform for the next step.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>2. Getting an offer from MaxWeb and setting up a postback URL.</h2>
                <ul>
                    <li>In MaxWeb, go to the <strong>Campaigns</strong> view.</li>
                    <li>
                        Locate an offer you want to promote. Note that you have to be approved before you will be able
                        to run some offers. Those offers will have the <strong>Apply to run</strong> button visible.
                        Offers that do not require approval will have the <strong>Promote</strong> button visible.
                    </li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-4.jpg" alt="View MaxWeb campaigns"/>

                <ul>
                    <li>Click to enter your account.</li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-5.jpg" alt="View MaxWeb campaigns"/>

                <ul>
                    <li>Go to the <strong>Settings</strong> view.</li>
                    <li>Go to the <strong>Postback Pixel</strong> tab.</li>
                    <li>Click the <strong>Add New</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-6.jpg" alt="View MaxWeb campaigns"/>

                <ul>
                    <li>
                        Paste the postback URL that you have copied from Skro in the <strong>URL</strong> text field.
                    </li>
                    <li>
                        Click the <strong>Save</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-8.jpg" alt="Add maxWeb postback pixel"/>

                <ul>
                    <li>Go to the <strong>Dashboard</strong> view.</li>
                    <li>Copy the offer URL to the clipboard.</li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-7.jpg" alt="MaxWeb dashboard"/>


                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>3. Add offer to Skro</h2>
                <ul>
                    <li>
                        Go to <strong>Offers</strong> -> <strong>Create</strong>
                    </li>
                    <li>Choose your workspace.</li>
                    <li>Add name.</li>
                    <li>
                        Grab the offer link from MaxWeb and add it to offer URL field and
                        append <strong>{`subid2={clickId}`}</strong> to the end of your offer URL.
                    </li>
                    <li>Choose MaxWeb as Affiliate Network.</li>
                </ul>
                <DocImage src="/images/docs/maxweb-int-9.jpg" alt="Add MaxWeb offer"/>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>4. Create campaign</h2>
                <ul>
                    <li>Go to Campaigns -> Create.</li>
                    <li>Fill all the fields where needed.</li>
                    <li>On the final step you will get tracking link for your traffic source.</li>
                </ul>

            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default MaxWebIntegration;
