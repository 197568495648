import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Typography, Box} from "@mui/material";
import {DocRoot} from "../docs/Docs";

function CookiePolicy(props) {
    const {selectEndUserPrivacyPolicy} = props;

    useEffect(() => {
        selectEndUserPrivacyPolicy();
    }, [selectEndUserPrivacyPolicy]);

    return (
        <DocRoot>
            <Box pt={10}>
                <Typography variant="h3" align="center">
                    Cookie Policy
                </Typography>
                <Typography variant="h6" align="center">
                    Last updated: April 21, 2021
                </Typography>
                <Box p={2}>
                    <p>
                        The English language version of this End User Privacy Policy shall be controlling in all
                        respects and shall prevail in case of any inconsistencies with translated versions, if any.
                    </p>
                    <p>
                        This notice is made by Skro OÜ, trading as Skro (hereinafter referred to as "Skro", "we", "our"
                        or “us”) which is firmly committed to protecting the privacy of its users, ensuring the
                        transparency and control over the personal data, strengthen the capacity via supporting them in
                        experience of GDPR compliance and provides this Privacy Policy to inform users about procedures
                        regarding the collection, use and disclosure of personal information from our web site located
                        at https://www.skro.eu/ (collectively, “Site”), information received due to using our product
                        and all other services and applications available on our site (collectively, “Subscription
                        Services”, “Services”) and visitors to websites of our users (collectively, "Customer Sites").
                    </p>
                    <p>
                        For any reason the Policy might be updated from time to time and it is required to consult the
                        Policy regularly for any changes.
                    </p>

                    <Typography variant={"h4"} sx={{paddingTop: 3, fontSize: 24}}>1 SECTION - WHAT IS SKRO</Typography>
                    <p>
                        Skro is a cloud-hosted analytics solution, designed for performance marketers and self-serve
                        advertisers to track the progress and profitability of their online campaigns. In other words,
                        the Skro platform enables our customers to make their online campaigns more efficient and
                        profitable by analysing the ad-related data and then, optimising the campaign by addressing them
                        in the most effective way. Thus, our customers collect different kinds of data about visitors
                        activities on the Internet to reach the right audience and increase the performance of their
                        online campaigns.
                    </p>

                    <Typography variant={"h4"} sx={{paddingTop: 3, fontSize: 24}}>
                        2 SECTION - RESPONSIBILITY BETWEEN DATA CONTROLLERS AND DATA PROCESSORS
                    </Typography>
                    <p>
                        2.1 In terms of GDPR compliance it is important to identify the roles of data controllers and
                        processors.
                    </p>
                    <p>
                        2.2 A controller term refers to the natural or legal person, public authority, agency or other
                        body which, alone or jointly with others, determines the purposes and means of the personal data
                        processing.
                    </p>
                    <p>
                        2.3 A processor term refers to a natural or legal person, public authority, agency or other body
                        which processes personal data on the part of the controller.
                    </p>
                    <p>
                        2.4 While our customers are using Skro we act as a customer’s data processor.
                    </p>
                    <p>
                        2.5 When we process a user's data, Skro is identified as a data controller.
                    </p>
                    <p>
                        2.6 Skro users are data controllers in respect to their user data.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        3 SECTION - WHAT DATA WE COLLECT AND FOR WHAT PURPOSES
                    </Typography>
                    <p>
                        <b>IP Address</b>
                    </p>
                    <p>
                        An IP address is used to identify the device’s location as well as, to some extent, user’s
                        location. Based on the IP address visitor’s country, region, city can be characterized and
                        stored in the Skro platform. Moreover, some more technical specifications are processed such as
                        Internet Service Provider (ISP) or mobile carrier and what type of the connection you use.
                    </p>
                    <p>
                        <b>User Agent</b>
                    </p>
                    <p>
                        A user agent helps us to identify what kind of a device a visitor uses (TV, desktop, table,
                        mobile phone) and which model it is. Even more, this piece of information is stored to establish
                        device’s parameters such as browser and browser version, operating system, and operating system
                        version. It also allows us to detect the automatic computer programs and fraud attempts.
                    </p>
                    <p>
                        <b>HTTP Request Header</b>
                    </p>
                    <p>
                        Information from HTTP request headers is used to determine a visitor’s language and referrer
                        domain. That data is then used to present an appropriate advertisement to the End user. It is
                        also stored for analytical purposes.
                    </p>
                    <p>
                        <b>HTTP Request Parameters</b>
                    </p>
                    <p>
                        The HTTP request parameters are used to transfer information from third-party services to the
                        Skro platform and the other way round. Based on that, the Skro customers are able to find target
                        audiences more effectively and message through appropriate channels. It is also stored for
                        analytical purposes.
                    </p>
                    <p>
                        <b>Request Time</b>
                    </p>
                    <p>
                        This is an exact date and time of interaction with Skro servers. It is used to present an
                        appropriate advertisement to the End user. It is also stored for analytical purposes.
                    </p>
                    <p>
                        <b>Unique Identifier (UID)</b>
                    </p>
                    <p>
                        A unique identifier generated by the platform enables us to match registered events and control
                        the frequency of those events.
                    </p>
                    <p>
                        Note that our publisher partners may share with us additional demographic information, such as
                        age or gender, in order to enable more accurate targeting. We do not use this information to
                        maintain any kind of persistent user’s profile database.
                    </p>
                    <p></p>
                    <p>
                        3.1 All the specified data details are collected and stored for analytical purposes only.
                    </p>
                    <p>
                        3.2 This information is not used in any other purpose except analytical.
                    </p>
                    <p>
                        3.3 We also do not collect any “sensitive” or “special categories of personal data” as defined
                        under the European data protection laws as well as personal data of children.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>4 SECTION - HOW WE COLLECT
                        DATA</Typography>
                    <p>
                        4.1 To collect data we use different types of cookies and a dedicated tracking pixel.
                    </p>
                    <p>
                        4.2 Landing page cookie allows to pass the data associated with visit of the ad campaign.
                        Uniqueness cookie is required to identify if the visitor is new for a particular ad campaign.
                    </p>
                    <p>
                        4.3 Click cookie to collect the data on clicks from landing page to the offer.
                    </p>
                    <p>
                        4.4 Tracking pixel is a pixel tag, which should be placed on a user’s website in corresponding
                        cases. The pixel is used to track the end-user activity on the website and this method is partly
                        based on cookies.
                    </p>


                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        5 SECTION - HOW WE USE COLLECTED DATA
                    </Typography>
                    <p>
                        5.1 The end-user data is used for analytical, reporting, optimization purposes only in terms of
                        running an ad campaign. The collected data helps our customers to improve the performance of
                        their online campaigns, identify the end-user interests and create further campaigns according
                        to the collected data.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        6 SECTION - LEGAL BASIS FOR PROCESSING END-USER INFORMATION
                    </Typography>
                    <p>
                        6.1 This paragraph refers to European Union End Users or to the cases when GDPR applies to under
                        the Applicable Law.
                    </p>
                    <p>
                        6.2 Our legal basis for collecting and using the End User information is based on your personal
                        consent when personal data is processed to collect and deliver the data and cookie technology
                        application.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        7 SECTION - HOW LONG THE DATA IS STORED
                    </Typography>
                    <p>
                        7.1 The collected data for reporting, analysis and optimization purposes is stored for 24 months
                        for the day of account registration.
                    </p>
                    <p>
                        7.2 The process of removing the collected data might take up to 30 days.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>8 SECTION - THE OPT-OUT
                        OPTION</Typography>
                    <p>
                        8.1 To Opt-Out for tracking on your mobile or desktop device it is required to change your
                        privacy preferences in the browser settings by blocking third-party cookies.
                    </p>
                    <p>
                        8.2 The configuration process depends on the browser and device type you use.
                    </p>


                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>
                        9 SECTION - THIRD-PARTY SERVICES
                    </Typography>
                    <p>
                        9.1 This Policy refers to the privacy practises of our service. We do not control the privacy
                        experiences of third parties, and we highly recommend to get familiar with the third-party
                        policies of other services you might be contacting while using our tracking solution.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>10 SECTION - SECURITY</Typography>
                    <p>
                        To protect your personal information we are using the following security procedures:
                    </p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>
                            the access to personal information is available to the qualified and authorized employees;
                        </li>
                        <li>
                            all the payment information is passed through the Secure Socket Layer (SSL) technology and
                            then, encrypted into our payment gateway providers database and it is not stored on our
                            servers;
                        </li>
                        <li>
                            we only rely on the trusted vendors of industry to assure the best security and protection
                            of your personal data.
                        </li>
                    </ol>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>11 SECTION - CHILDREN
                        POLICY</Typography>
                    <p>
                        11.1 Our platform can not be used by a person under 18 age. The platform is not targeted at
                        children.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>12 SECTION - CONTACT
                        INFORMATION</Typography>
                    <p>Skro OÜ</p>
                    <p>Registry code: 16173240</p>
                    <p>Address: Muuga põik 6, Laiaküla, Viimsi, Harju maakond 74008 Estonia</p>
                    <p>Customer support: support@skro.eu</p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>13 SECTION - TIME THE TERMS ENTER INTO
                        EFFECT</Typography>
                    <p>
                        These Terms enter into effect on April 21th, 2021, at 00.00 Eastern European Time (EET) and
                        apply to all actions performed on our Site, Service and within the Application since then.
                    </p>
                </Box>
            </Box>
        </DocRoot>
    );
}

CookiePolicy.propTypes = {
    selectEndUserPrivacyPolicy: PropTypes.func.isRequired
};

export default CookiePolicy;
