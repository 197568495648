import React from "react";
import {Grid, Typography} from "@mui/material";
import {
    Security,
    LocalMall,
    EmojiEvents,
    Group,
    Assessment,
    Directions,
    Speed,
    Dns,
    AttachMoney,
    CallSplit,
    Visibility,
    VisibilityOff,
    BugReport,
    ListAlt,
    Money,
    PostAdd,
    Facebook,
} from '@mui/icons-material';
import FeatureCard from "./FeatureCard";
import {ContainerFluid, ContainerFluidLgPaddingTop, LgPaddingTop} from "./Home";

const iconSize = 36;
const primary = "rgba(68, 199, 103, 1)";

const features = [
    {
        color: primary,
        headline: "Real-Time Stats",
        text: "Easily track and monitor real-time metrics of your ad campaign, including visits, clicks, cost, revenue, profit etc. Simplify affiliate conversion tracking with a single all-in-one platform.",
        icon: <Speed sx={{fontSize: iconSize, primary}}/>,
        mdDelay: "0",
        smDelay: "0"
    },
    {
        color: primary,
        headline: "Anti-Fraud Protection",
        text: "Monetizing bot traffic is not possible, and furthermore, bots negatively impact campaign performance. Ensure high-quality human traffic by blocking bots.",
        icon: <BugReport sx={{fontSize: iconSize}}/>,
        mdDelay: "0",
        smDelay: "0"
    },
    {
        color: primary,
        headline: "Cookieless Tracking",
        text: "Use the power of direct tracking with tracking pixel to obtain precise traffic data for Meta Ads, Google Ads, and Microsoft Ads.",
        icon: <VisibilityOff sx={{fontSize: iconSize}}/>,
        mdDelay: "0",
        smDelay: "0"
    },
    {
        color: primary,
        headline: "Rule-Based Paths",
        text: "Supercharge your campaign funnel by extending it and creating rule-based paths to your visitors. Unlock the potential of multiple landing pages and offers to skyrocket your conversions.",
        icon: <CallSplit sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Workspaces & Multi-User Access",
        text: "Efficiently coordinate tasks within your team by assigning distinct roles to each member. Establish separate workspaces for various campaigns and elements, empowering team members with delegated management responsibilities. This strategic approach enhances overall organization and streamlines workflow.",
        icon: <Group sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Pre-Filled Templates",
        text: "Access a comprehensive collection of 230+ pre-filled templates designed for Affiliate Networks, along with an additional 85+ templates for various Traffic Sources. Streamline your processes effortlessly with this extensive selection, ensuring a seamless and efficient workflow for your affiliate marketing endeavors.",
        icon: <PostAdd sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Custom Domains With SSL",
        text: "Secure your links and domains with complimentary SSL (Secure Sockets Layer) protection. This ensures that your campaigns remain trustworthy and avoid being flagged as suspicious by popular browsers such as Firefox, Chrome, Safari, and others.",
        icon: <Dns sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Multi-Currency",
        text: "Explore your aggregated reports with flexibility by accessing them in 169 diverse currencies. Customize your account's main currency to either EUR or USD, providing you with a versatile and user-friendly experience that aligns with your preferences.",
        icon: <Money sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Multi-Dimensional Reporting",
        text: "Unlock the power of effective optimization by easily accessing the information you need. Leverage user-based columns to gain insightful perspectives into your ad campaigns. Enhance your analysis with 34 diverse data metrics and organize your data into three levels of grouping. Additionally, take advantage of the convenience of downloading data in CSV format, providing you with a seamless and comprehensive reporting experience.",
        icon: <ListAlt sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Landing Page Protection",
        text: "Safeguard your landing pages against direct visits by implementing protection measures that analyze the time lapse between redirecting a user to the landing page and their actual visit to it.",
        icon: <Security sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Multi-Cost and Payout Tracking",
        text: "Understanding your earnings is a fundamental aspect of utilizing a tracker. With Skro, you have the flexibility to set your preferred cost model (Auto, CPA, CPC, or RevShare) and opt for your preferred payout type (auto or manual). This enables you to access detailed and comprehensive data on profit-based metrics, empowering you with the insights needed to make informed decisions about your performance.",
        icon: <Assessment sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Redirect Methods",
        text: "Maximize your marketing potential with our advanced redirection tools! Effortlessly guide your visitors to your offers using various methods such as HTTP 302, meta refresh, or double meta refresh. Elevate your strategy further by incorporating a cloaking domain to discreetly conceal your traffic source and pre-lander from your affiliate network. Unlock the full potential of your campaigns with our cutting-edge solutions.",
        icon: <Directions sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Clicks & Conversions Report",
        text: "Dive deep into the analytics of your clicks and conversions with Skro! Gain comprehensive insights into every single interaction and effortlessly identify any data mismatches between your affiliate network and our platform. Stay in control, spot discrepancies with ease, and ensure seamless synchronization of your data.",
        icon: <Visibility sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Refer & Earn",
        text: "Unlock your earnings with the Skro affiliate program! Join for free and set up with ease – all you need to do is share your referral link. Connect your friends to a trusted tool and enjoy a 10% lifetime commission on every purchase they make. It's the simplest way to earn money. Join now and start turning your connections into cash!",
        icon: <AttachMoney sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Custom Conversion Events",
        text: "Empower your tracking capabilities with custom conversion events on Skro! Monitor a diverse range of up to 20 events, providing you with a comprehensive overview. Whether it's deposits, app installs, purchases, instant checkouts, or any other event you wish to track, Skro ensures flexibility and precision in capturing the data that matters most to you.",
        icon: <EmojiEvents sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "ClickBank Integration",
        text: "Integrate ClickBank with Skro by transmitting conversion data through the ClickBank instant notification URL. Use the power of custom conversion events to track instant checkout events using the Skro tracking pixel. Elevate your analytics and gain real-time insights into your ClickBank transactions with precision and ease.",
        icon: <span style={{fontSize: 28, letterSpacing: 2, fontWeight: 600, marginBottom: -4}}>CB</span>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Traffic Source Integrations",
        text: "Leverage the Conversion API integration to seamlessly transmit purchase events back to the traffic source, fueling their AI capabilities to optimize your ad performance. Enjoy the convenience of automatic ad spend data synchronization every 30 minutes. Optimize campaigns, creatives, sources and sites through the Skro UI.",
        icon: <Facebook sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Shopify Integration",
        text: "Say goodbye to hours of manual effort in connecting advertising costs across various channels to Shopify sales and revenue. With our consolidated dashboard, you can effortlessly make campaign and budget decisions. Track your Shopify sales and optimize your ads all in one place.",
        icon: <LocalMall sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Deploy Websites on AWS",
        text: "Our platform takes the complexity out of managing domains, SSL certificates, and website deployments. From automatic SSL generation and S3 bucket setup to one-click deployments, we provide everything you need to get your site live and secure.",
        icon: <LocalMall sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
];

function FeatureSection() {
    return (
        <LgPaddingTop sx={{backgroundColor: "common.white"}}>
            <ContainerFluidLgPaddingTop>
                <Typography variant="h3" align="center" sx={{paddingBottom: 3}}>
                    Features
                </Typography>
                <Typography align="center" sx={{pt: 3, pb: 8, color: "#555", fontSize: 24}}>
                    Our features will change the way you do <span style={{fontWeight: 600, color: primary}}>affiliate marketing</span>
                </Typography>
                <ContainerFluid>
                    <Grid container spacing={6}>
                        {features.map(element => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                xl={4}
                                data-aos="zoom-in-up"
                                data-aos-delay={element.mdDelay}
                                key={element.headline}
                                sx={{pt: 4}}
                            >
                                <FeatureCard
                                    Icon={element.icon}
                                    color={element.color}
                                    headline={element.headline}
                                    text={element.text}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </ContainerFluid>
            </ContainerFluidLgPaddingTop>
        </LgPaddingTop>
    );
}

export default FeatureSection;
