import React from "react";
import {Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function HowToIntegrateFacebookCAPI() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", pb: "12px"}}>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/meta.svg" alt="Facebook (Meta) Ads Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate and set up Facebook (Meta) Conversion API?"/>
            <Box>
                <h2 id="conversion-api">I. Setup Conversion API</h2>
                <p>
                    If you want to send conversion info back to Facebook (Meta) Ads, then you will need to
                    enable <strong>Conversion API.</strong>
                </p>

                <ul>
                    <li>Facebook Ad Account integration is required.</li>
                    <li>
                        Navigate to the <strong>integrations</strong> view and edit your current Facebook (Meta)
                        integration.
                    </li>
                    <li>Modal will pop up and go to the second step and enable Conversion API.</li>
                </ul>

                <p><strong>Facebook Conversion Events</strong></p>
                <ol>
                    <li>
                        <strong>Conversion Event Name</strong> - Define all your events that your want to track
                        with CAPI (Lead, Purchase, Subscribe).
                        When creating a campaign in Skro, then you will need to specify which conversion event you
                        want to track. (If you want to track multiple events, then you will need to use our
                        Custom Conversions. For example: AddToCart, ViewContent etc.).
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to Facebook.
                    </li>
                </ol>

                <p><strong>Facebook Pixels</strong></p>
                <ol>
                    <li><strong>Pixel Name</strong> - Just give a name to your pixel.</li>
                    <li>
                        <strong>Pixel ID</strong> - Your Facebook pixel ID what you are going to use when creating a
                        campaign in Facebook and Skro.
                    </li>
                    <li>
                        <strong>Conversion API Access Token</strong> - Go to Facebook Ads Manager and click
                        on <strong>All Tools</strong> -> <strong>Events Manager</strong> -> Select your pixel and go
                        to <strong>Settings</strong>. Scroll down and you will see Conversion API section. Click
                        on <strong>Generate Access Token</strong>.
                    </li>
                    <li>Copy and paste that token in to Skro.</li>
                </ol>

                <p>Click <strong>Click & Save</strong> button.</p>
                <DocImage src="/images/docs/meta-integration-11.png" alt="Meta Integration step 11"
                />
                <p>
                    Your Conversion API set up is done. You can now choose your Facebook event and pixel when creating
                    a campaign in Skro.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default HowToIntegrateFacebookCAPI;
