import React from "react";
import {Box} from '@mui/material';

function FindUsSection() {
    return (
        <Box sx={{backgroundColor: "common.white", paddingTop: "90px"}}>
            <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" justifyContent="center"
                 sx={{backgroundColor: "#f9f9f9"}}>
                <Box p={4}>
                    <span style={{fontWeight: 600, fontSize: 16}}>Find us on</span>
                </Box>
                <Box ml={3} mt={1}>
                    <a href="https://afflift.com/?utm_source=skro.eu&utm_medium=find_us_logo&utm_campaign=trackers&utm_content=en">
                        <img src="images/find-us/afflift.svg" alt="AffLift" width="89"/>
                    </a>
                </Box>
                <Box ml={3} mt={1} p={1}>
                    <a href="https://affiliatefix.com/?utm_source=skro.eu&utm_medium=find_us_logo&utm_campaign=trackers&utm_content=en">
                        <img src="images/find-us/affiliatefix.svg" alt="AffiliateFix" width="127"/>
                    </a>
                </Box>
                <Box ml={3} mt={1} p={1}>
                    <a href="https://stmforum.com/?utm_source=skro.eu&utm_medium=find_us_logo&utm_campaign=trackers&utm_content=en">
                        <img src="images/find-us/stm.png" alt="stm" width="65"/>
                    </a>
                </Box>
            </Box>
        </Box>
    );
}

export default FindUsSection;
