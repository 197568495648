import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";

function FAQ(props) {
    const {selectFAQ} = props;

    useEffect(() => {
        selectFAQ();
    }, [selectFAQ]);

    return (
        <Fragment>
            <FrequentlyAskedQuestions/>
        </Fragment>
    );
}

FAQ.propTypes = {
    selectFAQ: PropTypes.func.isRequired
};

export default FAQ;
