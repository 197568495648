import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function BuyGoodsIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://buygoods.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/buygoods.png" alt="BuyGoods Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate BuyGoods with Skro?"/>
            <Box>
                <p>
                    <a href="https://buygoods.com" target="_blank" rel="noreferrer">
                        BuyGoods
                    </a> is a global Internet Retailer, offering safe and reliable online shopping experience focused on
                    customer's experience and service.
                </p>
                <p>
                    Integration gives you the ability to track sales, upsell and initiate checkout events
                    and send postback back to your traffic source.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>1. Add custom conversions to Skro</h2>
                <ul>
                    <li>Go to Settings -> Custom Conversions -> Create</li>
                </ul>
                <DocImage src="/images/docs/buygoods-int-1.jpg" alt="Add custom conversions"/>

                <ul>
                    <li>Create event named <strong>Initiate Checkout</strong></li>
                </ul>
                <DocImage src="/images/docs/buygoods-int-2.jpg" alt="Initiate Checkout event"/>

                <ul>
                    <li>Copy the Postback pixel for <strong>Initiate Checkout</strong>.</li>
                    <li>Log in to your BuyGoods account and go to Settings -> My Profile -> Funnel pixels.</li>
                    <li>Paste code to <strong>Checkout</strong> field.</li>
                </ul>

                <Alert severity="info" color="success" variant="filled">
                    {`<script async type="text/javascript"
                             src="https://skrotrack.com/postback?clickId={SUBID}&ce=InitiateCheckout"></script>`}
                </Alert>

                <Alert severity="warning" color="warning" variant="filled">If you are using your own custom domain, then please replace skrotrack.com</Alert>

                <DocImage src="/images/docs/buygoods-int-7.jpg" alt="Initiate Checkout event"/>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>2. Add BuyGoods affiliate network to Skro</h2>
                <ul>
                    <li>Go to Affiliate Networks -> Create -> Find BuyGoods logo and click on it.</li>
                    <li>Select workspace.</li>
                    <li>Copy postback URL.</li>
                    <li>Click <strong>Save & Close</strong></li>
                </ul>
                <DocImage src="/images/docs/buygoods-int-4.jpg" alt="Add affiliate network"/>

                <ul>
                    <li>Log in to Buygoods -> Settings -> Postback Pixels -> New</li>
                    <li>Paste postback URL to BuyGoods.</li>
                </ul>
                <Alert severity="info" color="success" variant="filled">
                    {`https://skrotrack.com/postback?clickId={SUBID}&payout={COMMISSION_AMOUNT}&transactionId={ORDERID}`}
                </Alert>
                <DocImage src="/images/docs/buygoods-int-6.jpg" alt="Add postback pixel"/>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>3. Add BuyGoods Offer to Skro</h2>
                <ul>
                    <li>
                        Go to <strong>Offers</strong> -> <strong>Create</strong>
                    </li>
                    <li>Choose your workspace.</li>
                    <li>Add name.</li>
                    <li>
                        Grab the offer link from BuyGoods and add it to offer URL field and
                        append <strong>{`&subid={clickId}`}</strong> to the end of your offer URL.
                    </li>
                    <li>Choose BuyGoods Affiliate Network.</li>
                </ul>
                <DocImage src="/images/docs/buygoods-int-5.jpg" alt="Add offer"/>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>4. Create campaign</h2>
                <ul>
                    <li>Go to Campaigns -> Create.</li>
                    <li>Fill all the fields where needed.</li>
                    <li>On the final step you will get tracking link for your traffic source.</li>
                </ul>

            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default BuyGoodsIntegration;
