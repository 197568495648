import React from "react";
import {Box, Divider, Alert} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function TaboolaIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://taboola.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/taboola.svg" alt="Taboola Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Taboola with Skro?"/>
            <Box>
                <p>
                    <a href="https://taboola.com" target="_blank" rel="noreferrer">
                        Taboola
                    </a> integration gives you the ability to manage and optimize all your Taboola ad campaigns through
                    the Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, ads and sites every 30 min.</li>
                    <li>Check the status of your campaigns, ads and sites.</li>
                    <li>Pause or resume campaigns, ads and sites.</li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in Taboola and Skro so Taboola
                    can
                    pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in Taboola and Skro is required.
                </p>
                <DocImage src="/images/docs/google-manage-campaigns.png" alt="Manager Taboola campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Ask Taboola Credentials</h2>
                <p>
                    Contact your Account Manager or Taboola support, saying you want to integrate with their backstage
                    API, ask them provide <strong>Client ID</strong> and <strong>Client Secret</strong> of your account.
                    You will need it when setup Taboola integration in Skro.
                </p>
                <h2>II. Create a Conversion</h2>
                <p>
                    A conversion event name is needed for Skro to send conversions to Taboola. We should get a
                    conversion event name in this section.
                </p>
                <ul>
                    <li>
                        Sign in to your Taboola account.
                    </li>
                </ul>
                <DocImage src="/images/docs/taboola-1.png" alt="Manager Taboola campaigns"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Tracking</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/taboola-2.png" alt="Manager Taboola campaigns"
                />
                <ul>
                    <li>Click <strong>+ New Conversion</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/taboola-3.png" alt="Manager Taboola campaigns"
                />
                <ul>
                    <li>
                        Enter your conversion name.
                    </li>
                    <li>Set conversion type <strong>Event</strong>.</li>
                    <li>
                        Select your conversion category.
                    </li>
                    <li>
                        Type your <strong>Conversion Event Name</strong>. (You will need that name when creating an
                        integration in Skro.)
                    </li>
                    <li>Click on <strong>Create</strong> button.</li>
                </ul>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/taboola-integration-1.png" alt="Taboola Integration step 1"
                />
                <ul>
                    <li>Click on <strong>Taboola</strong> logo and integration form will pop up.</li>
                </ul>
                <DocImage src="/images/docs/taboola-integration-2.png" alt="Taboola Integration step 2"
                />
                <ul>
                    <li>
                        Enter your <strong>Integration Name</strong>.
                    </li>
                    <li>
                        Enter your Taboola <strong>Client ID</strong>. Get this value from your account manager or
                        taboola
                        support.
                    </li>
                    <li>
                        Enter your Taboola <strong>Client Secret</strong>. Get this value from your account manager or
                        taboola
                        support.
                    </li>
                    <li>Click <strong>Next</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/taboola-integration-3.png" alt="Taboola Integration step 4"
                />
                <ul>
                    <li>
                        If you want to send conversions info back to Taboola, then you will need to
                        enable <strong>Conversion API.</strong>
                    </li>
                    <li>
                        <strong>Conversion Event Name</strong> - must be the same as you typed in Taboola. When
                        creating Skro campaign you will need to specify which conversion goal to send to.
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to Taboola.
                    </li>
                    <li>
                        Click on <strong>Click & Save</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/taboola-integration-4.png" alt="Taboola Integration step 5"
                />
                <ul>
                    <li>You Taboola account is successfully integrated.</li>
                    <li>You can switch on/off cost sync with given switch button.</li>
                </ul>
                <DocImage src="/images/docs/taboola-integration-5.png" alt="Taboola Integration step 5"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on
                        the <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-1.jpg" alt="Setup Taboola traffic source Step 1"
                />
                <ul>
                    <li>Click on <strong>Taboola</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/taboola-integration-6.png" alt="Setup Taboola traffic source step 2"
                />
                <ul>
                    <li>Postback URL is already set with correct macros.</li>
                    <li>Select your Taboola integration from the dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/taboola-integration-7.png" alt="Setup Taboola traffic source step 4"
                />
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for Taboola.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your offer.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-offer-1.png" alt="Setup Skro Offer"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as Taboola.</li>
                    <li>
                        Select your <strong>Conversion Event Name</strong>. That is needed to send conversion info back
                        to Taboola.
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/taboola-campaign-1.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/taboola-campaign-2.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>
                        In given example we use only offers, Switch on <strong>Use only offers</strong> and select your
                        offer. With Taboola you are allowed to use <strong>Redirect</strong> tracking method, so you can
                        add multiple landing pages and offers to your campaign.
                    </li>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/taboola-campaign-3.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose the <b>Redirect</b> method.</li>
                    <li>
                        Copy and paste the Campaign Tracking URL to Taboola.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    In given example we have that kind of URL:<br/><br/>
                    {"https://skrotrack.com/campaign/f83916ca-a95b-4641-8d9b-4d252f82e64b?click_id={click_id}&campaign_id={campaign_id}&site_id={site_id}&ad_id={campaign_item_id}&site={site}&thumbnail={thumbnail}&title={title}&platform={platform}&campaign_name={campaign_name}&site_domain={site_domain}"}
                </Alert>
                <DocImage src="/images/docs/taboola-campaign-4.png" alt="Setup Skro Campaign"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>V. Create a Campaign in Taboola</h2>
                <p>
                    Now you have your campaign URL with tracking parameters added. In the next step you will submit it
                    as your item's URL in Taboola's campaign inventory setup. In order to do that, perform the following
                    steps:
                </p>
                <ul>
                    <li>Sign in to your Taboola account.</li>
                    <li>Go to the Campaigns tab.</li>
                    <li>
                        Click the <strong>Create</strong> button and select the Campaign option from the drop-down menu.
                    </li>
                </ul>
                <DocImage src="/images/docs/taboola-campaign-5.png" alt="Setup Taboola Campaign"
                />
                <p><strong>Campaign Settings</strong> - Provide all required information. This includes:</p>
                <ul>
                    <li>Campaign Setup.</li>
                    <li>Bidding And Conversion Goal.</li>
                    <li>Schedule.</li>
                    <li>Campaign Targeting</li>
                    <li>Audience Targeting.</li>
                    <li>Click <strong>Next</strong>.</li>
                </ul>
                <p><strong>Budget and Bidding</strong> - Provide all required information. This includes:</p>
                <ul>
                    <li>Budget</li>
                    <li>Tracking</li>
                    <li>Profile</li>
                </ul>
                <p>
                    You do not need to add anything in the Tracking codes section. All Taboola parameter are already
                    included in the campaign tracking URL that will be added on item level.
                </p>
                <p>
                    Once you have provided the required information, click the <strong>Create & Add Ads</strong> button.
                    The Create ads view will appear.
                </p>
                <p>
                    <strong>Create Ads</strong> - Provide a target URL and a creative. In order to do so, perform
                    the steps below:
                </p>
                <ul>
                    <li>
                        Paste the copied campaign tracking URL from Skro into the <strong>Landing page URL</strong> text
                        field.
                    </li>
                    <li>
                        Upload a creative or choose one from the stock photo library, provide headlines. Once you set up
                        your ad, click the <strong>Submit</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/taboola-campaign-6.png" alt="Setup Taboola Campaign"
                />

                <p>
                    Congratulations, you have successfully set up tracking of Taboola traffic with Skro. Each time a
                    visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default TaboolaIntegration;
