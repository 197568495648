import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Typography, Box} from "@mui/material";
import {DocRoot} from "../docs/Docs";

function TermsConditions(props) {
    const {selectTermsConditions} = props;

    useEffect(() => {
        selectTermsConditions();
    }, [selectTermsConditions]);

    return (
        <DocRoot>
            <Box pt={10}>
                <Typography variant="h3" align="center">
                    Terms & Conditions
                </Typography>
                <Typography variant="h6" align="center">
                    Last updated: April 21, 2021
                </Typography>
                <Box p={2}>
                    <p>
                        The English language version of this Terms and Conditions shall be controlling in all respects
                        and shall prevail in case of any inconsistencies with translated versions, if any.
                    </p>
                    <p>
                        These Terms of Service (the “Agreement”) is made in the form of a public offer by Skro OÜ,
                        trading as Skro (hereinafter referred to as “Skro”, “we”, “our” or “us”) and sets forth the
                        terms and conditions that apply to your access and use of the internet website located at
                        www.skro.eu (the “Site”) and the services available thereon, including without limitation the
                        services that enable you to create, test and host promotion specific custom landing pages for
                        online marketing purposes (the “Services”).
                    </p>
                    <p>
                        BY ACCEPTING THIS AGREEMENT, EITHER BY COMPLETING THE ONLINE APPLICATION PROCESS BY CLICKING A
                        BOX INDICATING YOUR ACCEPTANCE OR BY EXECUTING A SUBSCRIPTION THAT REFERENCES THIS AGREEMENT
                        (THE "SUBSCRIPTION"), YOU AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS
                        AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
                        AUTHORITY TO BIND SUCH ENTITY TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS "YOU" OR
                        "YOUR" SHALL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE
                        WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE
                        SERVICES.
                    </p>
                    <p>
                        By accepting this Agreement, you agree to be bound by the terms and conditions of this Agreement
                        and Skro’s Privacy Policy provided at https://www.skro.eu/privacy-policy (the "Privacy Policy"),
                        as it may be amended from time to time in the future.
                    </p>
                    <p>
                        Skro may update this Agreement or the Privacy Policy at any time, without notification to you,
                        and you should regularly review this Agreement and the Privacy Policy by accessing the Site.
                        Your continued use of the Site and/or the Services shall be deemed irrevocable acceptance of any
                        such revisions.
                    </p>

                    <Typography variant={"h4"} sx={{paddingTop: 3, fontSize: 24}}>1 SECTION - ABILITY TO ENTER INTO THIS
                        AGREEMENT</Typography>
                    <p>
                        1.1 In order to enter into this Agreement, you must have reached the legal age of majority in
                        your jurisdiction of residence or possess legal parental or guardian consent, and be fully able
                        and competent to enter into the terms, conditions, obligations, affirmations, representations
                        and warranties set forth in this Agreement, and to abide by and comply with this Agreement. It
                        is your responsibility to ensure that you are legally eligible to enter into this Agreement
                        under any laws applicable to you in your jurisdiction of residence or otherwise. If you accept
                        this Agreement, you represent that you have the right to be bound by it.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>2 SECTION - YOUR PROFILE INFORMATION AND
                        ACCOUNT</Typography>
                    <p>
                        2.1 You agree and understand that you are responsible for maintaining the confidentiality of
                        your email address which allows you to access the Services. That email address, together with
                        any or other user information you provide, form your "Profile Information" and allow you to
                        access your account ("Account"). You agree to provide true, accurate, current and complete
                        information about yourself, and you may not misrepresent your Profile Information.
                    </p>
                    <p>
                        2.2 Your right to access and use the Services is provided personally to you and is not
                        transferable by you to any other person or entity. You are solely responsible for all activities
                        that occur under your Account or under your Profile Information. If you become aware of any
                        unauthorized use of your Account or Profile Information, you are obliged to immediately notify
                        Skro.
                    </p>
                    <p>
                        2.3 By providing us with your email address, you agree to receive all required notices
                        electronically to that email address. It is your responsibility to update or change that
                        address, as appropriate.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>3 SECTION - FEES</Typography>
                    <p>
                        3.1 Upon the registration, you will be provided by an unlimited free period of using Services
                        (“Free Subscription Plan”). You agree to follow all terms and conditions, including limitations
                        on the data retention, estimated amount of events per month and charge for over limit events
                        related to the Free Subscription Plan. You shall not sign-up numerous (more than one) Accounts
                        in order to receive any additional benefits under Free Subscription Plan (such as limit increase
                        of the amount of traffic etc.).
                    </p>
                    <p>
                        3.2 Skro reserves the right to modify, suspend or discontinue terms and conditions of Free
                        Subscription Plan, or any portion thereof, with or without notice at any time and for any
                        reason, including, but not limited to, a failure to comply with the terms of this Agreement.
                        Skro shall have no liability whatsoever for any damages, liabilities, losses or any other
                        consequences that you may incur as a result of any such modification, suspension or
                        discontinuance.
                    </p>
                    <p>
                        3.3 To meet the requirements of your business purposes you have the ability to choose one of our
                        four (4) subscription plans. You agree to pay a bill for the period of using Service specified
                        in the Subscription plan.
                    </p>
                    <p>
                        3.4 The period of using Service may be of thirty (30) calendar days for the monthly Subscription
                        plan. The total amount of a monthly fee varies depending on:
                    </p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>
                            selected Subscription plan;
                        </li>
                        <li>
                            amount of traffic sent to the web pages hosted by you using the Service that exceeded the
                            traffic limit of your current Subscription plan;
                        </li>
                        <li>
                            any additional features, adjuncts or modifications of your Account, Service or Subscription
                            plan that you select.
                        </li>
                    </ol>

                    <p>3.5 Subscription plan fee shall be billed in advance for the period of using the Service.</p>
                    <p>
                        3.6 The advanced fee amount is specified in the Subscription plan and is non-refundable. We do
                        not refund and accept a payment for a partial month of Service or for periods in which your
                        Account remains open but you do not use the Services.
                    </p>
                    <p>
                        3.7 If the total amount of traffic delivered to your web pages, which you host by using the
                        Services, exceeds the limit specified in the Subscription plan, you will be additionally billed
                        for the extra amount of traffic after a 30-day period.
                    </p>
                    <p>
                        3.8 This bill will be available in the “Settings/Subscription/Invoices” section. You shall pay
                        the additional bill within ten (10) days from the moment of its issue. If you fail to pay during
                        this period, your Account will be blocked.
                    </p>
                    <p>
                        3.9 We reserve the right to modify our Subscription plans at any time by posting such fee
                        changes in the cost of Services on the admin panel or on the Site upon fifteen (15) days written
                        notice to users.
                    </p>
                    <p>
                        3.10 You may change your Subscription plan to the another with a lower pricing at any time. To
                        do this, please contact us to receive all necessary information. If you have any questions about
                        the downgrade of your current Subscription plan, please contact our Customer Support.
                    </p>
                    <p>
                        3.11 We operate an automatic renewal billing policy. This means that once the initial period of
                        your Subscription plan has expired, for your convenience, your Subscription plan will
                        automatically be renewed at the same rate that you signed up for using the original payment
                        method. For example, if you pay by credit card, we will continue to debit this same credit card
                        when each period of Subscription plan expires. By the end of the period of current Subscription
                        plan, on the condition that the repeat billing was made successfully and period of Subscription
                        plan is prolonged, you will receive the same set of features that corresponds to the selected
                        Subscription plan for the same period. In case the payment for the specified period fails, you
                        need to provide a new payment method.
                    </p>
                    <p>
                        3.12 If you make payment for the first time on our site, make sure you have carefully read the
                        Pricing page, because when you make payment, you confirm that you have fully acknowledged with
                        the content of our Service, its functional specificity, pricing plans, and have fully agreed to
                        the conditions of this Terms of Service and Privacy Policy. Please note that all payments made
                        by you are non-refundable.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>4 SECTION - SUBMISSION OF
                        CONTENT</Typography>
                    <p>
                        4.1 The Site and the Services available thereon enable you to provide or upload content,
                        including but not limited to text, images, video, data, and other information or content
                        (collectively, "Content"), to Skro for the purpose of providing the Services. You acknowledge
                        and agree that you are solely responsible for all Content you submit, provide or upload and the
                        consequences for submitting, providing or uploading it.
                    </p>
                    <p>
                        4.2 Skro will use Content you upload solely in connection with providing the Services to you,
                        and for no other reason. You agree that by uploading, or otherwise providing any Content on or
                        through the Site and/or the Services, you grant to Skro a perpetual, worldwide, non-exclusive,
                        royalty-free license to use, reproduce, process, display, all or any portion of such Content,
                        solely in connection with providing the Services to you. This license includes the right to
                        host, index, cache or otherwise format your Content in order to provide the Services.
                    </p>
                    <p>
                        4.3 You represent and warrant that you own your Content or have the necessary licenses, rights,
                        consents and permissions to grant the license set forth herein and that its provision to Skro or
                        Skro's use thereof will not violate the copyrights, privacy rights, publicity rights, trademark
                        rights, contract rights or any other intellectual property rights or other rights of any person
                        or entity.
                    </p>
                    <p>
                        4.4 Skro retains the right to use or distribute any Aggregated Data generated by our customers
                        or users. "Aggregated Data" means records which have been stripped of information potentially
                        identifying customers, landing pages or end-users, and which have been manipulated or combined
                        to provide generalized, anonymous information.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>5 SECTION - INTELLECTUAL PROPERTY
                        RIGHTS</Typography>
                    <p>
                        5.1 All ownership rights and intellectual property rights in connection with or relating to the
                        Skro trademarks and the Site, including but not limited to its "look and feel" (e.g., text,
                        graphics, images, logos, buttons, icons and the placement and layout thereof), images, video,
                        audio, data, content, software (including html-based computer programs) and other media,
                        material or information, other than your Content, are and will remain the property of Skro or
                        its software or content suppliers.
                    </p>
                    <p>
                        5.2 Subject to the terms and conditions of this Agreement, Skro grants to you, during the term
                        of this Agreement, a non-transferable, non-sub licensable, non-exclusive, revocable,
                        limited-purpose right to access and use the Services that are subject to a valid Subscription
                        plan.
                    </p>
                    <p>
                        5.3 You acknowledge that any ideas, suggestions, concepts, processes or techniques which you
                        provide to Skro related to the Services, the Site or Skro or its business ("Feedback") shall
                        become Skro’s property without any compensation or other consideration payable to you by Skro,
                        and you do so of your own free will and volition. Skro, in its sole discretion, may use or
                        incorporate the Feedback into the Site in whatever form or derivative we will decide, either it
                        can be software, services, documentation, business or other products, or any future versions or
                        derivatives of the foregoing. You hereby assign all rights on a worldwide basis in perpetuity to
                        Skro in any Feedback and, as applicable, waive any moral rights.
                    </p>
                    <p>
                        5.4 Skro owns any test results, data information and other output generated by your use of the
                        Site and/or the Services during the term of this Agreement. You shall not disclose to any third
                        party any test results or performance information regarding the Site or the Services, whether
                        generated by you, Skro or a third party, except where expressly permitted.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>6 SECTION - CONFIDENTIAL
                        INFORMATION</Typography>
                    <p>
                        6.1 You agree to safeguard, keep secret and not to disclose to any third party, any Confidential
                        Information acquired, learned or provided from Skro during the term of this Agreement or
                        following the expiration or termination of this Agreement. "Confidential Information" means any
                        information marked confidential or that ought reasonably to be considered confidential under the
                        circumstances and includes, without limitation, any business plans, customer lists, operation
                        procedures, trade secrets, design formulas and programming code, know-how and processes,
                        computer programs and inventions, discoveries, and improvements of any kinds.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>7 SECTION - ACCEPTABLE USE AND
                        CONDUCT</Typography>
                    <p>
                        By agreeing to the Terms of Service you hereby consent that you will not publish or make
                        available any Content that, or use the Site in a manner that:
                    </p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>
                            infringes, violates or misappropriate any third party's intellectual property or proprietary
                            rights;
                        </li>
                        <li>
                            contains software viruses, Trojan horses or any other computer code, files or programs
                            designed to interrupt, destroy or limit the functionality of any computer software or
                            hardware or telecommunications equipment;
                        </li>
                        <li>
                            except where expressly permitted, is engaged in spamming, "chain letters", "pyramid
                            schemes", advertisement of illegal or controlled products or services, or other advertising
                            or marketing activities that violate this Agreement, the Privacy Policy or any applicable
                            laws, regulations or generally-accepted advertising or marketing industry guidelines;
                        </li>
                        <li>
                            is misleading, deceptive or fraudulent or otherwise illegal or promotes illegal activities,
                            including engaging in phishing or otherwise obtaining financial or other personal
                            information in a misleading manner or for fraudulent or misleading purposes;
                        </li>
                        <li>
                            is libelous or defamatory, or that is otherwise threatening, abusive, violent, harassing,
                            malicious or harmful to any person or entity, or is invasive of another's privacy;
                            is harmful to minors in any way;
                        </li>
                        <li>
                            is hateful or discriminatory based on race, color, sex, religion, nationality, ethnic or
                            national origin, marital status, disability, sexual orientation or age or is otherwise
                            objectionable, as reasonably determined by Skro;
                        </li>
                        <li>
                            includes personal or identifying information about another person in a manner that employs
                            misleading email or IP addresses, or forged headers or otherwise manipulated identifiers in
                            order to disguise the origin of Content transmitted through the Site to users;
                        </li>
                        <li>
                            constitutes or contains any form of advertising or solicitation to users who have requested
                            not to be contacted about other services, products or commercial interests;
                        </li>
                        <li>
                            impersonate yourself as an Skro employee, or any other person, or falsely state or otherwise
                            misrepresent your affiliation with any person or entity, or to obtain access to the Site or
                            a portion thereof without proper authorization;
                        </li>
                        <li>
                            interfere or attempt to interfere with the proper working of the Site or prevent others from
                            using the Site, or in a manner that disrupts the normal flow of dialogue with an excessive
                            number of messages (flooding attack) to the Site, or that otherwise negatively affects other
                            persons' ability to use the Site, or inadvertently or intentionally disrupts, diminishes the
                            quality of, interferes with the performance of, or impairs the functionality of the Site or
                            any other site or system in use by another user of Skro;
                        </li>
                        <li>
                            use any manual or automated means, including agents, robots, scripts, or spiders, to monitor
                            or copy the Site or the content contained therein;
                        </li>
                        <li>
                            facilitate the unlawful distribution of copyrighted Content;
                        </li>
                        <li>
                            license, sublicense, rent or lease the Services to third parties, use the Services for third
                            party training, commercial time-sharing or service bureau use, or otherwise make the
                            Services available to third parties or otherwise commercially exploit the Services;
                        </li>
                        <li>
                            stalk or otherwise harass anyone on the Site by using an information obtained from the Site;
                        </li>
                        <li>
                            collect, use or disclose data, including personal information about users without their
                            informed consent or for unlawful purposes or in violation of applicable law or regulations;
                        </li>
                        <li>
                            request, solicit or otherwise obtain access to emails or other authentication
                            credentials from any user of the Site for the purposes of automating logins to the Site;
                        </li>
                        <li>
                            attempt to gain unauthorized access to the computer systems of Skro or engage in any
                            activity that disrupts, diminishes the quality of, interferes with the performance of, or
                            impairs the functionality of the Site;
                        </li>
                        <li>
                            post adult or pornographic Content;
                        </li>
                        <li>
                            decompile or reverse engineer or attempt to access the source code of the software
                            underlying the Site, the Services or any other Skro technology;
                        </li>
                        <li>
                            copy, archive, store, reproduce, rearrange, modify, download, upload, create derivative
                            works from, display, perform, publish, distribute, redistribute or disseminate all or any
                            part of the Site;
                        </li>
                        <li>
                            access the Site for the purposes of building a product using similar ideas, features,
                            functions, interface or graphics as those found on the Site;
                        </li>
                        <li>
                            access the Site for the purposes of monitoring its availability, performance or
                            functionality, or for any other benchmarking or competitive purposes;
                        </li>
                        <li>
                            access the Site to upload any Content or computer code for the purposes of:
                            <ol style={{listStyleType: "lower-alpha"}}>
                                <li>
                                    causing a breach of security to the Site;
                                </li>
                                <li>
                                    interfering with the proper working, functionality or performance of the Site;
                                </li>
                                <li>
                                    or preventing others from accessing or using the Site.
                                </li>
                            </ol>
                        </li>
                    </ol>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>8 SECTION - DISCLAIMER OF
                        WARRANTIES</Typography>
                    <p>
                        8.1 YOUR USE OF THE SITE AND ALL CONTENT FORMING PART OF OR RELATED TO THE SITE, INCLUDING ANY
                        CONTENT YOU UPLOAD OR SUBMIT AND ANY THIRD PARTY SOFTWARE AND CONTENT, ARE AT YOUR SOLE
                        RESPONSIBILITY AND RISK. THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. SKRO
                        EXPRESSLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND WITH RESPECT TO
                        THE SITE, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR
                        CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, COMPLETENESS,
                        PERFORMANCE, SYSTEM INTEGRATION, QUIET ENJOYMENT, TITLE, AND NON-INFRINGEMENT.
                    </p>
                    <p>
                        8.2 SKRO DISCLAIMS ANY WARRANTY THAT THE SITE OR ANY CONTENT, INCLUDING WITHOUT LIMITATION ANY
                        THIRD PARTY SOFTWARE AND CONTENT, WILL MEET YOUR REQUIREMENTS OR BE UNINTERRUPTED, TIMELY,
                        SECURE, OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE
                        THE SITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU AGREE THAT FROM TIME TO
                        TIME SKRO MAY REMOVE THE SITE FOR INDEFINITE PERIODS OF TIME WITHOUT NOTICE TO YOU. YOUR ACCESS
                        AND USE OF THE SITE MAY BE INTERRUPTED FROM TIME TO TIME FOR ANY OF SEVERAL REASONS, INCLUDING,
                        WITHOUT LIMITATION, THE MALFUNCTION OF EQUIPMENT, PERIODIC UPDATING, MAINTENANCE OR REPAIR OF
                        THE SITE OR OTHER ACTIONS THAT SKRO, IN ITS SOLE DISCRETION, MAY ELECT TO TAKE. SKRO MAKES NO
                        GUARANTEE REGARDING:
                    </p>
                    <p>
                        8.2.1 THE AMOUNT, TIMING AND DELIVERY OF ANY CLICKS OR IMPRESSIONS WITH RESPECT TO ANY CONTENT
                        (INCLUDING THIRD PARTY CONTENT) OR ADVERTISING ON THE SITE;
                    </p>
                    <p>
                        8.2.2 THE COMPATIBILITY OF YOUR SOFTWARE, HARDWARE OR CONTENT WITH THE SITE.
                    </p>
                    <p>
                        8.3 SKRO IS NOT RESPONSIBLE FOR THE ACTS OR OMISSIONS OF, OR FOR THE FAILINGS OF, ANY
                        THIRD-PARTY PROVIDER OF ANY CONTENT, SERVICE, NETWORK, SOFTWARE OR HARDWARE, INCLUDING BUT NOT
                        LIMITED TO, INTERNET SERVICE PROVIDERS, HOSTING SERVICES UTILIZED BY SKRO, TELECOMMUNICATIONS
                        PROVIDERS, CONTENT PROVIDED BY OTHER USERS, OR ANY SOFTWARE OR HARDWARE NOT PROVIDED BY SKRO.
                    </p>
                    <p>
                        8.4 YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT YOUR CONTENT IS COMPATIBLE WITH THE SITE. SKRO
                        DISCLAIMS ANY LIABILITY OR RESPONSIBILITY FOR ANY UNAUTHORISED USE OF YOUR CONTENT BY THIRD
                        PARTIES OR OTHER USERS OF THE SITE AND IS NOT RESPONSIBLE FOR PROTECTING YOUR CONTENT.
                    </p>
                    <p>
                        8.5 ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR
                        OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
                        SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
                    </p>
                    <p>
                        8.6 NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM SKRO OR THROUGH OR
                        FROM THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>9 SECTION - THIRD PARTY SITES AND
                        CONTENT</Typography>
                    <p>
                        9.1 The Site may permit you to link to other websites or resources on the Internet, and other
                        websites or resources may contain links to the Site. These other websites are not under Skro’s
                        control, and you acknowledge that Skro is not responsible or liable for any third party content,
                        including but not limited to the accuracy, integrity, quality, usefulness, legality,
                        appropriateness, safety or intellectual property rights of or relating to such third party
                        content or any other aspect of such websites or resources. The inclusion of any such link does
                        not imply endorsement by Skro or any association with its operators.
                    </p>
                    <p>
                        9.2 You further acknowledge and agree that Skro shall not be responsible or liable, directly or
                        indirectly, for any damage or loss caused or alleged to be caused by or in connection with the
                        use of or reliance on any such third party content, goods or services available on or through
                        any such website or resource.
                    </p>
                    <p>
                        9.3 Access and use of third party sites, including the information, material, products and
                        services on third party sites or available through third party sites, is solely at your own
                        risk.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>10 SECTION – DISCLAIMER OF WARRANTIES
                        AND
                        LIMITATION OF
                        LIABILITY</Typography>
                    <p>
                        10.1 In no case shall Skro our directors, officers, employees, affiliates, agents, contractors,
                        interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any
                        direct, indirect, incidental, punitive or other damages of any kind, including, without
                        limitation to, lost profits, lost revenue, lost savings, loss of data, replacement costs, or any
                        similar damages, whether based in contract, tort (including negligence), strict liability or
                        otherwise, arising from your use of any of part of the Site, Service and Application or for any
                        other claim related in any way to your use of the Site, Service and Application, including, but
                        not limited to, any errors or omissions in any content, or any loss or damage of any kind
                        incurred as a result of the use of the Site, Service and Application or any content (or product)
                        posted, transmitted, or otherwise made available via the Site, Service and Application, even if
                        advised of their possibility. Because it may not always be possible to limit the liability for
                        damages, in such cases, our and partnering companies’ liability shall be limited to the maximum
                        extent permitted by applicable laws.
                    </p>
                    <p>
                        10.2 Skro reserves the right to modify, suspend or discontinue the Site and/or Services, or any
                        portion thereof, with or without notice at any time and for any reason, including, but not
                        limited to, a failure to comply with the terms of this Agreement. Skro shall have no liability
                        whatsoever for any damages, liabilities, losses or any other consequences that you may incur as
                        a result of any such modification, suspension or discontinuance.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>11 SECTION - INDEMNITY</Typography>
                    <p>
                        11.1 You agree to indemnify, defend, and hold harmless Skro, and its subsidiaries, affiliates,
                        co-branders, all third-party advertisers, technology providers, service providers or other
                        partners, and each of their respective officers, directors, agents, shareholders, employees and
                        representatives, from and against any third party claim, demand, loss, damage, cost, or
                        liability (including, reasonable attorneys' fees) arising out of or relating to this Agreement
                        or the Site, including but not limited to in relation to:
                    </p>
                    <p>
                        a) your use, non-use or misuse of, or connection to the Site, the Services and any Content,
                        including without limitation your Content and any third party Content, forming part of the Site;
                    </p>
                    <p>
                        b) your breach or alleged breach of this Agreement;
                    </p>
                    <p>
                        c) your violation of any rights, including intellectual property rights, of a third party.
                    </p>
                    <p>
                        11.2 Skro reserves the right, at your expense, to assume the exclusive defence and control of
                        any matter for which you are required to indemnify Skro and you agree to cooperate with Skro's
                        defence of these claims. You agree not to settle any matter without the prior written consent of
                        Skro.
                    </p>
                    <p>
                        11.3 Skro will use reasonable efforts to notify you of any such claim, action or proceeding upon
                        becoming aware of it.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>12 SECTION - CANCELLATION AND
                        TERMINATION</Typography>
                    <p>
                        12.1 You may cancel your Subscription plan at any time. To do this, please contact us and as a
                        result, the automatic billing of your Subscription plan will be canceled. After cancellation,
                        you will receive a notification that your Subscription plan has been canceled. If there are any
                        questions as for the process of automatic Subscription plan renewal, please contact our Customer
                        Support. After cancellation of your paid Subscription plan, you will have access to your Account
                        in the mode of Free Plan.
                    </p>
                    <p>
                        12.2 You will remain liable for all charges accrued on your Account up to the time when you
                        downgrade, including the full monthly fees for the month in which you have downgraded your
                        Account. Skro is under no obligation to store your Content and may delete your Account and your
                        Content immediately upon cancellation or after your Account has been downgraded to the Free
                        Plan. Skro may keep your Account and your Content indefinitely following cancellation or after
                        your Account has been downgraded to the Free Plan.
                    </p>
                    <p>
                        12.3 Skro reserves the right at any time, and without cost, charge or liability, to terminate
                        this Agreement at its sole discretion for any reason, including, but not limited to, a failure
                        to comply with the terms of this Agreement.
                    </p>
                    <p>
                        12.4 Skro reserves the right to deny access to any user of the Site, Service or Account.
                    </p>
                    <p>
                        12.5 Skro reserves the right to terminate any portion of the Site at any time, for any reason,
                        with or without notice.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>13 SECTION - DISPUTE RESOLUTION AND
                        GOVERNING
                        LAW</Typography>
                    <p>
                        13.1 These Terms and any separate agreements whereby we provide you Services or access to our
                        Site, Service and Application shall be governed by, and construed in accordance with the laws of
                        the Republic of Estonia.
                    </p>
                    <p>
                        13.2 We at Skro encourage you to contact us if you are having an issue, before resorting to the
                        courts. In the unfortunate situation where legal action does arise, these Terms (and all other
                        forms, operating rules, policies, or guidelines incorporated by reference or available by
                        hyperlink) will be governed by and construed in accordance with the laws of the Republic of
                        Estonia, without giving effect to any principles of conflicts of law, and without application of
                        the Uniform Computer Information Transaction Act or the United Nations Convention of Controls
                        for International Sale of Goods.
                    </p>
                    <p>
                        13.3 You agree that Skro and its Site, Services and Application are deemed passive which does
                        not give rise to jurisdiction over Skro or its subsidiaries, affiliates, employees, agents,
                        directors, officers, or shareholders, either specific or general, in any jurisdiction other than
                        the Republic of Estonia.
                    </p>
                    <p>
                        13.4 You agree that any action at law or in equity arising out of or relating to these Terms, or
                        your use or non-use of Skro Site, Service and Application, shall be filed only in the state
                        courts located in the Republic of Estonia, and you hereby consent and submit to the personal
                        jurisdiction of these courts for the purposes of litigating any such action.
                    </p>
                    <p>
                        13.5 You hereby irrevocably waive any right you may have to a trial by jury in any dispute,
                        action, or proceeding in the state of your residence or by the state of incorporation (if
                        applicable).
                    </p>
                    <p>
                        13.6 You acknowledge and agree that any kind of arbitration forums, mediation facilities or
                        similar instruments to settle disputes shall not apply unless expressly required by the laws of
                        the Republic of Estonia or the European Union.
                    </p>
                    <p>
                        13.7 If you wish to submit a complaint, please contact us at support@skro.eu – we shall do our
                        best to resolve it.
                    </p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>14 SECTION - CONTACT
                        INFORMATION</Typography>
                    <p>Skro OÜ</p>
                    <p>Registry code: 16173240</p>
                    <p>Address: Muuga põik 6, Laiaküla, Viimsi, Harju maakond 74008 Estonia</p>
                    <p>Customer support: support@skro.eu</p>

                    <Typography variant="h4" sx={{paddingTop: 3, fontSize: 24}}>15 SECTION - TIME THE TERMS ENTER INTO
                        EFFECT</Typography>
                    <p>
                        These Terms enter into effect on April 21th, 2021, at 00.00 Eastern European Time (EET) and
                        apply to all actions performed on our Site, Service and within the Application since then.
                    </p>
                </Box>
            </Box>
        </DocRoot>
    );
}

TermsConditions.propTypes = {
    selectTermsConditions: PropTypes.func.isRequired
};

export default TermsConditions;
