import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function FacebookAdsIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", pb: "12px"}}>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/meta.svg" alt="Facebook (Meta) Ads Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Facebook (Meta) Ads with Skro?"/>
            <Box>
                <p>
                    <a href="https://facebook.com" target="_blank" rel="noreferrer">
                        Facebook (Meta) Ads
                    </a> integration gives you the ability to manage and optimize all your ads through the Skro UI.
                </p>
                <ul>
                    <li>
                        Send conversions to Facebook to feed their AI and optimize audiences.
                    </li>
                    <li>
                        Sync ads spent every 30 minutes to Skro, so you get accurate reporting.
                    </li>
                    <li>
                        Check the delivery status and manage the status of Campaigns, AdSets and Ads directly from Skro
                        UI.
                    </li>
                    <li>
                        Change Daily Budget directly from Skro UI.
                    </li>
                    <li>
                        Import dynamic columns like CPM, Link CPC, Link CTR, Impressions, Link EPC and Link Clicks from
                        Facebook to Skro.
                    </li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in Facebook (Meta) Ads and Skro so
                    Facebook can
                    pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in Facebook and Skro is required.
                </p>
                <DocImage src="/images/docs/meta-campaign-reporting.png" alt="Manage Facebook Ads campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/meta-integration-1.png" alt="Meta Integration step 1"
                />
                <ul>
                    <li>Click on <strong>Facebook</strong> logo and you will be redirected to Facebook (Meta) Ads.</li>
                </ul>
                <DocImage src="/images/docs/meta-integration-2.png" alt="Meta Integration step 2"
                />
                <ul>
                    <li>Sign in the account you want to integrate.</li>
                    <li>Opt in your Pages and click <strong>Continue</strong></li>
                </ul>
                <DocImage src="/images/docs/meta-integration-3.png" alt="Meta Integration step 3"
                />
                <ul>
                    <li>Here you can see the list of permissions you need to grant access.</li>
                    <li>Click <strong>Save</strong> button</li>
                </ul>
                <DocImage src="/images/docs/meta-integration-4.png" alt="Meta Integration step 4"
                />
                <ul>
                    <li>Click on <strong>Got it</strong> and you will be redirect back to Skro.</li>
                </ul>
                <DocImage src="/images/docs/meta-integration-5.png" alt="Meta Integration step 5"
                />
                <ul>
                    <li>
                        Choose the Facebook (Meta) Ads account you want to integrate. If you have multiple ads account,
                        you need to integrate them separately.
                    </li>
                    <li>
                        Click on the <strong>Next</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/meta-integration-6.png" alt="Meta Integration step 6"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2 id="conversion-api">II. Setup Conversion API</h2>
                <p>
                    If you want to send conversion info back to Facebook (Meta) Ads, then you will need to
                    enable <strong>Conversion API.</strong>
                </p>
                <p><strong>Facebook Conversion Events</strong></p>
                <ol>
                    <li>
                        <strong>Conversion Event Name</strong> - Define all your events that your want to track
                        with CAPI (Lead, Purchase, Subscribe).
                        When creating a campaign in Skro, then you will need to specify which conversion event you
                        want to track. (If you want to track multiple events, then you will need to use our
                        Custom Conversions. For example: AddToCart, ViewContent etc.).
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to Facebook.
                    </li>
                </ol>

                <p><strong>Facebook Pixels</strong></p>
                <ol>
                    <li><strong>Pixel Name</strong> - Just give a name to your pixel.</li>
                    <li>
                        <strong>Pixel ID</strong> - Your Facebook pixel ID what you are going to use when creating a
                        campaign in Facebook and Skro.
                    </li>
                    <li>
                        <strong>Conversion API Access Token</strong> - Go to Facebook Ads Manager and click
                        on <strong>All Tools</strong> -> <strong>Events Manager</strong> -> Select your pixel and go
                        to <strong>Settings</strong>. Scroll down and you will see Conversion API section. Click
                        on <strong>Generate Access Token</strong>.
                    </li>
                    <li>Copy and paste that token in to Skro.</li>
                </ol>

                <p>Click <strong>Click & Save</strong> button.</p>
                <DocImage src="/images/docs/meta-integration-11.png" alt="Meta Integration step 11"
                />
                <p>You <strong>Facebook (Meta) Ads</strong> account is successfully integrated.</p>

                <ul>
                    <li>You can turn on/off your cost sync.</li>
                    <li>Edit, delete or renew your integration access token.</li>
                    <li>
                        Renewing your access token is needed because sometimes Facebook will revoke your access token
                        for some reason and your cost sync will not work.
                    </li>
                </ul>
                <DocImage src="/images/docs/meta-integration-12.png" alt="Meta Integration step 12"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on
                        the <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/meta-integration-13.png" alt="Meta Integration step 13"
                />
                <ul>
                    <li>Click on <strong>Facebook (Meta) Ads</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/meta-integration-14.png" alt="Meta Integration step 14"
                />
                <ul>
                    <li>Choose and select your Facebook (Meta) integration from dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/meta-integration-15.png" alt="Meta Integration step 15"
                />
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for Facebook (Meta)
                    Ads.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your landing page (pre-lander).
                </p>
                <ul>
                    <li>Navigate to the <strong>Landings</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your landing URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-1.png" alt="Setup Skro Landing"
                />
                <p>
                    Add your offers.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-2.png" alt="Setup Skro Offer"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as Facebook (Meta) Ads.</li>
                    <li>
                        Select your <strong>Conversion Event Name</strong>. That is needed to send conversion info back
                        to Facebook (Meta).
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-3.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-4.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>Select your landing.</li>
                    <li>Select your offer(s).</li>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-6.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose <b>Direct</b> method and select your landing page.</li>
                    <li>Copy and paste the Landing URL to your Facebook (Meta) Ads <strong>* Website URL</strong> field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://prelander1.com"}
                </Alert>
                <ul>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>URL parameters</strong> text field.
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiZDdjOTllNDAtNWVjOS00NjZjLWI1MmMtZTk1YjM3NTViNsY3IiwiciI6MCwicCI6MCwibCI6MH0=&ad_id={{ad.id}}&ad_name={{ad.name}}&adset_id={{adset.id}}&adset_name={{adset.name}}&campaign_id={{campaign.id}}&campaign_name={{campaign.name}}&placement={{placement}}&site_source_name={{site_source_name}}&gender={gender}"}
                </Alert>

                <p>
                    If you are doing ads by gender, then you can define your gender value manually to feed Facebook
                    Ads AI. If do not want to use this parameter then just remove it.
                </p>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 16}}>
                    Male traffic only: {"&gender=m"}
                </Alert>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 16}}>
                    Female traffic only: {"&gender=f"}
                </Alert>
                <ul>
                    <li>Copy and paste Skro <strong>Tracking Pixel</strong> to your website between HEAD tags.</li>
                    <li>Copy and paste Skro <strong>Click to offer URL</strong> to your landing page as CTA button.</li>
                    <li>Copy and paste Skro <strong>Postback URL</strong> to your affiliate network or use JS or IMG
                        pixel, but you will need to map Skro click ID and payout macros by yourself.
                    </li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-7.png" alt="Setup Skro Campaign"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>V. Create a Campaign in Facebook (Meta) Ads</h2>
                <p>
                    There are multiple campaign types that you can create with Facebook (Meta) Ads. Campaign types
                    determine how your ad will be promoted.
                </p>

                <p>In order to create a Facebook (Meta) ad campaign, please follow the steps below:</p>
                <ol>
                    <li>Sign in to your Facebook (Meta) ads manager account.</li>
                    <li>Create a new campaign.</li>
                    <li>Select your campaign type, tracking event, pixel, audience, placements, budget etc.</li>
                </ol>

                <p><strong>Example</strong>:</p>
                <p>The complete direct tracking URL that you have copied from Skro should look like this:</p>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://prelander1.com/?trck=eyJjcGlkIjoiZDdjOTllNDAtNWVjOS00NjZjLWI1MmMtZTk1YjM3NTViNDY3IiwiciI6MCwicCI6MCwibCI6MH0=&ad_id={{ad.id}}&ad_name={{ad.name}}&adset_id={{adset.id}}&adset_name={{adset.name}}&campaign_id={{campaign.id}}&campaign_name={{campaign.name}}&placement={{placement}}&site_source_name={{site_source_name}}&gender={gender}"}
                </Alert>

                <p>
                    On the ad level you have to provide your landing page URL as your business website. Do not
                    include any tracking parameters at this point, provide the plain URL.
                </p>

                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 24}}>
                    {"https://prelander1.com"}
                </Alert>

                <DocImage src="/images/docs/meta-camp-1.png" alt="Setup Facebook (Meta) Campaign"
                />

                <ul>
                    <li>
                        Paste the part of the direct tracking URL that you have copied from Skro that starts after
                        the <strong>?</strong> character in the <strong>URL Parameters</strong> text field.
                    </li>
                </ul>

                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 16}}>
                    {"trck=eyJjcGlkIjoiZDdjOTllNDAtNWVjOS00NjZjLWI1MmMtZTk1YjM3NTViNsY3IiwiciI6MCwicCI6MCwibCI6MH0=&ad_id={{ad.id}}&ad_name={{ad.name}}&adset_id={{adset.id}}&adset_name={{adset.name}}&campaign_id={{campaign.id}}&campaign_name={{campaign.name}}&placement={{placement}}&site_source_name={{site_source_name}}&gender=f"}
                </Alert>
                <DocImage src="/images/docs/meta-camp-2.png" alt="Setup Facebook (Meta) Campaign"
                />
                <ul>
                    <li>Click the <strong>Publish</strong> button.</li>
                </ul>
                <p>
                    Congratulations, you have successfully set up tracking of Facebook (Meta) traffic with Skro. Each
                    time a visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed. On a landing page, the direct tracking script will launch and send an HTTPS
                    request to Skro with information about a visit and other information passed from Facebook (Meta).
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default FacebookAdsIntegration;
