import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {LgPaddingTop} from "../home/Home";

function FrequentlyAskedQuestions() {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <LgPaddingTop sx={{backgroundColor: "common.white", mb: 6}}>
            <Typography variant="h3" align="center" sx={{paddingBottom: "50px"}}>
                Frequently Asked Questions
            </Typography>

            <Box sx={{
                width: '100%',
                maxWidth: 850,
                margin: "0 auto",
                padding: "0 12px"
            }} data-aos="zoom-in-up" data-aos-delay={200}>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel7bh-content"
                        id="panel7bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>
                            What makes Skro better than other trackers?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            Our performance reliability, best pricing, reports, integrations and features makes
                            Skro perfect tracking tool for your ad campaigns.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>
                            Do I need my own server?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            Skro is a SaaS solution, which means we store and retain your data. Forget about
                            additional costs of purchasing and maintaining your own server — we will take care about
                            everything for you with our Cloud-based solution.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>What are events?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            An event is any action that is being tracked — a visit, a click, a conversion. Every time a
                            user is redirected through our infrastructure, we call it an event.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>
                            Will my tracking stop after I reach the monthly limit?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            No. After you reach your plan limit, your events will still be tracked, but you’ll have to
                            pay
                            for the overages.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>
                            What are the overages?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            These are all the events that are not covered by your plan. For example, if you’re a Starter
                            plan user and reach the limit of 2 500 000 events, you will be charged €0.03 per every 1000
                            events that exceed the limit.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>
                            Is my data removed after the period of retention?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            We store all data for 24 months. If you would like to access historical data, simply
                            upgrade your plan.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>
                            What is the difference between a tracker and Google Analytics?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            Google Analytics is a tool for analyzing traffic on a site, for example, you can use this
                            service on your landing. A tracker knows how to distribute traffic between landing pages and
                            offers, counts costs and revenues, combines them into a single report, calculates profit and
                            ROI for various metrics. Optimize ad campaigns through the Skro interface.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} elevation={0}
                           sx={{
                               borderBottom: "1px solid",
                               borderBottomColor: "primary.borderLight"
                           }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel7bh-content"
                        id="panel7bh-header"
                    >
                        <Typography sx={{
                            fontSize: 18,
                            fontWeight: 600,
                            flexBasis: '100%',
                            flexShrink: 0,
                        }}>
                            What payment options you have?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" color="textSecondary">
                            Currently we support only Stripe payments.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Typography align="center" style={{marginTop: 50, color: "#666", padding: "0 15px"}} data-aos="zoom-in-up"
                        data-aos-delay={200}>
                More questions? Please contact to our Customer Support.<br/>
                support@skro.eu
            </Typography>
        </LgPaddingTop>
    );
}

export default FrequentlyAskedQuestions;
