import React from "react";
import {Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocLinkBox, DocRoot} from "../Docs";

function NamesiloAPIIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://namesilo.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/namesilo.svg" alt="NameSilo logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="NameSilo API Integration"/>
            <Box>

                <p>
                    This API integrations gives you the ability to create DNS records automatically for domain
                    verification and website distribution.
                </p>

                <h2 style={{paddingTop: "24px"}}>Step 1: Log In to Your NameSilo Account or Create a New One</h2>
                <DocDivider/>
                <p>
                    1. Go to the <a href="https://namesilo.com" target="_blank"
                                    rel="noreferrer">https://namesilo.com</a> web-page.
                </p>
                <p>
                    2. Log in to your account or create a new one.
                </p>
                <DocImage sx={{maxWidth: 400}} src="/images/docs/namesilo-int-1.png"
                          alt="NameSilo Integration step 1"/>

                <h2 style={{paddingTop: "24px"}}>Step 2: Generate Your Access Keys</h2>
                <DocDivider/>
                <p>
                    1. Now you are able to sign in to your NameSilo Console.
                </p>
                <p>
                    2. On the top right corner click on <strong>My Profile</strong> icon and then click
                    on <strong>My Account</strong> button.
                </p>
                <DocImage sx={{maxWidth: 500}} src="/images/docs/namesilo-int-2.png" alt="NameSilo Integration step 2"/>
                <p>
                    3. Find <strong>API Manager</strong> from the left sidebar and click on it.
                </p>
                <DocImage sx={{maxWidth: 300}} src="/images/docs/namesilo-int-3.png" alt="NameSilo Integration step 2"/>
                <p>
                    4. You will need to accept their terms and then click on <strong>Generate</strong> button to get API
                    token.
                </p>
                <DocImage src="/images/docs/namesilo-int-4.png" alt="NameSilo Integration step 2"/>
                <p>
                    5. Copy <strong>API Token</strong> to the clipboard.
                </p>
                <DocImage src="/images/docs/namesilo-int-5.png" alt="NameSilo Integration step 2"/>

                <h2 style={{paddingTop: "24px"}}>Step 3: Add integration to Skro</h2>
                <DocDivider/>
                <p>
                    1. Log in to Skro and navigate to the <strong>Settings</strong> -> <strong>Integrations</strong> and
                    scroll down to <strong>Servers / Domains - Deploy Websites & Issue SSL Certs</strong> section and
                    click on <strong>Add</strong> button.
                </p>
                <DocImage src="/images/docs/cloudflare-int-7.png" alt="Namesilo Integration Step 3"/>
                <p>
                    2. Click on <strong>NameSilo</strong> logo.
                </p>
                <DocImage src="/images/docs/namesilo-int-7.png" alt="Namesilo Integration Step 3"/>
                <p>
                    3. Add your Namesilo API Token and click on <strong>Save & Close</strong> button.
                </p>
                <DocImage src="/images/docs/namesilo-int-8.png" alt="Namesilo Integration Step 3"/>
                <p>4. Your Namesilo API integration has been done.</p>
            </Box>

            <DocLinkBox to="/docs/deploy-website/how-to-setup-domain-to-get-ssl-certificate"
                        title="Get started with website domain setup ->"/>

            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default NamesiloAPIIntegration;
