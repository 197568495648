import React from "react";
import {Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function ExoClickIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://exoclick.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/exoclick.svg" alt="ExoClick logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate ExoClick with Skro?"/>
            <Box>
                <p>
                    ExoClick integration gives you the ability to manage and optimize all your ExoClick ads through the
                    Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, variations, zones and sites every 30 min.</li>
                    <li>Check the status of your campaigns, variations, zones and sites.</li>
                    <li>Pause or resume campaigns, variations, zones and sites.</li>
                    <li>Change the bid value for campaigns and zones.</li>
                </ul>
                <DocImage src="/images/docs/manage-campaigns.jpg" alt="Manager ExoClick campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>How to generate ExoClick API token?</h2>
                <ul>
                    <li>Log in to your <strong>ExoClick</strong> account.</li>
                    <li>Navigate to <strong>Profile</strong> -> <strong>API Tokens</strong>.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-api-token-1.jpg" alt="ExoClick API Token 1"
                />
                <ul>
                    <li>
                        Click on <strong>New API Token</strong> and copy the token to clipboard.
                    </li>
                </ul>
                <DocImage src="/images/docs/exoclick-api-token-2.jpg" alt="ExoClick API Token 1"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add integration to Skro</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-integration-1.jpg" alt="ExoClick Integration step 1"
                />
                <ul>
                    <li>Click on <strong>ExoClick</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-integration-2.jpg" alt="ExoClick Integration step 2"
                />
                <ul>
                    <li>
                        Paste the <strong>ExoClick API Token</strong> to <strong>API Token</strong> field and
                        click <strong>Create Integration</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/exoclick-integration-3.jpg" alt="ExoClick Integration step 3"
                />
                <ul>
                    <li>You can turn on/off auto-cost update through <strong>Data transfer</strong> switch.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-integration-4.jpg" alt="ExoClick Integration step 4"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Setup <strong>Postback URL</strong> and associate the integration with ExoClick traffic source.</h2>
                <ul>
                    <li>Log in to your ExoClick account.</li>
                    <li>
                        Navigate to the <strong>Campaigns</strong> -> <strong>Conversion Tracking</strong>.
                    </li>
                    <li>Click on <strong>New Conversion Goal</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-goal-1.jpg" alt="Setup ExoClick conversion tracking step 1"
                />
                <ul>
                    <li>Enter goal name.</li>
                    <li>Set <strong>Conversion value</strong> dynamic then we can send payout value over postback
                        URL with parameter <strong>&value={`{payout}`}</strong></li>
                    <li>Click on <strong>Create</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-goal-2.jpg" alt="Setup ExoClick conversion tracking step 2"
                />

                <ul>
                    <li>
                        Copy the <strong>goal</strong> parameter value from postback URL in
                        section <strong>Step 3. Triggering a Conversion</strong>
                    </li>
                </ul>
                <DocImage src="/images/docs/exoclick-goal-3.jpg" alt="Setup ExoClick conversion tracking step 3"
                />

                <ul>
                    <li>Go back to Skro and create a new traffic source.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-1.jpg" alt="Setup ExoClick traffic source step 1"
                />
                <ul>
                    <li>Click on <strong>ExoClick</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-2.jpg" alt="Setup ExoClick traffic source step 2"
                />
                <ul>
                    <li>
                        Replace goal value <strong>REPLACE</strong> with your goal value from ExoClick. In this
                        example the value is <strong>0f1fc9aa940f4abac90c35244b895bec</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-3.jpg" alt="Setup ExoClick traffic source step 3"
                />
                <ul>
                    <li>Add integration association.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-4.jpg" alt="Setup ExoClick traffic source step 4"
                />
                <ul>
                    <li>All parameters are pre-filled.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default ExoClickIntegration;
