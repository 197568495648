import React from "react";
import {Typography, Alert, Box} from "@mui/material";
import {DocImage, DocRoot} from "../Docs";

function HowToIntegrateSkroWithShopifyStore() {
    return (
        <DocRoot>
            <DocHeaderTitle title="How to integrate Skro with Shopify store?"/>
            <Box>
                <p>
                    Shopify is the leading cloud-based, multi-channel commerce platform. Merchants can use the software
                    to design, set up, and manage their stores across multiple sales channels, including web, mobile,
                    social media, marketplaces, brick-and-mortar locations and pop-up shops.
                </p>
                <p>
                    If you’re looking for a tracking tool that will help you gain insight into your e-commerce business,
                    Skro might just be a perfect solution for you. We will show you how easy it is to integrate your
                    Shopify store with Skro.
                </p>
                <Alert severity="warning" color="warning" variant="filled">
                    Knowledge of web design languages such as HTML, CSS, JavaScript may be required. We suggest hiring
                    an expert if you are not comfortable proceeding with the following guide on your own.
                </Alert>
                <h4>Step 1</h4>
                <p>
                    In the first step you will need to create a campaign in your Skro account. Since it's an online
                    shop, we understand how important it may be to track all types of incoming traffic – even organic.
                    That is why we have a solution that works for all types of traffic you may have. If you want to
                    track organic traffic as well, create a one more campaign (duplicate your existing campaign).
                </p>
                <h4>Step 2</h4>
                <p>
                    If campaign is created then you will see <b>Tracking Pixel JS</b> and <b>Postback Pixel JS</b>.
                </p>
                <DocImage src="/images/docs/shopify-pixels.jpg" alt="Shopify Pixels" />
                <h4>Step 3</h4>
                <p>
                    Copy <b>Tracking Pixel JS</b> and go to your Shopify Account -> Themes -> Actions -> Edit code.
                </p>
                <DocImage src="/images/docs/edit-code.jpg" alt="Edit code" />
                <p>
                    Select <b>theme.liquid</b> from Layout
                    section and put the following pixel code between <b>{"<head>"}</b> tags.
                </p>
                <DocImage src="/images/docs/theme-liquid.jpg" alt="Theme liquid" />
                <p>
                    If you need to track organic traffic then you will need to edit that tracking pixel code. Find at
                    the very beginning of that tracking pixel code <b>organicCampaignToken: ""</b>. If you have already
                    created an additional campaign to track your organic traffic then copy that campaign <b>"trck"</b>
                    parameter value there. Don’t forget to save the changes.
                </p>
                <DocImage src="/images/docs/organic.jpg" alt="Organic Campaign" />
                <Alert severity="success" color="success" variant="filled" style={{marginTop: 16}}>
                    organicCampaignToken:
                    "eyJjcGlkIjoiZTI5YjNmN2EtNzhkNC00MTc1LTljODEt3dg0NmFlODcxYTk0IiwiciI6MCwicCI6MCwibyI6MH0="
                </Alert>
                <h4>Step 4</h4>
                <p>
                    Now you need to set the postback pixel for your store. Copy the <b>Postback Pixel JS</b> and go to
                    Shopify Account -> Setting -> Checkout and place it inside the Additional Scripts section. Payout
                    is automatically detected and will be sent to Skro.
                </p>
                <DocImage src="/images/docs/checkout.jpg" alt="Checkout" />
                <DocImage src="/images/docs/additional-scripts.jpg" alt="Additional Scripts"
                     />
                <h4>Step 5</h4>
                <p>
                    Select an offer to generate the URL, copy it and submit to the traffic source for approval and start
                    tracking.
                </p>
                <DocImage src="/images/docs/generate-tracking-link.jpg" alt="Generate Tracking Link"
                     />
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export function DocHeaderTitle({title}) {
    return (
        <h1>
            {title}
        </h1>
    );
}

export function MoreQuestionsFooterText() {
    return (
        <Typography align="left" sx={{marginTop: "50px", color: "#666"}}>
            More questions? <br/> Please contact to our Customer Support <b>support@skro.eu</b>
        </Typography>
    );
}

export default HowToIntegrateSkroWithShopifyStore;
