import React from "react";
import {Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocLinkBox, DocRoot} from "../Docs";

function CloudflareAPIIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://cloudflare.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/cloudflare.svg" alt="Cloudflare logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="Cloudflare API Integration"/>
            <Box>
                <p>
                    This API integrations gives you the ability to create DNS records automatically for domain
                    verification and website distribution.
                </p>
                <h2 style={{paddingTop: "24px"}}>Step 1: Log in to your Cloudflare account or create a new one</h2>
                <DocDivider/>
                <p>
                    1. Go to the <a href="https://cloudflare.com" target="_blank"
                                    rel="noreferrer">https://cloudflare.com</a> web-page.
                </p>
                <p>
                    2. Log in to your account or create a new one.
                </p>
                <DocImage sx={{maxWidth: 400}} src="/images/docs/cloudflare-int-1.png"
                          alt="Cloudflare Integration step 1"/>

                <h2 style={{paddingTop: "24px"}}>Step 2: Generate API Token</h2>
                <DocDivider/>
                <p>
                    1. Now you are able to sign in to your Cloudflare Console.
                </p>
                <p>
                    2. On the top right corner click on <strong>Profile</strong> icon button and navigate to
                    the <strong>My Profile</strong> -> <strong>API Tokens</strong> and click on <strong>Create
                    Token</strong> button to generate new API Token.
                </p>
                <DocImage src="/images/docs/cloudflare-int-2.png" alt="Cloudflare Integration step 2"/>
                <p>
                    3. Find <strong>Edit Zone DNS</strong> row and click on <strong>Use template</strong> button.
                </p>
                <DocImage sx={{maxWidth: 600}} src="/images/docs/cloudflare-int-3.png"
                          alt="Cloudflare Integration step 2"/>

                <p>
                    4. Under permissions section select <strong>Edit</strong> and
                    from Zone Resources section choose <strong>All Zones</strong> and
                    click on <strong>Continue to summary</strong> button.
                </p>
                <DocImage sx={{maxWidth: 600}} src="/images/docs/cloudflare-int-4.png"
                          alt="Cloudflare Integration step 2"/>
                <p>
                    5. On this step you will see overview of your api token permissions and then
                    click on <strong>Create Token</strong> button.
                </p>
                <DocImage sx={{maxWidth: 600}} src="/images/docs/cloudflare-int-5.png"
                          alt="Cloudflare Integration step 2"/>
                <p>
                    6. Your API Token is now generated. Copy the value to clipboard.
                </p>
                <DocImage sx={{maxWidth: 600}} src="/images/docs/cloudflare-int-6.png"
                          alt="Cloudflare Integration step 2"/>

                <h2 style={{paddingTop: "24px"}}>Step 3: Add integration to Skro</h2>
                <DocDivider/>
                <p>
                    1. Log in to Skro and navigate to the <strong>Settings</strong> -> <strong>Integrations</strong> and
                    scroll down to <strong>Servers / Domains - Deploy Websites & Issue SSL Certs</strong> section and
                    click on <strong>Add</strong> button.
                </p>
                <DocImage src="/images/docs/cloudflare-int-7.png" alt="Cloudflare Integration Step 3"/>
                <p>
                    2. Click on <strong>Cloudflare</strong> logo.
                </p>
                <DocImage src="/images/docs/cloudflare-int-9.png" alt="Cloudflare Integration Step 3"/>
                <p>
                    3. Add your Cloudflare API Token and click on <strong>Save & Close</strong> button.
                </p>
                <DocImage src="/images/docs/cloudflare-int-10.png" alt="Cloudflare Integration Step 3"/>
                <p>4. Your Cloudflare API integration has been done.</p>
            </Box>

            <DocLinkBox to="/docs/deploy-website/how-to-setup-domain-to-get-ssl-certificate"
                        title="Get started with website domain setup ->"/>

            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default CloudflareAPIIntegration;
