import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";
import {Box, Button, Snackbar} from "@mui/material";

function CookieConsent(props) {
    const {handleCookieRulesDialogOpen} = props;
    const [isVisible, setIsVisible] = useState(false);

    const onAccept = useCallback(() => {
        Cookies.set("allow-cookies", true, {
            expires: 365,
        });
        setIsVisible(false);
    }, [setIsVisible]);

    const onDecline = useCallback(() => {
        Cookies.set("allow-cookies", false, {
            expires: 365,
        });
        setIsVisible(false);
    }, [setIsVisible]);

    useEffect(() => {
        if (Cookies.get("allow-cookies") === undefined) {
            setIsVisible(true);
        }
    }, []);

    return (
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            sx={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 4
            }}
            open={isVisible}
            message={
                <Box>
                    <p style={{fontWeight: 600, fontSize: 15}}>
                        This website uses cookies
                    </p>
                    <p style={{fontSize: 13}}>
                        We use cookies to ensure that we offer you the best experience while browsing our website.
                        Check our <Link to="#" sx={{
                        color: "primary.main",
                        cursor: "pointer",
                        textDecoration: "underline"
                    }}
                                        onClick={handleCookieRulesDialogOpen}>Cookie Declaration</Link> for more
                        information.
                    </p>
                    <Box display="flex">
                        <Box mr={2}>
                            <Button size="small" variant="contained" color="primary" onClick={onAccept}
                                    sx={{color: "common.white"}}>
                                Accept
                            </Button>
                        </Box>
                        <Box>
                            <Button color="inherit" variant="outlined" size="small" onClick={onDecline}>
                                Decline
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }
        />
    );
}

CookieConsent.propTypes = {
    handleCookieRulesDialogOpen: PropTypes.func.isRequired,
};

export default CookieConsent;
