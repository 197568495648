import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Typography, useTheme} from "@mui/material";
import {ContainerFluidLgPaddingTop, CustomContainer, LgPaddingTop} from "./Home";
import {ExpandMore} from "@mui/icons-material";

function SolutionSection() {
    const [expanded, setExpanded] = useState("panel4");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const theme = useTheme();

    return (
        <LgPaddingTop sx={{backgroundColor: "#FFFFFF"}}>
            <ContainerFluidLgPaddingTop>
                <Typography variant="h3" align="center" style={{paddingBottom: 20}}>
                    Solutions
                </Typography>
                <Typography align="center" sx={{paddingBottom: "60px", color: "#555", fontSize: 24}}>
                    Perfect for Ad Agencies, Affiliates, E-Commerce, Media Buyers and Search Feed Arbitrage
                </Typography>
                <CustomContainer data-aos="zoom-in-up" data-aos-delay={200}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} elevation={0}
                               sx={{
                                   borderBottom: "1px solid",
                                   borderBottomColor: theme.border.borderLight,
                                   paddingTop: 1,
                                   paddingBottom: 1
                               }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                        >
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontSize: theme.typography.pxToRem(18),
                                fontWeight: 600,
                                flexBasis: '100%',
                                flexShrink: 0,
                            }}>
                                Affiliates
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" color="textSecondary">
                                Skro empowers you to handle paid or organic traffic from any ad network or scale
                                thousands of campaigns to the maximum traffic capacity. Easily track, route, and A/B
                                test unlimited landings and offers, with or without redirects. Benefit from integrated
                                cost tracking and aggregated reports.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}
                               sx={{
                                   borderBottom: "1px solid",
                                   borderBottomColor: theme.border.borderLight,
                                   paddingTop: 1,
                                   paddingBottom: 1
                               }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontSize: theme.typography.pxToRem(18),
                                fontWeight: 600,
                                flexBasis: '100%',
                                flexShrink: 0,
                            }}>Ad Agencies</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" color="textSecondary">
                                Get clarity to optimize ad spend based on ROI. Provide outstanding services by taking
                                advantage of in-depth real-time tracking that goes beyond your clients expectations.
                                Everything from a single platform, accurate and well organized while sharing
                                performance insights directly with your clients.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0}
                               sx={{
                                   borderBottom: "1px solid",
                                   borderBottomColor: theme.border.borderLight,
                                   paddingTop: 1,
                                   paddingBottom: 1
                               }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontSize: theme.typography.pxToRem(18),
                                fontWeight: 600,
                                flexBasis: '100%',
                                flexShrink: 0,
                            }}>
                                E-Commerce
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" color="textSecondary">
                                Save hours manually connecting your advertising costs across channels to Shopify sales
                                and revenue. One dashboard to make campaign and budget decisions. Understand which
                                campaigns, sources, channels or countries are driving most of your sales, while
                                sending tracking signals to all your integrated ad networks. Works with every
                                eCommerce CMS.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} elevation={0}
                               sx={{
                                   borderBottom: "1px solid",
                                   borderBottomColor: theme.border.borderLight,
                                   paddingTop: 1,
                                   paddingBottom: 1
                               }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontSize: theme.typography.pxToRem(18),
                                fontWeight: 600,
                                flexBasis: '100%',
                                flexShrink: 0,
                            }}>
                                Search Feed Arbitrage
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" color="textSecondary">
                                Get rid of messy CSV data aggregation, leveraging multiple server-to-server
                                event tracking to monitor the performance of your search feed arbitrage campaigns -
                                including keyword performance insights. Plan and execute data-driven
                                optimization actions using both pending intraday and confirmed revenue insights
                                thanks to our direct API integration.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </CustomContainer>
            </ContainerFluidLgPaddingTop>
        </LgPaddingTop>
    );
}

export default SolutionSection;