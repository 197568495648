import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import FeatureSection from "./FeatureSection";
import PricingSection from "./PricingSection";
import FrequentlyAskedQuestions from "../faq/FrequentlyAskedQuestions";
import FindUsSection from "./FindUs";
import PartnerLogos from "./PartnerLogos";
import Cookies from "js-cookie";
import IntegrationSection from "./IntegrationSection";
import SolutionSection from "./SolutionSection";
import {styled} from "@mui/system";
import {Box} from "@mui/material";
import HeadSection from "./HeadSection";

export const ContainerFluid = styled(Box)(({theme}) => ({
    width: "100%",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 1370
}));

export const CustomContainer = styled(Box)(({theme}) => ({
    width: "100%",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
        maxWidth: 540
    },
    [theme.breakpoints.up("md")]: {
        maxWidth: 720
    },
    [theme.breakpoints.up("lg")]: {
        maxWidth: 1170
    }
}));

export const ContainerFluidLgPaddingTop = styled(Box)(({theme}) => ({
    width: "100%",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 1370,
    paddingTop: `${theme.spacing(20)}px !important`,
    [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(18)}px !important`
    },
    [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(16)}px !important`
    },
    [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(14)}px !important`
    }
}));

export const LgPaddingTop = styled(Box)(({theme}) => ({
    marginTop: "120px",
    paddingTop: `${theme.spacing(20)}px !important`,
    [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(18)}px !important`
    },
    [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(16)}px !important`
    },
    [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(14)}px !important`
    }
}));

function Home(props) {
    const {selectHome} = props;
    useEffect(() => {
        selectHome();
    }, [selectHome]);

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const refer = query.get('refer')
        if (refer !== null && Cookies.get("refer") === undefined) {
            Cookies.set("refer", refer, {path: "/", domain: "skro.eu", expires: 30, secure: true});
        }
    }, [props.location.search]);

    return (
        <Fragment>
            <HeadSection/>
            <IntegrationSection/>
            <SolutionSection/>
            <FeatureSection/>
            <PartnerLogos/>
            <PricingSection/>
            <FindUsSection/>
            <FrequentlyAskedQuestions/>
        </Fragment>
    );
}

Home.propTypes = {
    selectHome: PropTypes.func.isRequired
};

export default Home;
