import React from "react";
import {Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function BodisIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://bodis.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/bodis.svg" alt="Bodis logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Bodis with Skro?"/>
            <Box>
                <h2>Who we are</h2>
                <p>
                    <a href="https://bodis.com" target="_blank" rel="noreferrer">
                        Bodis
                    </a> was founded in 2007 and today consists of a team of creative minds working around the
                    clock to make Bodis the best domain parking platform. Our platform is designed to help domainers
                    monetize their undeveloped domain names. Bodis offers a variety of features that will simply create
                    better results for domainers.
                </p>
                <h2>Integration</h2>
                <p>
                    Skro integration with Bodis gives you the ability to sync revenue data that has been adjusted
                    after different traffic sources.
                </p>
                <ul>
                    <li>Update revenue and conversions every hour for today.</li>
                    <li>Update revenue and conversions 5 times a day for the last 2 days.</li>
                </ul>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>
                        Click on the <strong>Add</strong> button in Affiliate Networks & Search Feed Arbitrage section.
                    </li>
                </ul>
                <DocImage src="/images/docs/bodis-integration-1.png" alt="Bodis integration step 1"/>
                <ul>
                    <li>Click on the <strong>Bodis</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/bodis-integration-2.png" alt="Bodis integration step 2"/>
                <ul>
                    <li>
                        <strong>API Key</strong> info you must ask from your Bodis
                        account manager or write to Bodis support.
                    </li>
                    <li><strong>Bodis Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a different columns in your Skro reports.
                    </li>
                    <li>
                        <strong>Click ID Param</strong> is the parameter what you use in your offer URL to send Skro
                        Click ID.
                    </li>
                </ul>
                <DocImage src="/images/docs/bodis-integration-3.png" alt="Bodis integration step 3"/>
                <ul>
                    <li>Fill the form and click <strong>Save & Close</strong></li>
                </ul>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default BodisIntegration;
