import React from "react";
import {Box, Divider, Alert} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function TikTokIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://tiktok.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/tiktok.svg" alt="TikTok Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate TikTok with Skro?"/>
            <Box>
                <p>
                    <a href="https://tiktok.com" target="_blank" rel="noreferrer">
                        TikTok
                    </a> integration gives you the ability to manage and optimize all your ads through the Skro UI.
                </p>
                <ul>
                    <li>
                        Send conversions to TikTok to feed their AI and optimize audiences.
                    </li>
                    <li>
                        Sync ads spent every 30 minutes to Skro, so you get accurate reporting.
                    </li>
                    <li>
                        Check the delivery status and manage the status of Campaigns, Ad Groups and Ads directly
                        from Skro UI.
                    </li>
                    <li>
                        Change Daily Budget directly from Skro UI.
                    </li>
                    <li>
                        Import dynamic columns like CPM, Link CPC, Link CTR, Impressions, Link EPC and Link Clicks from
                        TikTok to Skro.
                    </li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in TikTok and Skro so TikTok can
                    pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in TikTok and Skro is required.
                </p>
                <DocImage src="/images/docs/tiktok-dashboard.png" alt="Manage TikTok ad campaigns with Skro tracker"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-1.png" alt="TikTok and Skro Integration step 1"
                />
                <ul>
                    <li>Click on <strong>TikTok</strong> logo and you will be redirected to TikTok Ads.</li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-2.png" alt="TikTok and Skro Integration step 2"
                />
                <ul>
                    <li>Sign in the account you want to integrate.</li>
                    <li>Here you can see the list of permissions you need to grant access.</li>
                    <li>Click <strong>Confirm</strong> button and you will be redirect back to Skro.</li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-3.png" alt="TikTok and Skro Integration step 3"
                />
                <ul>
                    <li>
                        Choose the TikTok ad account you want to integrate. If you have multiple ad accounts,
                        you need to integrate them separately.
                    </li>
                    <li>
                        Click on the <strong>Next</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-4.png" alt="TikTok and Skro Integration step 4"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2 id="conversion-api">II. Setup Conversion API</h2>
                <p>
                    If you want to send conversion info back to TikTok, then you will need to
                    enable <strong>Conversion API.</strong>
                </p>
                <p><strong>TikTok Conversion Events</strong></p>
                <ol>
                    <li>
                        <strong>Conversion Event Name</strong> - Define all your events that your want to track
                        with CAPI (CompleteRegistration, CompletePayment, Subscribe etc.).
                        When creating a campaign in Skro, then you will need to specify which conversion event you
                        want to track. (If you want to track multiple events, then you will need to use our
                        Custom Conversions and fire the pixel where needed. For example: AddToCart, ViewContent etc.).
                    </li>
                    <li>
                        <strong>Include Conversion Payout</strong> - If checked, we will send conversion revenue and
                        currency to TikTok.
                    </li>
                </ol>

                <p><strong>Facebook Pixels</strong></p>
                <ol>
                    <li><strong>Pixel Name</strong> - Just give a name to your pixel.</li>
                    <li>
                        <strong>Pixel ID</strong> - Your TikTok pixel ID what you are going to use when creating a
                        campaign in TikTok and Skro. Go to TikTok Ads Manager and click
                        on <strong>Tools</strong> -> <strong>Events</strong> -> <strong>Web Events</strong> and copy
                        your pixel ID. For example IDCM7EMDJC77UVR5LM77GO
                    </li>
                    <li>
                        <strong>Conversion API Access Token</strong> - Go to TikTok Ads Manager and click
                        on <strong>Tools</strong> -> <strong>Events</strong> -> <strong>Web Events</strong> Select
                        your pixel and go to <strong>Settings</strong>. Scroll down and you will
                        see <strong>Events API</strong> section. Click on <strong>Generate Access Token</strong>.
                    </li>
                    <li>Copy and paste that token in to Skro.</li>
                    <li>Click on <strong>Click & Save</strong> button.</li>
                </ol>

                <DocImage src="/images/docs/tiktok-int-5.png" alt="TikTok and Skro Integration step 5"
                />

                <p>Your <strong>TikTok</strong> ad account is successfully integrated.</p>

                <ul>
                    <li>You can turn on/off your cost sync.</li>
                    <li>Edit, delete or renew your integration access token.</li>
                    <li>
                        Renewing your access token is needed because sometimes TikTok will revoke your access token
                        for some reason and your cost sync will not work.
                    </li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-6.png" alt="TikTok and Skro Integration step 6"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on
                        the <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/meta-integration-13.png" alt="TikTok and Skro Integration step 13"
                />
                <ul>
                    <li>Click on <strong>TikTok</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-7.png" alt="TikTok and Skro Integration step 7"
                />
                <ul>
                    <li>Choose and select your TikTok integration from dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-8.png" alt="TikTok and Skro Integration step 8"
                />
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for TikTok.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in Skro</h2>
                <p>
                    First you will need to add your landing page (pre-lander).
                </p>
                <ul>
                    <li>Navigate to the <strong>Landings</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your landing URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-1.png" alt="Setup Skro Landing"
                />
                <p>
                    Add your offers.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your offer URL and add macros if you needed.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/meta-skro-camp-2.png" alt="Setup Skro Offer"
                />
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as TikTok.</li>
                    <li>
                        Select your <strong>Conversion Event Name</strong>. That is needed to send conversion info back
                        to TikTok.
                    </li>
                    <li>
                        Select your <strong>Conversion Pixel</strong>. That is needed to send conversion info back
                        to TikTok.
                    </li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Select your cost currency.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-9.png" alt="TikTok and Skro Integration step 9"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-10.png" alt="TikTok and Skro Integration step 10"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong></li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong></li>
                    <li>Select your landing.</li>
                    <li>Select your offer(s).</li>
                    <li>Click <strong>Save</strong></li>
                </ul>
                <DocImage src="/images/docs/tiktok-int-11.png" alt="TikTok and Skro Integration step 11"
                />
                <ul>
                    <li>Choose <b>Direct</b> method and select your landing page.</li>
                    <li>Copy and paste the Landing URL with macros to your TikTok ad <strong>*URL</strong> field.</li>
                </ul>

                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://prelander1.com/?trck=eyJjcGlkIjoiOTc4NDk2OGUtYzQwMy00MGQxLTg4OTUtOTE0NzliMzM2M2E5IiwiciI6MCwicCI6MCwibCI6MH0=&ttclid=__CLICKID__&ad_id=__CID__&ad_group_id=__AID__&campaign_id=__CAMPAIGN_ID__&ad_name=__CID_NAME__&ad_group_name=__AID_NAME__&campaign_name=__CAMPAIGN_NAME__&placement=__PLACEMENT__"}
                </Alert>

                <DocImage src="/images/docs/tiktok-int-12.png" alt="TikTok and Skro Integration step 12"
                />

                <ul>
                    <li>Copy and paste Skro <strong>Tracking Pixel</strong> to your website between HEAD tags.</li>
                    <li>Copy and paste Skro <strong>Click to offer URL</strong> to your landing page as CTA button.</li>
                    <li>Copy and paste Skro <strong>Postback URL</strong> to your affiliate network or use JS or IMG
                        pixel, but you will need to map Skro click ID and payout macros by yourself.
                    </li>
                </ul>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>V. Create a Campaign in TikTok</h2>
                <p>
                    There are multiple campaign types that you can create with TikTok. Campaign types
                    determine how your ad will be promoted.
                </p>

                <p>In order to create a TikTok ad campaign, please follow the steps below:</p>
                <ol>
                    <li>Sign in to your TikTok ads manager account.</li>
                    <li>Create a new campaign.</li>
                    <li>Select your campaign type, tracking event, pixel, audience, placements, budget etc.</li>
                </ol>

                <DocImage src="/images/docs/tiktok-int-13.png" alt="TikTok and Skro Integration step 13"
                />

                <p><strong>Example</strong>:</p>
                <p>The complete direct tracking URL that you have copied from Skro should look like this:</p>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    {"https://prelander1.com/?trck=eyJjcGlkIjoiOTc4NDk2OGUtYzQwMy00MGQxLTg4OTUtOTE0NzliMzM2M2E5IiwiciI6MCwicCI6MCwibCI6MH0=&ttclid=__CLICKID__&ad_id=__CID__&ad_group_id=__AID__&campaign_id=__CAMPAIGN_ID__&ad_name=__CID_NAME__&ad_group_name=__AID_NAME__&campaign_name=__CAMPAIGN_NAME__&placement=__PLACEMENT__"}
                </Alert>

                <ul>
                    <li>
                        On the ad level you have to provide your landing page URL as your business website.
                    </li>
                </ul>

                <DocImage src="/images/docs/tiktok-int-14.png" alt="TikTok and Skro Integration step 14"
                />
                <ul>
                    <li>Click the <strong>Publish</strong> button.</li>
                </ul>
                <p>
                    Congratulations, you have successfully set up tracking of TikTok traffic with Skro. Each
                    time a visitor clicks on your ad, will be directed to your landing page with Skro's tracking
                    parameters being passed. On a landing page, the direct tracking script will launch and send an HTTPS
                    request to Skro with information about a visit and other information passed from TikTok.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default TikTokIntegration;
