import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import FeatureSection from "../home/FeatureSection";

function Features(props) {
    const {selectFeatures} = props;
    useEffect(() => {
        selectFeatures();
    }, [selectFeatures]);
    return (
        <Fragment>
            <FeatureSection/>
        </Fragment>
    );
}

Features.propTypes = {
    selectFeatures: PropTypes.func.isRequired
};

export default Features;
