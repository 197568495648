import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {AppBar, Box, Button, Divider, IconButton, Toolbar} from "@mui/material";
import {Apps, Subscriptions, Home, Help, FindInPage, Menu} from "@mui/icons-material"
import NavigationDrawer from "../../shared/components/NavigationDrawer";
import {DocImage} from "../docs/Docs";

function NavBar(props) {
    const {handleMobileDrawerOpen, handleMobileDrawerClose, mobileDrawerOpen, selectedTab} = props;
    const menuItems = [
        {
            link: "/",
            name: "Home",
            icon: <Home sx={{color: "common.white"}}/>
        },
        {
            link: "/features",
            name: "Features",
            icon: <Apps sx={{color: "common.white"}}/>
        },
        {
            link: "/pricing",
            name: "Pricing",
            icon: <Subscriptions sx={{color: "common.white"}}/>
        },
        {
            link: "/faq",
            name: "FAQs",
            icon: <Help sx={{color: "common.white"}}/>
        },
        {
            link: "/docs/facebook/how-to-create-a-campaign-to-track-facebook-traffic",
            name: "Documentation",
            icon: <FindInPage sx={{color: "common.white"}}/>
        }
    ];
    return (
        <Box>
            <AppBar position="fixed" sx={{boxShadow: 0, backgroundColor: "common.darkBlack"}}>
                <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
                    <Box sx={{maxWidth: 200}}>
                        <Button href="/">
                            <DocImage src="/logo.svg" alt="Skro" sx={{width: "65px"}}/>
                        </Button>
                    </Box>
                    <Box>
                        <Box sx={{display: {md: 'none', xs: 'block'}}}>
                            <IconButton
                                onClick={handleMobileDrawerOpen}
                                aria-label="Open Navigation"
                            >
                                <Menu color="primary"/>
                            </IconButton>
                        </Box>
                        <Box sx={{display: {xs: "none", md: "inline"}}}>
                            {menuItems.map(element => {
                                if (element.link) {
                                    return (
                                        <Link
                                            key={element.name}
                                            to={element.link}
                                            sx={{textDecoration: "none !important"}}
                                            onClick={handleMobileDrawerClose}
                                        >
                                            <Button
                                                color="secondary"
                                                size="large"
                                                sx={{
                                                    fontSize: 15,
                                                    fontWeight: 600,
                                                    color: "common.white",
                                                    textTransform: "none"
                                                }}
                                            >
                                                {element.name}
                                            </Button>
                                        </Link>
                                    );
                                }
                                return (
                                    <Button
                                        color="secondary"
                                        size="large"
                                        onClick={element.onClick}
                                        sx={{
                                            fontSize: 13,
                                            fontWeight: 600,
                                            color: "common.white",
                                            textTransform: "none"
                                        }}
                                        key={element.name}
                                    >
                                        {element.name}
                                    </Button>
                                );
                            })}
                        </Box>
                    </Box>
                    <Box sx={{display: {xs: "none", md: "inline"}}}>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                sx={{color: "common.white", p: "4px 8px"}}
                                href="https://app.skro.eu/login"
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
                <Divider/>
            </AppBar>
            <NavigationDrawer
                menuItems={menuItems}
                anchor="right"
                open={mobileDrawerOpen}
                selectedItem={selectedTab}
                onClose={handleMobileDrawerClose}
            />
        </Box>
    );
}

NavBar.propTypes = {
    handleMobileDrawerOpen: PropTypes.func,
    handleMobileDrawerClose: PropTypes.func,
    mobileDrawerOpen: PropTypes.bool,
    selectedTab: PropTypes.string
};

export default NavBar;
