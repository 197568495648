import React from "react";
import {Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocLinkBox, DocRoot} from "../Docs";

function AWSAPIIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://aws.amazon.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/aws.svg" alt="AWS logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="AWS Integration"/>
            <Box>
                <h2 style={{paddingTop: "24px"}}>Step 1: Log In to Your AWS Account or Create a New One</h2>
                <DocDivider/>
                <p>
                    1. Go to the <a href="https://aws.amazon.com" target="_blank"
                                    rel="noreferrer">https://aws.amazon.com</a> web-page.
                </p>
                <p>
                    2. In the top right corner press the <strong>My Account</strong> drop-down menu and choose
                    the <strong>AWS Management Console</strong> option.
                </p>
                <DocImage sx={{maxWidth: 300}} src="/images/docs/aws-sign-up-1.png" alt="AWS Integration step 1"/>
                <p>
                    3. If you already have an existing AWS account, just log in and proceed to <strong>Step 2</strong>.
                    If you don't have one, press the <strong>Create a new AWS account</strong> button.
                </p>
                <DocImage sx={{maxWidth: 300}} src="/images/docs/aws-sign-up-2.png" alt="AWS Integration step 2"/>
                <p>
                    4. After that, you'll be redirected to a webpage with the registration form. Please fill in all
                    mandatory fields and press the <strong>Verify email address</strong> button.
                </p>
                <DocImage sx={{maxWidth: 300}} src="/images/docs/aws-sign-up-3.png" alt="AWS Integration step 3"/>
                <p>
                    5. Next, you will be redirected to the (5 steps) form where you will need to fill all your contact
                    and payment info.
                </p>

                <h2 style={{paddingTop: "24px"}}>Step 2: Generate Your Access Keys</h2>
                <DocDivider/>
                <p>
                    1. Now you are able to sign in to your AWS Console. Do this and proceed to the next step.
                </p>
                <p>
                    2. In the top right corner click on your account name and open the drop-down menu. Choose
                    the <strong>Security Credentials</strong> option.
                </p>
                <DocImage sx={{maxWidth: 300}} src="/images/docs/aws-integration-1.png" alt="AWS Integration 1"/>
                <p>
                    3. Scroll down to <strong>Access Keys</strong> section and press the <strong>Create Access
                    Key</strong> button.
                </p>
                <DocImage src="/images/docs/aws-integration-2.png" alt="AWS Integration 2"/>

                <p>
                    4. Tick on <strong>I understand creating a root ...</strong> and click on <strong>Create access
                    key</strong> button.
                </p>
                <DocImage src="/images/docs/aws-integration-3.png" alt="AWS Integration 3"/>
                <p>
                    5. Your access key is now generated. Copy these values to clipboard.
                </p>
                <DocImage src="/images/docs/aws-integration-4.png" alt="AWS Integration 4"/>
                <p>
                    6. Log in to Skro and navigate to the <strong>Settings</strong> -> <strong>Integrations</strong> and
                    scroll down to <strong>Servers - Deploy Websites</strong> section and click
                    on <strong>Add</strong> button.
                </p>
                <DocImage src="/images/docs/aws-integration-5.png" alt="AWS Integration 5"/>
                <p>
                    7. Choose AWS and click on the logo and new popup will show up. Add your AWS API Access Key and
                    Secret key and click <strong>Save & Close</strong>.
                </p>
                <DocImage sx={{maxWidth: 400}} src="/images/docs/aws-integration-6.png" alt="AWS Integration 6"/>
                <p>8. Your AWS API integration has been done.</p>
            </Box>

            <DocLinkBox to="/docs/deploy-website/how-to-setup-domain-to-get-ssl-certificate" title="Get started with website domain setup ->"/>

            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default AWSAPIIntegration;
