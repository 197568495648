import React from "react";
import {Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function TonicIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center"}}>
                <a href="https://tonic.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/tonic.svg" alt="Tonic logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Tonic with Skro?"/>
            <Box>
                <h2>Who we are</h2>
                <p>
                    <a href="https://tonic.com" target="_blank" rel="noreferrer">
                        Tonic
                    </a> offers you the proper tooling to efficiently monetize your Native, Social, Search, Display &
                    Direct Website traffic with the world’s largest search feed. Our trick to achieve the
                    highest-possible conversion rates for your traffic? Doublechecking every user’s interest by
                    confirming it twice.
                </p>
                <h2>Integration</h2>
                <p>
                    Skro integration with Tonic gives you the ability to sync revenue data that has been adjusted
                    after different traffic sources.
                </p>
                <ul>
                    <li>Update revenue date every hour.</li>
                </ul>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>
                        Click on the <strong>Add</strong> button in Affiliate Networks & Search Feed Arbitrage section.
                    </li>
                </ul>
                <DocImage src="/images/docs/bodis-integration-1.png" alt="Tonic integration step 1"
                />
                <ul>
                    <li>Click on the <strong>Tonic</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/tonic-integration-1.png" alt="Tonic integration step 2"
                />
                <ul>
                    <li>
                        <strong>API Username</strong> is an API token. Login to your Tonic Publisher account then
                        go to <strong>Account</strong> -> <strong>Settings</strong> -> <strong>API Access</strong>
                    </li>
                    <li>
                        <strong>API Password</strong> is an API secret. Login to your Tonic Publisher account then
                        go to <strong>Account</strong> -> <strong>Settings</strong> -> <strong>API Access</strong>
                    </li>
                    <li><strong>Tonic Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a different columns in your Skro reports.
                    </li>
                    <li>
                        <strong>Click ID Param</strong> is the parameter what you use in your offer URL to send Skro
                        Click ID.
                    </li>
                </ul>
                <DocImage src="/images/docs/tonic-integration-2.png" alt="Tonic integration step 3"
                />
                <ul>
                    <li>Fill the form and click <strong>Save & Close</strong></li>
                </ul>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default TonicIntegration;
