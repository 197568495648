import React from "react";
import {Divider, Box, Alert} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function SedoDirectIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://sedo.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/docs/sedo-logo.svg" alt="Sedo logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Sedo with Facebook and Skro direct tracking?"/>
            <Box>
                <p>
                    <a href="https://sedo.com" target="_blank" rel="noreferrer">
                        Sedo
                    </a> is the world's largest domain marketplace. With over 19 million listed domains and 2
                    million customers. Sedo is the world's largest platform for the professional trading of web
                    addresses.
                </p>
                <p>
                    Sedo stands for "Search Engine for Domain Offers" and their online platform currently provides not
                    only a convenient searchable domain marketplace, but also extensive services for domain buyers and
                    sellers: from domain parking to value appraisals all the way up to the brokerage and marketing of
                    domains by our professional brokerage team.
                </p>
                <p>
                    Our integration with Sedo gives you the ability to sync earnings data that has been adjusted after
                    Sedo
                    first postback.
                </p>
                <ul>
                    <li>Update revenue of the previous 3 days once a day.</li>
                </ul>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>1. How to add Sedo integration?</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>Click on the <strong>Add</strong> button in Affiliate Network & Arbitrages section.</li>
                </ul>
                <DocImage src="/images/docs/aff-network-integration-1.jpg" alt="Sedo integration step 1"/>
                <ul>
                    <li>Click on the <strong>Sedo</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/sedo-integration-2.jpg" alt="Sedo integration step 2"/>
                <ul>
                    <li><strong>PartnerID</strong> and <strong>SignKey</strong> info you must ask from your Sedo
                        account manager or write to Sedo support.
                    </li>
                    <li><strong>Username</strong> and <strong>Password</strong> is your Sedo login credentials.</li>
                    <li><strong>Sedo Account Currency</strong> is the currency of the parking earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a different columns in your Skro reports.
                    </li>
                    <li>
                        <strong>Click ID Param</strong> is the parameter what you use in your offer URL to send Skro
                        Click ID.
                    </li>
                </ul>
                <DocImage src="/images/docs/sedo-integration-3.jpg" alt="Sedo integration step 3"/>
                <ul>
                    <li>Fill the form and click <strong>Save & Close</strong></li>
                </ul>

                <h2>2. How to set up Sub ID values for Direct tracking?</h2>

                <p>
                    First, you need to link the <strong>Arbitrage Integration</strong> with your Facebook traffic source
                    for a proper <strong>Direct</strong> tracking.
                </p>
                <ul>
                    <li>Go to <strong>Traffic Sources</strong>.</li>
                    <li>Click on <strong>Create</strong> button and select <strong>Facebook</strong> template.</li>
                    <li>Fill all the fields where needed.</li>
                    <li>Select your <strong>Traffic Source Integration</strong>.</li>
                    <li>Select your <strong>Arbitrage Integration.</strong></li>
                </ul>
                <DocImage src="/images/docs/sedo-direct-1.jpg" alt="Sedo and Facebook direct integration step 4"/>

                <ul>
                    <li>Click <strong>Save & Close</strong></li>
                </ul>

                <p>
                    To ensure accurate direct tracking with Facebook/Meta Ads, please add the "sub1" and "sub2"
                    parameters to your offer URL.
                </p>
                <Alert color="info" variant="filled">
                    <ul>
                        <li><strong>{`&sub1={campaignId}|{offerId}`}</strong></li>
                        <li><strong>{`&sub2={custom5}|{custom3}|{custom1}`}</strong></li>
                    </ul>
                </Alert>

                <ul>
                    <li>Go to <strong>Offers</strong> view and click on <strong>Create</strong> button</li>
                </ul>

                <DocImage src="/images/docs/sedo-direct-2.jpg" alt="Sedo and Facebook direct integration step 5"/>

                <p>This is an example url:</p>
                <Alert color="info" variant="filled">
                    {`https://sedo-offer.com?sub1={campaignId}|{offerId}&sub2={custom5}|{custom3}|{custom1}&network=facebook&site=my-site&adtitle=my-ad-title`}
                </Alert>

                <ul>
                    <li>Click <strong>Save & Close</strong></li>
                </ul>

                <p>Integration setup is done and your are ready to sync your earnings data
                    with <strong>Direct</strong> tracking.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default SedoDirectIntegration;
