import React from "react";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";
import {Box, Alert} from "@mui/material";

function HowToCreateACampaignToTrackMyShopifyTraffic() {
    return (
        <DocRoot>
            <DocHeaderTitle title="How to create a campaign to track my Shopify traffic?"/>
            <Box>
                <h4>Step 1</h4>
                <p>
                    Create your first campaign to track Shopify traffic. Please click to the green button in the
                    upper right corner.
                </p>
                <DocImage sx={{maxWidth: 300}} src="/images/docs/new-campaign-btn.jpg" alt="New campaign button"/>
                <h4>Step 2</h4>
                <ul>
                    <li>
                        <b>Traffic Source</b> - Select your Traffic Source where is your traffic from. For example we
                        have selected Facebook.
                    </li>
                    <li>
                        <b>Cost Model</b> - Facebook do not support dedicated cost parameter that is why we select
                        "Do not track". You can update your campaign cost manually.
                    </li>
                    <li>
                        <b>Currency</b> - Choose the cost currency. Needed when you are updating your cost.
                    </li>
                    <li>
                        Click <b>Next</b>
                    </li>
                </ul>
                <DocImage src="/images/docs/campaign-step1.jpg" alt="Campaign step 1"/>

                <h4>Step 3</h4>
                <ul>
                    <li>
                        <b>Campaign name</b> - Enter your campaign name.
                    </li>
                    <li>
                        <b>Country</b> - Select your campaign country where your traffic coming from. You can set it
                        Global if your do not want to specify it.
                    </li>
                    <li>
                        <b>Tracking domain</b> - Select your custom tracking domain. Please add your own custom tracking
                        domain in the <a href="https://app.skro.eu/settings/domains">Settings
                        view</a>. Free SSL is automatically generated for all your custom domains.
                    </li>
                    <li>
                        Click <b>Next</b>
                    </li>
                </ul>
                <Alert severity="warning" color="warning" variant="filled" sx={{marginBottom: "16px"}}>
                    To run high-volume campaigns, it is highly recommended that you set up and use a custom domains. The
                    default skrotrack.com and skrocloak.com domains are same for all users and may potentially get
                    flagged by antivirus software and traffic sources. That's why is meant for testing purposes only.
                </Alert>
                <DocImage src="/images/docs/campaign-step2.jpg" alt="Campaign step 2"/>

                <h4>Step 4</h4>
                <ul>
                    <li>
                        <b>Destination type</b> - Choose flow.
                    </li>
                    <li>
                        <b>Redirect mode</b> - Choose 302 as standard redirect mode.
                    </li>
                    <li>
                        <b>Use only offers</b> - Activate this option.
                    </li>
                    <li>
                        <b>Offers</b> - Select your Shopify store. Create new if you do not have any and select it from
                        dropdown menu.
                    </li>
                    <li>
                        Click <b>Save</b> to generate tracking links.
                    </li>
                </ul>
                <DocImage src="/images/docs/campaign-step3.jpg" alt="Campaign step 3"/>

                <h4>How to set up offer as your Shopify store?</h4>
                <ul>
                    <li>
                        <b>Name</b> - Add your store name.
                    </li>
                    <li>
                        <b>Offer URL</b> - Add your store homepage.
                    </li>
                    <li>
                        <b>Country</b> - Select your store country. You can leave it Global if do not want to specify
                        it.
                    </li>
                    <li>
                        <b>Affiliate network</b> - Select your affiliate network. If you don't have any then click
                        the plus icon to add one. Choose Shopify from the templates and click <b>Save & Close</b>. We'll
                        set up your tracking pixels in the final step.
                    </li>
                </ul>
                <DocImage src="/images/docs/add-offer.jpg" alt="Create offer"/>

                <h4>Step 5</h4>
                <p>
                    In the final step you will need to choose the "Shopify Pixels" and then select your Shopify store to
                    get the tracking link.
                </p>
                <ul>
                    <li>Copy the Offer URL to your traffic source and start tracking.</li>
                    <li>
                        <a href="/docs/shopify/how-to-integrate-skro-with-my-shopify-store">
                            Check how to integrate Skro with your Shopify store.
                        </a>
                    </li>
                </ul>
                <DocImage src="/images/docs/campaign-step4.jpg" alt="Campaign step 4"/>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default HowToCreateACampaignToTrackMyShopifyTraffic;
