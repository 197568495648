import React, {Fragment, Suspense} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {ThemeProvider} from '@mui/material/styles';
import theme from "./theme";
import Pace from "./shared/components/Pace";
import {CssBaseline} from "@mui/material";
import Main from "./components/Main";
import './index.css';

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Pace color={theme.palette.primary.main}/>
                <Suspense fallback={<Fragment/>}>
                    <Switch>
                        <Route>
                            <Main/>
                        </Route>
                    </Switch>
                </Suspense>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
