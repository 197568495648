import React from "react";
import {Divider, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function PushgroundIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://pushground.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/pushground.svg" alt="Pushground logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Pushground with Skro?"/>
            <Box>
                <p>
                    <a href="https://app.pushground.com/register?invitation=grE6FRebPnnluSmB5Ew17w%3D%3D"
                       target="_blank" rel="noreferrer">
                        Pushground
                    </a> integration gives you the ability to manage and optimize all your Pushground ads through
                    the Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, creatives, supplies and sites every 30 min.</li>
                    <li>Check the status of your campaigns, creatives supplies and sites.</li>
                    <li>Pause or resume campaigns, creatives, supplies and sites.</li>
                    <li>Change the bid value for campaigns and supplies.</li>
                </ul>
                <DocImage src="/images/docs/manage-campaigns.jpg" alt="Manager Pushground campaigns"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>How to generate Pushground API token?</h2>
                <ul>
                    <li>Log in to your <strong>Pushground</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong>.</li>
                </ul>
                <DocImage src="/images/docs/pushground-api-token-1.jpg" alt="Pushground API Token 1"
                />
                <ul>
                    <li>Copy the <strong>API Token</strong> to clipboard.</li>
                </ul>
                <DocImage src="/images/docs/pushground-api-token-2.jpg" alt="Pushground API Token 1"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>Add integration to Skro</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on the <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-integration-1.jpg" alt="Pushground Integration step 1"
                />
                <ul>
                    <li>Click on <strong>Pushground</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/pushground-traffic-source-1.jpg" alt="Pushground Integration step 2"
                />
                <ul>
                    <li>
                        Paste the <strong>Pushground API Token</strong> to <strong>API Token</strong> field and
                        click <strong>Create Integration</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/pushground-traffic-source-2.jpg" alt="Pushground Integration step 3"
                />
                <ul>
                    <li>You can turn on/off auto-cost update through <strong>Data transfer</strong> switch.</li>
                </ul>
                <DocImage src="/images/docs/pushground-traffic-source-3.jpg" alt="Pushground Integration step 4"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>
                    Setup <strong>Postback URL</strong> and associate the integration with Pushground traffic source.
                </h2>
                <ul>
                    <li>Navigate to <strong>Traffic Sources</strong>.</li>
                    <li>Click on <strong>Create</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/exoclick-traffic-source-1.jpg" alt="Setup Pushground traffic source step 1"
                />
                <ul>
                    <li>Click on <strong>Pushground</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/pushground-traffic-source-4.jpg"
                          alt="Setup Pushground traffic source step 2"
                />
                <ul>
                    <li>Traffic Source Postback URL is already pre-filled.</li>
                    <li>Add integration association.</li>
                </ul>
                <DocImage src="/images/docs/pushground-traffic-source-5.jpg"
                          alt="Setup Pushground traffic source step 3"
                />
                <ul>
                    <li>All parameters are pre-filled.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default PushgroundIntegration;
