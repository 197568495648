import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
    List,
    ListItemIcon,
    ListItemText,
    Drawer,
    IconButton,
    Typography,
    Toolbar, ListItemButton
} from "@mui/material";
import {Close, KeyboardArrowRight} from "@mui/icons-material";
import {isWidthUp} from "@mui/material/Hidden/withWidth";

function NavigationDrawer(props) {
    const {
        width,
        open,
        onClose,
        anchor,
        menuItems,
        selectedItem,
    } = props;

    useEffect(() => {
        window.onresize = () => {
            if (isWidthUp("sm", width) && open) {
                onClose();
            }
        };
    }, [width, open, onClose]);

    return (
        <Drawer variant="temporary" open={open} onClose={onClose} anchor={anchor}>
            <Toolbar sx={{width: 225, backgroundColor: "common.defaultBlue"}}>
                <ListItemButton
                    sx={{
                        p: 0,
                        m: 0,
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "right"
                    }}
                    disableGutters
                >
                    <ListItemIcon>
                        <IconButton onClick={onClose} aria-label="Close Navigation">
                            <Close color="primary"/>
                        </IconButton>
                    </ListItemIcon>
                </ListItemButton>
            </Toolbar>
            <List sx={{backgroundColor: "common.defaultBlue", height: "100%"}}>
                {menuItems.map(element => {
                    return (
                        <Link
                            compo
                            key={element.name}
                            to={element.link}
                            onClick={onClose}
                            style={{textDecoration: 'none'}}
                        >
                            <ListItemButton
                                selected={selectedItem === element.name}
                                disableRipple
                                disableTouchRipple
                            >
                                <ListItemIcon>{element.icon}</ListItemIcon>
                                <ListItemText
                                    primary={element.name}
                                    primaryTypographyProps={{
                                        sx: {
                                            color: "common.white",
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </Link>
                    );
                })}
            </List>
            <ListItemButton
                onClick={() => document.location.href = "https://app.skro.eu/login"}
                sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "primary.main",
                    }
                }}
            >
                <ListItemIcon><KeyboardArrowRight/></ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="button">
                            Sign in
                        </Typography>
                    }
                />
            </ListItemButton>
        </Drawer>
    );
}

NavigationDrawer.propTypes = {
    anchor: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedItem: PropTypes.string
};

export default NavigationDrawer;
