import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocRoot} from "../Docs";

function AdsIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://ads.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 100}} src="/images/integration/ads.svg" alt="Ads.com logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Ads.com with Skro?"/>
            <Box>
                <h2>Who we are</h2>
                <p>
                    <a href="https://ads.com" target="_blank" rel="noreferrer">
                        Ads.com
                    </a> is a monetization platform committed to delivering innovative, highly competitive,
                    scalable advertising solutions that guarantee superior integration rates for both monetizers and
                    advertisers alike. With over decades of combined experience in the digital advertising
                    space/industry, our team is dedicated to making Ads.com the web’s leading advertising application.
                </p>
                <h2>Integration</h2>
                <p>
                    Skro integration with Ads.com gives you the ability to sync revenue data that has been adjusted
                    after different traffic sources.
                </p>
                <ul>
                    <li>Update revenue and conversions every hour.</li>
                </ul>

                <h2>Step 1: Add integration</h2>
                <DocDivider/>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>
                        Click on the <strong>Add</strong> button in Affiliate Networks & Search Feed Arbitrage section.
                    </li>
                </ul>
                <DocImage src="/images/docs/ads-com-int-1.jpg" alt="Ads.com integration step 1"/>
                <ul>
                    <li>Click on the <strong>Ads.com</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/ads-com-int-2.jpg" alt="Ads.com integration step 2"/>
                <ul>
                    <li><strong>Name</strong> is a title for your integration.</li>
                    <li>
                        <strong>Username</strong> - Enter your account username not email.
                    </li>
                    <li>
                        <strong>Password</strong> - Enter your account password.
                    </li>
                </ul>
                <DocImage src="/images/docs/ads-com-int-3.jpg" alt="Ads.com integration step 3"/>

                <ul>
                <li>Logging in into your Ads.com Account.</li>
                    <li>Go to the <strong>Tracking</strong> section, which you can access from the left-side menu.</li>
                    <li>Click on the <strong>API</strong> tab.</li>
                    <li>
                        Click on <strong>Generate API Key</strong> button and copy the <strong>Secret Key</strong> and
                        paste it to Skro.
                    </li>
                </ul>
                <DocImage src="/images/docs/ads-com-int-4.jpg" alt="Ads.com integration step 4"/>

                <ul>
                    <li><strong>Ads.com Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a separate columns in your Skro reports. To use that you will need to use
                        Custom Conversions in Skro.
                    </li>
                    <li>
                        <strong>Click ID Param</strong> is the parameter what you use in your offer URL to send Skro
                        Click ID. There are available 5 Sub IDs (subid1 - subid5). Once you have selected a Sub ID,
                        remember to
                        consistently use it when passing the Click ID while creating offers for this feed.
                    </li>
                    <li>If you are done, then click on <strong>Save & Close</strong> button.</li>
                </ul>

                <h2>Step 2: Offer URL Params</h2>
                <DocDivider/>
                <p>Example of the offer URL with Skro click id macro:</p>
                <Alert variant="filled" color="info" sx={{mb: 2}}>
                    <strong>{`https://domain.com/?subid1={clickId}`}</strong>
                </Alert>
                <p>
                    More info about Sub ID tracking
                    is <a target="_blank" rel="noreferrer" href="https://refdocs.ads.com/cs/sub-id-tracking">here</a>
                </p>

                <h2>Step 3: Postback URL</h2>
                <DocDivider/>
                <p>Example of the Postback URL (S2S):</p>
                <Alert variant="filled" color="info" sx={{mb: 2}}>
                    <strong>{`https://your-tracking-domain.com/postback?clickId={subid1}&payout={revenue_estimated}`}</strong>
                </Alert>
                <p>
                    If you want to track different events like views, ad click on a separate columns, then you will
                    need to use Custom Conversions in Skro.
                </p>
                <p>
                    To fire custom conversion then you will need to
                    append <strong>&ce=YOUR_EVENT_NAME</strong> to your postback URL.
                </p>
                <p>
                    More info about Postback (S2S) tracking
                    is <a target="_blank" rel="noreferrer" href="https://refdocs.ads.com/cs/s2s-tracking">here</a>
                </p>

                <p style={{paddingTop: 24}}>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default AdsIntegration;
