import React from "react";
import {Grid, Typography, Box} from "@mui/material";
import {styled} from "@mui/system";
import {ContainerFluid, ContainerFluidLgPaddingTop, LgPaddingTop} from "./Home";

export const LogoBox = styled(Box)(({theme}) => ({
    display: "flex",
    padding: 10,
    marginTop: 8,
    marginRight: 8,
    height: 45,
    borderRadius: 20,
    textAlign: "center",
    backgroundColor: "#fff"
}));

function IntegrationSection() {
    return (
        <LgPaddingTop sx={{backgroundColor: "#FFFFFF"}}>
            <ContainerFluidLgPaddingTop>
                <Typography variant="h3" align="center" sx={{paddingBottom: 3}}>
                    Use the Power of Integrations
                </Typography>
                <Typography align="center" sx={{paddingBottom: "60px", color: "#555", fontSize: 24}}>
                    Automate your cost and revenue tracking and optimize your campaigns, zones and sites through the
                    Skro UI
                    by integrating your traffic sources with Skro.
                </Typography>
                <ContainerFluid>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h5" align="left" sx={{
                                        fontSize: 24,
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Conversion API
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        You can now avoid issues related to post-GDPR, iOS 14, or cookie-based
                                        attribution by using an API to send precise conversion data. This means that
                                        you can ensure accurate measurement and tracking of your conversions without
                                        being affected by changes in regulations or restrictions on cookie-based
                                        tracking. With this feature, you can have confidence in your data and make
                                        informed decisions based on accurate conversion tracking.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/meta.svg" alt="Facebook" width="85"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tiktok.svg" alt="TikTok" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/google_ads.svg" alt="Google Ads" width="105"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/microsoftads.svg" alt="Bing" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/taboola.svg" alt="Taboola" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/mgid.svg" alt="MGID" width="70"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/outbrain.svg" alt="Outbrain" width="100"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h5" align="left" sx={{
                                        fontSize: 24,
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Cost Sync
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        You can easily monitor the expenses associated with your paid advertising
                                        campaigns at every level by using Skro. All you need to do is connect one or
                                        more of your ad network accounts that Skro supports, and the tool will
                                        automatically synchronize your cost data every 30 minutes. With this feature,
                                        you can stay up-to-date on your campaign spending and gain a comprehensive
                                        understanding of your advertising expenses across various ad networks and
                                        campaigns.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/meta.svg" alt="Facebook" width="85"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tiktok.svg" alt="TikTok" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/google_ads.svg" alt="Google Ads" width="105"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/microsoftads.svg" alt="Microsoft Ads (Bing)"
                                             width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/taboola.svg" alt="Taboola" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/mgid.svg" alt="MGID" width="70"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/outbrain.svg" alt="Outbrain" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/propellerads.svg" alt="PropellerAds" width="125"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/exoclick.svg" alt="ExoClick" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/pushground.svg" alt="Pushground" width="110"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/partners/mondiad.svg" alt="Mondiad" width="95"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h5" align="left" sx={{
                                        fontSize: 24,
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Revenue Sync
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        Gone are the days of manually uploading CSV files. Now, you can simply connect
                                        your preferred search feed arbitrage or affiliate network provider to Skro, and
                                        your revenues will be automatically imported into the tool as they are
                                        updated. This means that you can avoid the tedious task of manually
                                        uploading data and have complete visibility into your revenue streams in
                                        real-time. By automating this process, you can save time and focus on more
                                        important tasks.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/clickbank.svg" alt="ClickBank" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/sedo.svg" alt="Sedo" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/system1.svg" alt="System1" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tonic.svg" alt="Tonic" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/bodis.svg" alt="Bodis" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/ads.svg" alt="Ads.com" width="50"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/domain_active.svg" alt="Domain Active"
                                             width="120"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h5" align="left" sx={{
                                        fontSize: 24,
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Optimize Ads
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        By connecting one or more of your accounts from the ad networks that are
                                        supported by Skro, you can use our interface to optimize your campaigns,
                                        creatives, ad groups, zones, and sites for your traffic sources. This means
                                        that you can manage and optimize your advertising efforts more efficiently and
                                        effectively through a single platform. With Skro, you can make
                                        data-driven decisions and take actions to improve the performance of your
                                        advertising campaigns across multiple ad networks.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/meta.svg" alt="Facebook" width="85"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tiktok.svg" alt="TikTok" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/google_ads.svg" alt="Google Ads" width="105"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/microsoftads.svg" alt="Microsoft Ads (Bing)"
                                             width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/taboola.svg" alt="Taboola" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/mgid.svg" alt="MGID" width="70"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/outbrain.svg" alt="Outbrain" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/propellerads.svg" alt="PropellerAds" width="125"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/exoclick.svg" alt="ExoClick" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/pushground.svg" alt="Pushground" width="110"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/partners/mondiad.svg" alt="Mondiad" width="95"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h5" align="left" sx={{
                                        fontSize: 24,
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Deploy Landers on AWS
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        Our platform takes the complexity out of managing domains, SSL certificates,
                                        website deployments and distributions. From automatic domain verification and
                                        SSL generation to one-click deployments and distributions. We provide everything
                                        you need to get your site live and secure.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/aws.svg" alt="AWS" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/cloudflare.svg" alt="Cloudflare" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/namesilo.svg" alt="NameSilo" width="110"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </ContainerFluid>
            </ContainerFluidLgPaddingTop>
        </LgPaddingTop>
    );
}

export default IntegrationSection;
