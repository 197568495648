import React from "react";
import PropTypes from "prop-types";
import {Switch} from "react-router-dom";
import PropsRoute from "../shared/components/PropsRoute";
import Home from "./home/Home";
import Pricing from "./pricing/Pricing";
import Features from "./features/Features";
import TermsConditions from "./policies/TermsConditions";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import CookiePolicy from "./policies/CookiePolicy";
import FAQ from "./faq/FAQ";
import Docs from "./docs/Docs";

function Routing(props) {
    const {
        selectHome,
        selectPricing,
        selectFeatures,
        selectTermsConditions,
        selectPrivacyPolicy,
        selectEndUserPrivacyPolicy,
        selectFAQ,
        selectDocs
    } = props;
    return (
        <Switch>
            <PropsRoute exact path="/docs/postback/parameters-in-postback-urls" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/shopify/how-to-integrate-skro-with-my-shopify-store" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/shopify/how-to-create-a-campaign-to-track-my-shopify-traffic" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/facebook/how-to-create-a-campaign-to-track-facebook-traffic" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/facebook/how-to-integrate-facebook-capi" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/facebook/how-to-send-additional-parameters-via-facebook-meta-conversion-api" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/buygoods-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/maxweb-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/clickbank-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/exoclick-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/propellerads-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/pushground-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/mondiad-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/sedo-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/sedo-facebook-direct-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/system1-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/system1-rsoc-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/inuvo-rsoc-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/about-rsoc" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/ads-com-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/domain-active-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/bodis-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/tonic-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/taboola-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/mgid-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/outbrain-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/google-ads-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/microsoft-bing-ads-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/facebook-meta-ads-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/integrations/tiktok-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/deploy-website/about-aws-deployment" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/deploy-website/aws-api-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/deploy-website/cloudflare-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/deploy-website/namesilo-integration" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/deploy-website/how-to-setup-domain-to-get-ssl-certificate" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs/deploy-website/how-to-deploy-website" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/docs" component={Docs} selectDocs={selectDocs}/>
            <PropsRoute exact path="/faq" component={FAQ} selectFAQ={selectFAQ}/>
            <PropsRoute exact path="/end-user-privacy-policy" component={CookiePolicy} selectEndUserPrivacyPolicy={selectEndUserPrivacyPolicy}/>
            <PropsRoute exact path="/cookie-policy" component={CookiePolicy} selectEndUserPrivacyPolicy={selectEndUserPrivacyPolicy}/>
            <PropsRoute exact path="/privacy-policy" component={PrivacyPolicy} selectPrivacyPolicy={selectPrivacyPolicy}/>
            <PropsRoute exact path="/terms-and-conditions" component={TermsConditions} selectTermsConditions={selectTermsConditions}/>
            <PropsRoute exact path="/contact" component={Pricing} selectPricing={selectPricing}/>
            <PropsRoute exact path="/features" component={Features} selectFeatures={selectFeatures}/>
            <PropsRoute exact path="/pricing" component={Pricing} selectPricing={selectPricing}/>
            <PropsRoute exact path="/login" component={() => {
                window.location.href = "https://app.skro.eu/login";
                return null;
            }}/>
            <PropsRoute path="/" component={Home} selectHome={selectHome}/>
        </Switch>
    );
}

Routing.propTypes = {
    selectHome: PropTypes.func.isRequired,
    selectPricing: PropTypes.func.isRequired,
    selectFeatures: PropTypes.func.isRequired,
    selectTermsConditions: PropTypes.func.isRequired,
    selectPrivacyPolicy: PropTypes.func.isRequired,
    selectEndUserPrivacyPolicy: PropTypes.func.isRequired,
    selectFAQ: PropTypes.func.isRequired,
    selectDocs: PropTypes.func.isRequired
};

export default Routing;
