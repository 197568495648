import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Box, Typography} from "@mui/material";

function FeatureCard(props) {
    const {Icon, color, headline, text} = props;
    return (
        <Fragment>
            <Box
                sx={{
                    color: color,
                    fill: color,
                    borderRadius: 4,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 3,
                    maxWidth: 60,
                    margin: "0 auto"
                }}
            >
                {Icon}
            </Box>
            <Box sx={{
                lineHeight: 1.334,
                fontSize: 24,
                fontWeight: 400,
                margin: 0,
                pb: 3,
                pt: 3,
                textAlign: "center"
            }}>
                {headline}
            </Box>
            <Typography variant="body1" color="textSecondary" align="justify">
                {text}
            </Typography>
        </Fragment>
    );
}

FeatureCard.propTypes = {
    Icon: PropTypes.element.isRequired,
    color: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

export default FeatureCard;
