import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocImage, DocRoot} from "../Docs";

function DomainActiveIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://domainactive.com/" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 225}} src="/images/integration/domain_active.svg"
                              alt="Domain Active logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Domain Active with Skro?"/>
            <Box>
                <h2>Who we are</h2>
                <p>
                    <a href="https://domainactive.com/" target="_blank" rel="noreferrer">
                        Domain Active
                    </a> works with the top advertisers and networks within the digital media industry. Our partners
                    leverage our platform and full suite of optimization solutions to monetize their websites, traffic
                    and domain portfolios. Our detailed reporting and yield management tools provide our partners with
                    the industry’s most advanced monetization solutions.
                </p>
                <h2>Integration</h2>
                <p>
                    Skro integration with Domain Active gives you the ability to sync revenue data that has been
                    adjusted after different traffic sources.
                </p>
                <ul>
                    <li>Update revenue and conversions every hour.</li>
                </ul>

                <h2>Step 1: Add integration</h2>
                <DocDivider/>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>
                        Click on the <strong>Add</strong> button in Affiliate Networks & Search Feed Arbitrage section.
                    </li>
                </ul>
                <DocImage src="/images/docs/ads-com-int-1.jpg" alt="Domain Active integration step 1"/>
                <ul>
                    <li>Click on the <strong>Domain Active</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/domain-active-int-1.jpg" alt="Domain Active integration step 2"/>
                <ul>
                    <li>Give a <strong>Name</strong> to your integration.</li>
                    <li><strong>API Key</strong> is your DomainActive API key ask it from your account manager.</li>
                    <li><strong>Domain Active Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a separate columns in your Skro reports. To use that you will need to use
                        Custom Conversions in Skro.
                    </li>
                    <li>
                        <strong>Click ID Param</strong> is the parameter what you use in your offer URL to send Skro
                        Click ID. There are available 5 Sub IDs (subid1 - subid3). Once you have selected a Sub ID,
                        remember to consistently use it when passing the Click ID while creating offers for this feed.
                    </li>
                </ul>

                <DocImage src="/images/docs/domain-active-int-2.jpg" alt="DomainActive integration step 3"/>
                <ul>
                    <li>Fill the form and click <strong>Save & Close</strong></li>
                </ul>

                <h2>Step 2: Offer URL Params</h2>
                <DocDivider/>
                <p>Example of the offer URL with Skro click id macro:</p>
                <Alert variant="filled" color="info" sx={{mb: 2}}>
                    <strong>{`https://example.com/?tg1={clickId}`}</strong>
                </Alert>


                <h2>Step 3: Postback URL</h2>
                <DocDivider/>
                <p>Example of the Postback URL (S2S):</p>
                <Alert variant="filled" color="info" sx={{mb: 2}}>
                    <strong>{`https://your-tracking-domain.com/postback?clickId={$tg1}&payout={$estimatedGoogleCPCNow}`}</strong>
                </Alert>
                <p>
                    If you want to track different events like views, ad click on a separate columns, then you will
                    need to use Custom Conversions in Skro.
                </p>
                <p>
                    To fire custom conversion then you will need to
                    append <strong>&ce=YOUR_EVENT_NAME</strong> to your postback URL.
                </p>

                <p style={{paddingTop: 24}}>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default DomainActiveIntegration;
