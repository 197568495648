import React from "react";
import PropTypes from "prop-types";

function WaveBorder(props) {
    const id = String(Math.random());
    const {
        className,
        lowerColor,
        upperColor,
        ...rest
    } = props;

    return (
        <div className={className} style={{ background: upperColor }} {...rest}>
            <svg
                className="waves"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shapeRendering="auto"
            >
                <defs>
                    <path
                        id={id}
                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    />
                </defs>
                <g className="parallax">
                    <use href={`#${id}`} x="48" y="0" fill={lowerColor} />
                </g>
            </svg>
        </div>
    );
}

WaveBorder.propTypes = {
    lowerColor: PropTypes.string.isRequired,
    upperColor: PropTypes.string.isRequired,
};

export default WaveBorder;
