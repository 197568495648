import React from "react";
import PropTypes from "prop-types";
import {Box, Typography, Button, Divider, useTheme} from "@mui/material";

function PriceCard(props) {
    const {title, pricing, features, cc} = props;
    const theme = useTheme();
    const signUpLink = "https://app.skro.eu/login";
    return (
        <Box sx={{
            backgroundColor: theme.palette.common.defaultBlue,
            color: theme.palette.common.white,
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(6),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            marginTop: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            position: "relative",
            maxWidth: 400
        }}>
            {title === "Pro" &&
                <Box sx={{
                    position: "absolute",
                    right: 0,
                    top: 16,
                    backgroundColor: "#f44336",
                    padding: "4px 8px",
                    fontWeight: 600,
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20
                }}
                >
                    Most popular
                </Box>
            }
            <Box mb={3}>
                <Typography
                    variant={"h6"}
                    sx={{
                        color: "common.white",
                        textAlign: "center"
                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Box mb={3}>
                <Typography
                    sx={{
                        fontSize: 14,
                        color: "#999",
                        padding: 0,
                        marginTop: "-10px",
                        textAlign: "center"
                    }}
                >
                    {cc}
                </Typography>
            </Box>
            <Box display="flex" alignContent="center" mb={3}>
                <Typography sx={{textAlign: "center", margin: "0 auto"}}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        href={signUpLink}
                    >
                        Sign up now
                    </Button>
                </Typography>
            </Box>
            <Box mb={3}>
                <Typography variant={"h4"}>
                    {pricing}
                </Typography>
            </Box>
            {features.map((feature, index) => (
                <Box key={index}>
                    <Box display="flex" alignItems="center" flexDirection="column" p={1}>
                        <Box style={{textAlign: "center"}}>
                            <Typography
                                variant="h6"
                                align="center"
                            >
                                {feature?.mainText}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{textAlign: "center", fontSize: 14, color: "#999"}}>
                                {feature?.subText}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider/>
                </Box>
            ))}
        </Box>
    );
}

PriceCard.propTypes = {
    title: PropTypes.string.isRequired,
    pricing: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    cc: PropTypes.string.isRequired
};

export default PriceCard;
