import React from "react";
import {Typography, ToggleButtonGroup, ToggleButton, Box, useTheme} from "@mui/material";
import PriceCard from "./PriceCard";
import {ContainerFluid, LgPaddingTop} from "./Home";

function PricingSection() {
    const theme = useTheme();
    const [planType, setPlanType] = React.useState("individual");
    const handleToggleButtonChange = (event, newPlanType) => {
        if (newPlanType === null) {
            return false;
        }
        setPlanType(newPlanType);
    };

    const individualSubscriptionPlans = [
        {
            name: "Free",
            price: 0.00,
            overagePrice: 0.05,
            events: "250 000",
            customDomains: 10,
            dataRetentionInMonths: 3,
            totalNumberOfRecords: "20",
            integrations: 1,
            users: 1
        },
        {
            name: "Starter",
            price: 39.00,
            overagePrice: 0.03,
            events: "2 500 000",
            customDomains: 50,
            dataRetentionInMonths: 6,
            totalNumberOfRecords: "Unlimited",
            integrations: 3,
            users: 1
        },
        {
            name: "Pro",
            price: 99.00,
            overagePrice: 0.02,
            events: "5 000 000",
            customDomains: 100,
            dataRetentionInMonths: 12,
            totalNumberOfRecords: "Unlimited",
            integrations: "Unlimited",
            users: 1
        }
    ];

    const businessSubscriptionPlans = [
        {
            name: "Team",
            price: 199.00,
            overagePrice: 0.02,
            events: "10 000 000",
            customDomains: 150,
            dataRetentionInMonths: 12,
            totalNumberOfRecords: "Unlimited",
            integrations: "Unlimited",
            users: 5
        },
        {
            name: "Enterprise",
            price: 449.00,
            overagePrice: 0.01,
            events: "30 000 000",
            customDomains: 300,
            dataRetentionInMonths: 24,
            totalNumberOfRecords: "Unlimited",
            integrations: "Unlimited",
            users: 10
        }
    ];


    return (
        <LgPaddingTop sx={{backgroundColor: "#FFFFFF", mb: 12}}>
            <Typography variant="h3" align="center" sx={{paddingBottom: "50px"}}>
                Pricing
            </Typography>
            <Typography paragraph align="center" sx={{paddingBottom: "50px", color: "#555", fontSize: 24}}>
                Choose your perfect plan
            </Typography>
            <Box sx={{textAlign: "center", paddingBottom: theme.spacing(2)}}>
                <ToggleButtonGroup
                    color="primary"
                    value={planType}
                    exclusive
                    onChange={handleToggleButtonChange}
                >
                    <ToggleButton value="individual">
                        Individual
                    </ToggleButton>
                    <ToggleButton value="business">
                        Business
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <ContainerFluid>
                <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="center">
                    {planType === "individual" && individualSubscriptionPlans.map((plan) => {
                        return (
                            <Box sx={{width: "100%", minWidth: 200, maxWidth: 400}} p={2} data-aos="zoom-in-up" key={plan.name}>
                                <PriceCard
                                    title={plan.name}
                                    cc={plan.name === "Free" ? "No CC Required" : "CC Required"}
                                    pricing={
                                        <Box sx={{textAlign: "center"}}>
                                            € {plan.price}
                                            <Typography display="inline"> / month</Typography>
                                        </Box>
                                    }
                                    features={[
                                        {mainText: plan.dataRetentionInMonths + " months", subText: "Data retention"},
                                        {mainText: plan.events, subText: "Events per month"},
                                        {mainText: "€ " + plan.overagePrice, subText: "Per 1000 events overage"},
                                        {mainText: plan.customDomains, subText: "Custom domains with Free SSL"},
                                        {mainText: plan.users, subText: "Number of users"},
                                        {mainText: plan.integrations, subText: "Number of integrations"},
                                        {
                                            mainText: plan.totalNumberOfRecords,
                                            subText: "Total number of campaigns, offers, landings, flows, traffic sources and affiliate networks"
                                        }
                                    ]}
                                />
                            </Box>
                        );
                    })}
                    {planType === "business" && businessSubscriptionPlans.map((plan) => {
                        return (
                            <Box sx={{width: "100%", minWidth: 200, maxWidth: 400}} p={2} data-aos="zoom-in-up" key={plan.name}>
                                <PriceCard
                                    title={plan.name}
                                    cc={"CC Required"}
                                    pricing={
                                        <Box sx={{textAlign: "center"}}>
                                            € {plan.price}
                                            <Typography display="inline"> / month</Typography>
                                        </Box>
                                    }
                                    features={[
                                        {mainText: plan.dataRetentionInMonths + " months", subText: "Data retention"},
                                        {mainText: plan.events, subText: "Events per month"},
                                        {mainText: "€ " + plan.overagePrice, subText: "Per 1000 events overage"},
                                        {mainText: plan.customDomains, subText: "Custom domains with Free SSL"},
                                        {mainText: plan.users, subText: "Number of users"},
                                        {mainText: plan.integrations, subText: "Number of integrations"},
                                        {
                                            mainText: plan.totalNumberOfRecords,
                                            subText: "Total number of campaigns, offers, landings, flows, traffic sources and affiliate networks"
                                        }
                                    ]}
                                />
                            </Box>
                        );
                    })}
                </Box>
                <Box sx={{textAlign: "center", pt: 3, pb: 3, fontSize: 16, color: "#666"}}>
                    * Plans should be paid upfront. <br/>
                    * An event is defined as every visit / click / conversion. <br/>
                    * Overage charge occurs when the plan’s included events limit is exceeded. <br/>
                    * Overage should be paid at the end of each month of using.
                </Box>
                <Box sx={{
                    marginTop: "20px",
                    padding: "50px 20px",
                    textAlign: "center",
                    fontSize: 16,
                    color: "#fff",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.shape.borderRadius,
                    [theme.breakpoints.down("xs")]: {
                        maxWidth: 380,
                        marginLeft: "auto",
                        marginRight: "auto"
                    },
                }}>
                    <h2 style={{color: theme.palette.primary.main}}>Looking for a custom plan?</h2>
                    <p>Haven’t found a plan that covers everything you need? Contact us to discuss a custom plan.</p>
                    <p>support@skro.eu</p>
                </Box>
            </ContainerFluid>
        </LgPaddingTop>
    );
}

export default PricingSection;
