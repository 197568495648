import React from "react";
import {Alert} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function SkroPostbackParameters() {
    return (
        <DocRoot>
            <DocHeaderTitle title="Parameters in Postback URLs"/>
            <div>
                <p>
                    When using the postback URL, Skro requires specific parameters for conversion tracking to work
                    correctly.
                </p>
                <p><strong>Default S2S Postback URL:</strong></p>
                <Alert severity="info" variant="filled" color="info" sx={{marginBottom: 1}}>
                    {`https://skrotrack.com/postback?clickId=REQUIRED&payout=OPTIONAL&status=OPTINAL&transactionId=OPTIONAL`}
                </Alert>
                <h2 style={{paddingTop: "24px"}}>Parameter name (clickId)</h2>
                <p>
                    The <strong>clickId</strong> is dedicated to passing skro’s click ID. The parameter is required to
                    track the registered event. During each campaign visit, a unique click ID is added to the offer
                    URL, to a network (server-side). If the click ID is relayed back to Skro via a server request, a
                    conversion is recorded successfully in the origin Skro campaign. Also, when a conversion is
                    reported in a Skro campaign, all primary visit attributes for the associated user are correlated
                    within the reporting.
                </p>
                <p><strong>Example:</strong></p>
                <Alert severity="info" variant="filled" color="info" sx={{marginBottom: 1}}>
                    {`https://www.my_offer_link.com?s2={clickId}`}
                </Alert>
                <p>
                    The offer’s source has its own dedicated parameters for receiving the Skro click ID (s2 in the
                    above example), along with any additional data for each click sent to an offer. Each parameter also
                    has its own corresponding token (network side) that enables referencing the data recorded for each
                    click.
                </p>
                <p>
                    The conversion ID (<strong>clickId</strong>) is matched to the visit ID with certain attributes
                    such as device type, operating system, geo etc. The most important part to register conversions
                    correctly is to match the click ID in the offer URL with the token used in the related postback URL.
                    To do so, check the affiliate network documentation or talk to your affiliate manager to make sure
                    that you use the correct token.
                </p>

                <h2 style={{paddingTop: "24px"}}>Parameter name (payout)</h2>
                <p>
                    The <strong>payout</strong> parameter is used to pass the revenue amount per conversion. There are
                    two available options for tracking conversion payout in
                    Skro: <strong>Auto</strong> or <strong>Manual</strong>. Auto payout tracking
                    is available when a dynamic payout token or fixed value is used in your postback URL. Payout
                    tokens/values are appended to the <strong>payout=</strong> parameter found in the Skro postback
                    URL's query string.
                </p>

                <h2 style={{paddingTop: "24px"}}>Parameter name (status)</h2>
                <p>
                    With Skro it is possible to track all the stages of conversion from the moment it appears to the
                    actual payment.
                </p>
                <Alert severity="info" variant="filled" color="info" sx={{marginBottom: 1}}>
                    The status of conversion can only be tracked if your affiliate network provides the dedicated token
                    to pass this data through the postback URL.
                </Alert>
                <p>
                    For example, if you are using our AdCombo affiliate network template then it has predefined statuses
                    and correct macro in postback URL.
                </p>

                <DocImage src="/images/docs/postback-statuses.jpg"
                          alt="Skro - Setup affiliate network postback statuses."
                />

                <h2 style={{paddingTop: "24px"}}>Parameter name (transactionId)</h2>
                <p>
                    The <strong>transactionId</strong> parameter is used to pass a unique transaction ID for each
                    conversion. You can use a unique transaction ID value in a postback URL when you want to record
                    secondary conversion steps, upsells, per visitor following the primary conversion. You can either
                    manually define a transaction ID or pass it dynamically via a token supported by the offer’s source
                    (affiliate network or other). Transaction ID tokens/values are appended to
                    the <strong>transactionId=</strong> parameter found in a Skro postback URL/pixel query string.
                </p>

                <h2 style={{paddingTop: "24px"}}>Parameter name (payoutCurrencyCode)</h2>
                <p>
                    If you want, you can use the <strong>payoutCurrencyCode</strong> parameter to pass the information
                    about a payout currency with each conversion. Note that if you send currency code via url parameter
                    then it has the highest priority. Example values: USD, EUR, GBP etc. We support up to 169 different
                    currencies.
                </p>

                <h2 style={{paddingTop: "24px"}}>Parameter name (protectionToken)</h2>
                <p>
                    This parameter allows you to use a secret token to prevent fake conversions. You will need to switch
                    that function on when creating an affiliate network in Skro.
                </p>

                <DocImage src="/images/docs/affiliate-network-protection-token.jpg"
                          alt="Skro - Setup protection token for postback url."
                />

                <p><strong>Example postback URL with protection token:</strong></p>
                <Alert severity="info" variant="filled" color="info" sx={{marginBottom: 1}}>
                    {`https://skrotrack.com/postback?clickId=3333rk00q8tc73dalcu0&payout=5&protectionToken=EHdG7Dyhr1`}
                </Alert>

                <h2 style={{paddingTop: "24px"}}>Parameter name (ce)</h2>
                <p>
                    In the postback URL, you might also use the <strong>ce</strong> parameter that is used to track
                    different types of conversions: e.g. installs, registrations, purchase, upsell, deposits etc. and
                    see the detailed data in reports. You can create up to 20 custom conversion events depending on your
                    subscription plan. However, the parameter requires an additional setup that you can configure in
                    the <strong>Settings</strong> --> <strong>Custom Conversions</strong> tab.
                </p>

                <DocImage src="/images/docs/custom-conversions.jpg" alt="Skro - Setup custom conversion events."
                />

                <p><strong>Example:</strong></p>
                <p>
                    For the <strong>App Install</strong> custom conversion event with the parameter’s values such as
                    inst and install, every postback fired
                    with <strong>&ce=inst</strong> or <strong>&ce=install</strong> will be counted
                    as a new conversion. Those conversions will be categorized as the App Install event. The custom
                    conversion event name will be displayed in reports.
                </p>

                <p><strong>Example of postback URL for custom conversion:</strong></p>
                <Alert severity="info" variant="filled" color="info" sx={{marginBottom: 1}}>
                    {`https://skrotrack.com/postback?clickId=3333rk00q8tc73dalcu0&payout=5&ce=install`}
                </Alert>

                <h2 style={{paddingTop: "24px"}}>Additional Parameters</h2>
                <p>
                    If you need to send additional information via the postback URL, you can use
                    the <strong>p1</strong>, <strong>p2</strong>, <strong>p3</strong>, <strong>p4</strong> and <strong>p5</strong> to
                    pass any information from an affiliate network with a token. Just add
                    this additional parameter with an appropriate affiliate network token or value and Skro will
                    record it.
                </p>
                <p><strong>Example postback URL with additional params:</strong></p>
                <Alert severity="info" variant="filled" color="info" sx={{marginBottom: 1}}>
                    {`https://skrotrack.com/postback?clickId=REPLACE&payout=OPTIONAL&status=OPTIONAL&transactionId=OPTIONAL&p1=OPTIONAL&p2=OPTIONAL&p3=OPTIONAL&p4=OPTIONAL&p5=OPTIONAL`}
                </Alert>

                <h2 style={{paddingTop: "24px"}}>Facebook (Meta) Ads Additional Conversion API Parameters</h2>
                <p>
                    <strong>Requirements:</strong> Facebook Ad Account and Conversion API Integration.
                </p>
                <p>
                    If you need to send additional information via Facebook Conversion API, then you can use the
                    following parameters:
                </p>
                <ul>
                    <li><strong>firstName</strong> - Using Roman alphabet a-z characters is recommended.
                    </li>
                    <li><strong>lastName</strong> - Using Roman alphabet a-z characters is recommended.
                    </li>
                    <li><strong>email</strong> - User email address.</li>
                    <li><strong>phone</strong> - User phone number.</li>
                    <li><strong>zip</strong> - Use lowercase with no spaces and no dash. Use only the first 5 digits for
                        U.S. zip codes. Use the area, district, and sector format for the UK.
                    </li>
                    <li><strong>fbLoginId</strong> - The ID issued by Meta when a person first logs into an instance of
                        an app. This is also known as App-Scoped ID.
                    </li>
                    <li><strong>fbp</strong> - The Facebook browser ID value is stored in the _fbp browser cookie under
                        your domain. Example value: fb.1.1596403881668.1116446470
                    </li>
                </ul>

                <Alert severity="info" variant="filled" color="info" sx={{marginBottom: 1}}>
                    <p>Example postback URL with additional parameters:</p>
                    {`https://skrotrack.com/postback?clickId=ancdh2udspoc73cjodf0&payout=10&firstName=John&lastName=Toe&email=johntoe@example.com&zip=12345&fbLoginId=1.1.mylogin&fbp=fb.1.1596403881668.1116446470`}
                </Alert>
            </div>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default SkroPostbackParameters;
